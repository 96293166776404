import React from "react";
import { Modal } from "reactstrap";

const PhotoView = ({ src, showModal, setShowModal }) => {
  const togglemodal = () => {
    setShowModal(!showModal);
  };

  return (
    <div>
      <Modal
        size="md"
        isOpen={showModal}
        centered={true}
        className="doc-modal confirmation"
        // toggle={togglemodal}
      >
        <div className="modal-header px-0 border-0">
          <div className="head-item">
            <span
              onClick={togglemodal}
              aria-hidden="true"
              className="close-icon"
            >
              <i className="bx bx-x"></i>
            </span>
          </div>
        </div>

        <div>
          <img src={src} alt="photo" width={"100%"} />
        </div>
      </Modal>
    </div>
  );
};

export default PhotoView;
