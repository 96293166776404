import { call, put, takeEvery } from "redux-saga/effects"
import { getAdminRoleTypeFail, getAdminRoleTypeSuccess, getAdminRolesFail, getAdminRolesSuccess } from "./actions"
import { GET_ADMIN_ROLES, GET_ADMIN_ROLETYPE } from "./actionsType"
import { adminRoleType, getAllAdminRoles } from "helpers/backend_helper"

//Get Admin Roles
function* getRoles() {
    try {
        const response = yield call(getAllAdminRoles)
        yield put(getAdminRolesSuccess(response))
    } catch (error) {
        yield put(getAdminRolesFail(error))
    }
}
export function* AdminRoleSaga() {
    yield takeEvery(GET_ADMIN_ROLES, getRoles)
}

//Get Admin Roles
function* getRoleType({ payload: { type } }) {
    try {
        const response = yield call(adminRoleType, type)
        yield put(getAdminRoleTypeSuccess(response))
    } catch (error) {
        yield put(getAdminRoleTypeFail(error))
    }
}
export function* RoleTypeSaga() {
    yield takeEvery(GET_ADMIN_ROLETYPE, getRoleType)
}