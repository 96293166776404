//update profile
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

//Password change
export const PASSWORD_CHANGE = "PASSWORD_CHANGE";
export const PASSWORD_CHANGE_FAIL = "PASSWORD_CHANGE_FAIL";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";

//update profile image
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
export const UPDATE_PROFILE_IMAGE_FAIL = "UPDATE_PROFILE_IMAGE_FAIL";
export const UPDATE_PROFILE_IMAGE_SUCCESS = "UPDATE_PROFILE_IMAGE_SUCCESS";
export const UPDATE_PROFILE_IMAGE_LOADING = "UPDATE_PROFILE_IMAGE_LOADING";
export const GET_IMAGE_PATH = "GET_IMAGE_PATH";