import {
  GET_ALL_WINNERS,
  GET_WINNERS_FAIL,
  GET_WINNERS_SUCCESS,
  REDEEM_PRIZE,
  REDEEM_PRIZE_FAIL,
  REDEEM_PRIZE_SUCCESS,
} from "./actionType";

export const getAllWinners = ({ data, currentPage, pageSize, dispatch, readFile }) => ({
  type: GET_ALL_WINNERS,
  payload: { data, currentPage, pageSize, dispatch, readFile }
});
export const getAllWinnersSuccess = (data) => ({
  type: GET_WINNERS_SUCCESS,
  payload: data,
});
export const getWinnersFail = (error) => ({
  type: GET_WINNERS_FAIL,
  payload: error,
});

//redeem prize
export const redeemPrizeAction = (data, resetRedeemModel) => ({
  type: REDEEM_PRIZE,
  payload: { data, resetRedeemModel },
});
export const redeemPrizeActionSuccess = (data) => ({
  type: REDEEM_PRIZE_SUCCESS,
  payload: data,
});
export const redeemPrizeActionFail = (error) => ({
  type: REDEEM_PRIZE_FAIL,
  payload: error,
});
