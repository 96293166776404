import {
    GET_ALL_ADMIN_SUCCESS,
    GET_ALL_ADMIN_FAIL,
    GET_ADMIN_ROLES,
    GET_ADMIN_ROLES_FAIL,
    GET_ADMIN_ROLES_SUCCESS,
    ADD_ADMIN, ADD_ADMIN_FAIL,
    ADD_ADMIN_SUCCESS, ADMIN_LOADING,
    GET_ADMIN_BY_ID_SUCCESS,
    GET_ADMIN_BY_ID_FAIL,
    STATUS, STATUS_FAIL,
    STATUS_SUCCESS
} from "./actionTypes"

//Get All Admins
const initialState = {
    admin: [],
    error: {}
}
export const GetAdmin = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ADMIN_SUCCESS:
            return {
                ...state,
                admin: action.payload,
            }

        case GET_ALL_ADMIN_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

//Add Admin 
const addAdminInitialState = {
    error: "",
    success: "",
    loading: false
}
export const AddAdmin = (state = addAdminInitialState, action) => {
    switch (action.type) {
        case ADD_ADMIN:
            state = {
                ...state,
            }
            break
        case ADD_ADMIN_SUCCESS:
            state = { ...state, success: action.payload, loading: false }
            break
        case ADD_ADMIN_FAIL:
            state = { ...state, error: action.payload, loading: false }
            break
        case ADMIN_LOADING:
            state = {
                loading: action.payload
            }
    }
    return state
}

// Get Admin ID
const GetAdminInitialState = {
    error: "",
    success: "",
}
export const GetAdminById = (state = GetAdminInitialState, action) => {
    switch (action.type) {
        case GET_ADMIN_BY_ID_SUCCESS:
            return {
                ...state,
                admin: action.payload,
            }

        case GET_ADMIN_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload,
            }
    }
    return state
}

//Change Admin Status
const statusInitialState = {
    error: "",
    success: "",
    loader: false,
}
export const ChangeStatus = (state = statusInitialState, action) => {
    switch (action.type) {
        case STATUS:
            state = {
                ...state,
                success: "",
                loader: true,
            }
            break
        case STATUS_SUCCESS:
            state = {
                ...state, success: action.payload, loader: false,
            }
            break
        case STATUS_FAIL:
            state = { ...state, error: action.payload, loader: false, }
            break
    }
    return state
}