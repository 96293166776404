import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";

const SpinnerModal = ({ SpinWheelImageData, showModal, setShowModal }) => {

    const [wheel, setWheel] = useState(null);
    const togglemodal = () => { setShowModal(!showModal) };

    useEffect(() => {

        const segments = SpinWheelImageData.map((spin, index) => {
            return ({
                'image': spin?.image,
            })
        });

        const newWheel = new window.Winwheel({
            numSegments: SpinWheelImageData?.length,
            textOrientation: "curved",
            textAlignment: "outer",
            outerRadius: 150,
            textFontSize: 12,
            canvasId: "myCanvas",
            drawMode: "segmentImage",
            segments: segments,
            animation: {
                type: "custom",
                duration: 5,
                propertyName: "rotationAngle",
            },
        });
        setWheel(newWheel);

        return () => {
            if (wheel) {
                wheel.stopAnimation();
            }
        };

    }, [SpinWheelImageData]);

    return (
        <div>
            <Modal
                size="md"
                isOpen={showModal}
                centered={true}
                className="doc-modal confirmation"
            >
                <div className="modal-header px-0 border-0">
                    <div className="head-item">
                        <h3 className='page-title font-size-18 p-0'>Spinner Preview</h3>
                        <span
                            onClick={togglemodal}
                            aria-hidden="true"
                            className="close-icon"
                        >
                            <i className="bx bx-x"></i>
                        </span>
                    </div>
                </div>
                <canvas id="myCanvas" width="840" height="840" ></canvas>
            </Modal>
        </div>
    );
};

export default SpinnerModal;
