import React, { memo } from 'react';

const PrimaryButton = ({btnType, btnName, btnIcon, showIcon, onClick, disabled, className}) => {
    return (
        <>
            <button className={`btn-common btn-primary btn-block primary-button ${className}`} type={btnType} onClick={onClick} disabled={disabled}>
                {showIcon ? <img className='me-2' src={btnIcon} alt='btnicon' /> : ''}
                {btnName}
            </button>
        </>
    );
};

export default memo(PrimaryButton);