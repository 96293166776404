import bufferToBase64 from "constants/bufferToBase64";
import { ADD_ADVERTISEMNET, ADD_ADVERTISEMNET_FAIL, ADD_ADVERTISEMNET_LOADING, ADD_ADVERTISEMNET_SUCCESS, CHANGE_ADS_STATUS, CHANGE_ADS_STATUS_FAIL, CHANGE_ADS_STATUS_SUCCESS, DEL_AD, DEL_AD_FAIL, DEL_AD_SUCCESS, GET_ALL_ADDVERTISEMENT, GET_ALL_ADDVERTISEMENT_FAIL, GET_ALL_ADDVERTISEMENT_SUCCESS, READ_ADVERTISEMNET_IMAGE, READ_ADVERTISEMNET_IMAGE_FAIL, READ_ADVERTISEMNET_IMAGE_SUCCESS } from "./actionTypes";

//Get All image
const initialState = {
  data: [],
  error: {},
  loading: false,
};
export const GetallAdds = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ADDVERTISEMENT:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_ADDVERTISEMENT_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case GET_ALL_ADDVERTISEMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
//chnage status
const chnageInitialState = {
  data: [],
  error: {},
  loading: false,
};
export const AdsStatusChange = (state = chnageInitialState, action) => {
  switch (action.type) {
    case CHANGE_ADS_STATUS:
      return {
        ...state,
        loading: true,
      };
    case CHANGE_ADS_STATUS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case CHANGE_ADS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
//delete ad
const deleteAdInitialState = {
  data: [],
  error: "",
  loading: false
}
export const DeleteAdById = (state = deleteAdInitialState, action) => {
  switch (action.type) {
    case DEL_AD:
      return {
        ...state,
        loading: true,
      }
    case DEL_AD_SUCCESS:
      return {
        ...state,
        ads: action.payload,
        loading: false
      }

    case DEL_AD_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    default:
      return state;
  }
}
//adding advertisement
const addPrize = {
  data: [],
  error: "",
  loading: false,
};
export const addAdvertisement = (state = addPrize, action) => {
  switch (action.type) {
    case ADD_ADVERTISEMNET:
      return { ...state, loading: true };
    case ADD_ADVERTISEMNET_SUCCESS:
      return { ...state, data: action.payload, loading: false }
    case ADD_ADVERTISEMNET_FAIL:
      return { ...state, error: action.payload, loading: false };
    case ADD_ADVERTISEMNET_LOADING:
      return {
        loading: action.payload
      };
    default:
      return state;
  }
};

//adding advertisement
const readAds = {
  data: {},
  error: "",
  loading: false,
};
export const ReadAdvertisementImage = (state = readAds, action) => {
  switch (action.type) {
    case READ_ADVERTISEMNET_IMAGE:
      return {
        ...state,
        loading: true
      };

    case READ_ADVERTISEMNET_IMAGE_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          data: { ...state.data, [action.payload.data.key]: bufferToBase64(action.payload.data.buffer?.data) },
          loading: false,
        };
      } else {
        return {
          data: {},
          error: {},
          loading: false,
        }
      }

    case READ_ADVERTISEMNET_IMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    default: return state
  }
};