import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { CAMPAIGN_SETTINGS_UPDATE, GET_BY_CAMPAIGN_ID, GET_CAMPAIGN_DETAILS, GET_NOTIFICATION, GET_SETTINGS, SETTINGS_UPDATE, UPDATE_CAMPAIGN_DETAILS } from "./actionType"
import { settingsUpdateError, settingsUpdateSuccess, settingsUpdateLoading, getSettingsLoading, getSettingsError, getSettingsSuccess, getNotificationError, getNotificationLoading, getNotificationSuccess, campaignSettingsUpdateError, campaignSettingsUpdateSuccess, getCampaignDetailsSuccess, getCampaignDetailsError, updateCampaignDetailsError, updateCampaignDetailsSuccess, getByCampaignIdSuccess, getByCampaignIdFail } from "./action"
//Include Both Helper File with needed methods
import { postSettingsUpdate, getSettings, getNotificationUrl, postCampaignSettingsUpdate, getCampaignDetails, updateCampaignDetails, addCampaignDetails, getCampaignId } from "../../helpers/backend_helper"
import "toastr/build/toastr.min.css"
import toast from "react-hot-toast";

//Get settings
function* fetchSettings() {
  try {
    yield put(getSettingsLoading(true))
    const response = yield call(getSettings)
    yield put(getSettingsSuccess(response))
  } catch (error) {
    yield put(getSettingsError(error))
  }
}
export function* GetSettingsSaga() {
  yield takeEvery(GET_SETTINGS, fetchSettings)
}

//Update settings
function* settingsUpdate({ payload: { data, setDisabled, id } }) {
  try {
    yield put(settingsUpdateLoading(true))
    const response = yield call(postSettingsUpdate, data, id)
    yield put(settingsUpdateLoading(false))
    if (response.status === 'error') {
      toast.error(response.message)
    } else {
      yield put(settingsUpdateSuccess(response))
      toast.success(response.message)
      setDisabled(true)
    }
  } catch (error) {
    yield put(settingsUpdateError(error))
  }
}
export function* SettingsUpdateSaga() {
  yield takeEvery(SETTINGS_UPDATE, settingsUpdate)
}

//campaign settings
function* campaignSettingUpdate({ payload: { data, id, history } }) {
  try {
    const response = yield call(postCampaignSettingsUpdate, data, id)
    if (response.status === 'error') {
      toast.error(response.message)
    } else {
      yield put(campaignSettingsUpdateSuccess(response))
      yield put(getSettingsSuccess(response))
      toast.success(response.message)
      history.push("/settings");
    }
  } catch (error) {
    yield put(campaignSettingsUpdateError(error))
  }
}
export function* CampaignSettingsUpdateSaga() {
  yield takeEvery(CAMPAIGN_SETTINGS_UPDATE, campaignSettingUpdate)
}

//get campaign details
function* getCampaignDetailsFunction() {
  try {
    const response = yield call(getCampaignDetails)
    if (response.status === 'error') {
      toast.error(response.message)
      yield put(getCampaignDetailsError(response))
    } else {
      yield put(getCampaignDetailsSuccess(response))
    }
  } catch (error) {
    yield put(getCampaignDetailsError(error?.response?.data))
  }
}

export function* GetCampaignDetailsSaga() {
  yield takeEvery(GET_CAMPAIGN_DETAILS, getCampaignDetailsFunction)
}

//update campaign details
function* updateCampaignDetailsFunction({ payload: { data, id, afterSuccess } }) {
  try {
    async function afterResult(response) {
      if (response?.status?.toLowerCase() === 'error') {
        toast.error(response.message)
        await put(updateCampaignDetailsError(response))
      } else {
        await put(updateCampaignDetailsSuccess(response))
        await put(getCampaignDetailsSuccess(response))
        await put(getByCampaignIdSuccess(response))
        if (afterSuccess) {
          afterSuccess(response?.data?._id)
        }
        toast.success(response.message)
      }
    }

    if (id) {
      let response = yield call(updateCampaignDetails, data, id)
      afterResult(response);
    } else {
      let response = yield call(addCampaignDetails, data)
      afterResult(response);
    }
  } catch (error) {
    yield put(updateCampaignDetailsError(error?.response?.data))
  }
}

export function* UpdateCampaignDetailsSaga() {
  yield takeEvery(UPDATE_CAMPAIGN_DETAILS, updateCampaignDetailsFunction)
}

//Get CampaignId
function* CampaignId({ payload: id }) {
  try {
    const response = yield call(getCampaignId, id)
    yield put(getByCampaignIdSuccess(response))
  } catch (error) {
    yield put(getByCampaignIdFail(error))
  }
}
export function* GetByCampaignIdSaga() {
  yield takeEvery(GET_BY_CAMPAIGN_ID, CampaignId)
}