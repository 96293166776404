import {
  GET_ALL_QRCODES_SUCCESS,
  GET_ALL_QRCODES_FAIL,
  GET_QRCODES_BY_ID,
  GET_QRCODES_BY_ID_SUCCESS,
  GET_QRCODES_BY_ID_FAIL,
  GENERATE_QRCODES,
  SET_STATUS_QRCODES,
  SET_STATUS_QRCODES_SUCCESS,
  SET_STATUS_QRCODES_FAIL,
  GENERATE_QRCODES_SUCCESS,
  GENERATE_QRCODES_FAIL,
  QRCODES_LOADING,
  ISSUE_QRCODES,
  ISSUE_QRCODES_SUCCESS,
  ISSUE_QRCODES__FAIL,
  CONFIRM_PRINT_QR,
  SEND_QRCODE,
  SEND_QRCODE_FAIL,
  SEND_QRCODE_SUCCESS,
} from "./actionTypes";

//Get All Qrcodes
const initialState = {
  qrcodes: [],
  error: {},
};
export const GetQrcodes = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_QRCODES_SUCCESS:
      return {
        ...state,
        qrcodes: action.payload,
      };

    case GET_ALL_QRCODES_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

//get qrcodes by ID
const getQrcodesIdInitialState = {
  error: "",
  success: "",
  loading: false,
};
export const GetQrcodesById = (state = getQrcodesIdInitialState, action) => {
  switch (action.type) {
    case GET_QRCODES_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_QRCODES_BY_ID_SUCCESS:
      return {
        ...state,
        qrcodes: action.payload,
        loading: false,
      };

    case GET_QRCODES_BY_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
  }
  return state;
};

//Add Qrcodes
const addQrcodesInitialState = {
  error: "",
  Qrnumbers: [],
  loading: false,
};
export const AddQrcodes = (state = addQrcodesInitialState, action) => {
  switch (action.type) {
    case GENERATE_QRCODES:
      state = {
        ...state,
      };
      break;
    case GENERATE_QRCODES_SUCCESS:
      state = { ...state, Qrnumbers: action.payload.Qrnumbers, loading: false };
      break;
    case GENERATE_QRCODES_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;
    case QRCODES_LOADING:
      state = {
        loading: action.payload,
      };
  }
  return state;
};

//Set Status Qrcodes
const statusInitialState = {
  error: "",
  printStatus: false,
  loading: false,
};
export const SetStatusQrcodes = (state = statusInitialState, action) => {
  switch (action.type) {
    case SET_STATUS_QRCODES:
      state = { ...state };
      break;
    case SET_STATUS_QRCODES_SUCCESS:
      state = { ...state, printStatus: true, loading: false };
      break;
    case SET_STATUS_QRCODES_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;
  }
  return state;
};

//issue Qrcodes
const issueQrInitialState = {
  error: "",
  qrCodes: [],
  loading: false,
  print: false,
};
export const issueQrcodes = (state = issueQrInitialState, action) => {
  switch (action.type) {
    case ISSUE_QRCODES:
      state = { ...state, loading: true, print: false };
      break;
    case ISSUE_QRCODES_SUCCESS:
      state = {
        ...state,
        qrCodes: action.payload,
        loading: false,
        print: true,
      };
      break;
    case ISSUE_QRCODES__FAIL:
      state = { ...state, error: action.payload, loading: false, print: false };
      break;
    case CONFIRM_PRINT_QR:
      state = { ...state, print: action.payload };
      break;
  }
  return state;
};

//Send Qr Codes
const sendQrcodesInitialState = {
  error: "",
  sendCode: [],
  loading: false,
};
export const SendQrcodes = (state = sendQrcodesInitialState, action) => {
  switch (action.type) {
    case SEND_QRCODE:
      state = {
        error: "",
        sendCode: [],
        loading: true
      };
      break;
    case SEND_QRCODE_SUCCESS:
      state = { ...state, sendCode: action.payload, loading: false };
      break;
    case SEND_QRCODE_FAIL:
      state = { ...state, error: action.payload, loading: false };
      break;

  }
  return state;
};