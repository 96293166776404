import { Error_Request, Error_Reset } from "./actionTypes"

 
//Get Bookings
export const ErrorRequest = (data) => ({
  type: Error_Request,
  payload: data,
}) 

export const ErrorReset = () => ({
  type: Error_Reset,
  payload: "",
})