import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Container, input, Card } from "reactstrap";
import Pagination from "../../components/Common/pagination/Pagination"
import serachIcon from "../../assets/images/svg/manageQr/ic-search.svg";
import tickIcon from "../../assets/images/svg/manageQr/ic-tick.svg";
import TableContainer from "components/Common/TableContainer";
import { useDispatch, useSelector } from "react-redux";
import { getAllQr } from "store/manageQr/action";
import moment from "moment";
import PageLoader from "components/Common/PageLoader";
import TableSkeleton from "components/Common/TableSkeleton";
import { debounce } from "lodash";
import DocumentTitle from "components/DocumentTitle";
import Select from "react-select";
import { segmentCount } from "constants/SpinWheelSegment";
export default function ManageQr() {

  const dispatch = useDispatch();

  const [inputSearch, setInputSearch] = useState("");
  const [showData, setShowData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState({
    field: "createdAt",
    sort: null,
    value: "ascending",
  });
  const [campaignDropValue, setCampaignDropValue] = useState(null)

  const allQr = useSelector((state) => state?.GetallQr?.QRlist?.qrlist);
  const allQrLoading = useSelector((state) => state?.GetallQr?.loading);
  const allQrCount = useSelector((state) => state?.GetallQr?.QRlist?.totalCount);
  const campaignOptions = useSelector((state) => state?.CampaignOptions?.data);

  const pageSize = 10;

  useEffect(() => {
    dispatch(getAllQr({ data: { search: inputSearch, sort: sortBy, campaign: campaignDropValue?.value }, currentPage, pageSize }));
  }, [currentPage, inputSearch, sortBy, campaignDropValue?.value]);

  useEffect(() => {
    if (inputSearch) {
      searchFilter();
    } else setShowData(allQr);
  }, [inputSearch, allQr]);

  const searchFilter = () => {
    if (inputSearch) {
      const filterData = [...allQr];
      const showTableData = filterData.filter((item) => {
        return Object.values(item).some((value) => {
          if (value && typeof value === "string") {
            return value.toLowerCase().includes(inputSearch.toLowerCase());
          }
          return value === inputSearch;
        });
      });
      setShowData(showTableData);
    }
  };
  const Header = useMemo(() => [
    {
      Header: `S.No`,
      Cell: (cellProps) => {
        return (
          <div>
            <p className="m-0">{cellProps?.row?.index + 1}</p>
          </div>
        );
      },
    },
    {
      id: 'campain',
      Header: `Campaign Name`,
      Cell: (cellProps) => {
        return (
          <div>
            <p className="m-0 td text-uppercase" >{cellProps?.row?.original?.campaign}</p>
          </div>
        );
      },
    },
    {
      isSorting: true,
      id: 'qrCode',
      Header: `QR Code`,
      Cell: (cellProps) => {
        return (
          <div>
            <p className="m-0 td text-uppercase" >{cellProps?.row?.original?.qrCode}</p>
          </div>
        );
      },
      sortType: (rowA, rowB) => {
        if (rowA.original.qrCode < rowB.original.qrCode) return -1;
        if (rowA.original.qrCode > rowB.original.qrCode) return 1;
        return 0;
      },
    },
    {
      Header: `Issued 
      Status`,
      Cell: (cellProps) => {
        return (
          <div>
            <p className="m-0">
              {cellProps?.row?.original?.issuedStatus === "1" ? (
                <img src={tickIcon} width={26} height={18}></img>
              ) : (
                "-"
              )}
            </p>
          </div>
        );
      },
    },
    {
      isSorting: true,
      id: 'issuedDateAndTime',
      Header: `Issued 
      Date & Time`,
      Cell: (cellProps) => {
        return (
          <div>
            <p className="m-0">
              {cellProps.row.original?.issuedDateAndTime ? (
                <>
                  <p className="m-0 td">
                    {moment(cellProps.row.original?.issuedDateAndTime).format(
                      "LT"
                    )}
                  </p>
                  <span className="date">
                    {" "}
                    {moment(cellProps.row.original?.issuedDateAndTime).format(
                      "ll"
                    )}
                  </span>
                </>
              ) : (
                "-"
              )}
            </p>
          </div>
        );
      },
    },
    {
      Header: `Spin 
      Status`,
      Cell: (cellProps) => {
        return (
          <div>
            <p className="m-0">
              {cellProps?.row?.original?.spinStatus === "1" ? (
                <img src={tickIcon} width={26} height={18}></img>
              ) : (
                "-"
              )}
            </p>
          </div>
        );
      },
    },
    {
      Header: `Spin
      Date & Time`,
      isSorting: true,
      id: 'spinDateAndTime',
      Cell: (cellProps) => {
        return (
          <div>
            {cellProps.row.original?.spinDateAndTime ? (
              <>
                <p className="m-0 td">
                  {moment(cellProps.row.original?.spinDateAndTime).format("LT")}
                </p>
                <span className="date">
                  {" "}
                  {moment(cellProps.row.original?.spinDateAndTime).format("ll")}
                </span>
              </>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      Header: `Email`,
      isSorting: true,
      id: 'email',
      Cell: (cellProps) => {
        return (
          <div>
            {cellProps?.row?.original?.email ? (
              <p className="m-0 td">{cellProps?.row?.original?.email}</p>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      Header: `Result`,
      isSorting: true,
      id: 'winningStatus',
      Cell: (cellProps) => {
        return (
          <div>
            {cellProps?.row?.original?.winningStatus ? (
              <p className="m-0 td">{cellProps?.row?.original?.winningStatus}</p>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = debounce((value) => {
    setInputSearch(value);
    setCurrentPage(1)
  }, 1000)

  const handleCampaignSelect = (e) => {
    setCampaignDropValue(e);
    setInputSearch("")
  }

  useEffect(() => {
    if (campaignOptions?.length > 0) {
      const result = campaignOptions.filter((item) => item?.status === 'ACTIVE')
      if (result.length > 0) {
        setCampaignDropValue(result[0])
        setInputSearch("")
      }
    }
  }, [campaignOptions])

  return (
    <React.Fragment>
      <DocumentTitle title={`Manage QR`} />
      <div className="page-content ">
        <Container fluid>
          <div className="page-title">Manage QR</div>
          <Card>
            <div className="searchContainer">

              <div className='compain-drop'>
                <Select
                  className='select-menu'
                  value={campaignDropValue}
                  onChange={(e) => handleCampaignSelect(e)}
                  options={[{ label: "All", value: "", status: "" }, ...campaignOptions]}
                />
              </div>

              <div className="searchField">
                <img
                  className="searchIcon"
                  src={serachIcon}
                  width={16}
                  height={16}
                ></img>
                <input
                  className="searchInput"
                  type="text"
                  // value={inputSearch}
                  onInput={(e) => handleSearch(e.target.value)}
                  placeholder="Search"
                />
              </div>
            </div>

            {!allQrLoading && showData ? (
              <div className="p-4">

                <TableContainer
                  columns={Header}
                  data={showData}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageSize={10}
                  handleOrderClicks={() => null}
                  iSPageOptions={true}
                  handlePageChange={handlePageChange}
                  className="custom-header-css"
                  btnType="button"
                  editID={""}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                />
                <div className="d-flex justify-content-center"> {
                  allQrCount > pageSize &&
                  <Pagination
                    className="pagination-bar custom-pagination"
                    currentPage={currentPage}
                    totalCount={allQrCount}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                }
                </div>
              </div>

            ) : (
              <TableSkeleton datacount={7} listcount={15} />
            )}
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
}
