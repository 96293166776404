import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// import * as Yup from "yup";
// import { useFormik } from "formik";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
// action
import { userForgetPasswordResendCode, userResetPassword } from "../../store/actions";
import AppLogo from '../../assets/images/logo.png'
// import images
import { useParams } from 'react-router-dom';
import PasswordStrengthMeter from "constants/PasswordStrengthMeter";
import AuthLoader from "components/Common/AuthLoader";
import DocumentTitle from "components/DocumentTitle";

const ResetPassword = props => {
    //meta title

    const dispatch = useDispatch();

    const campaignDetail = useSelector(state => state.GetCampaignDetails.data);

    let { email } = useParams();
    const [passwordNew, setPasswordNew] = useState(false);
    const [passwordConf, setPasswordConf] = useState(false);
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(0);
    const [passwordValidate, setPasswordValidate] = useState('');
    const [getpassword, setGetPassword] = useState('');

    const handleChange = (event) => {
        setGetPassword(event.target.value);
    };

    const resetFormValues = {
        email: email,
        password: '',
        confPwd: '',
        verificationToken: ''
    };
    const validationSchema = Yup.object({
        password: Yup.string().required("Enter new password").test("pwd_length", "Password should reach atleast medium strength", val => passwordValidate >= 2).trim(),
        confPwd: Yup
            .string()
            .required("Enter confirm password")
            .oneOf([Yup.ref("password"), ""], "New and confirm password must be matched").trim(),

        verificationToken: Yup.number().required("Enter verification code"),
    })

    const { register, handleSubmit, formState: { errors, }, } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: resetFormValues
    });

    const onSubmit = (values) => {
        dispatch(userResetPassword(values, props.history));
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setSeconds(59);
                    setMinutes(minutes - 1);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const resendOTP = () => {
        setMinutes(1);
        setSeconds(30);
        dispatch(userForgetPasswordResendCode({ email }))
    };
    const [password, setPassword] = useState('');
    useEffect(() => {
        setPassword(getpassword)
    }, [getpassword])

    const { Loader } = useSelector(state => ({
        Loader: state.ResetPassword.resetLoader
    }));

    return (
        <React.Fragment>
            {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
        <i className="bx bx-home h2" />
        </Link>
      </div> */}
            <DocumentTitle title={`Reset Password`} />
            <div className="account-pages ">
                <div className="reset-password">
                    <Container>
                        <div className="text-center auth-logo">
                            <img src={AppLogo} alt="Logo" />
                        </div>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden auth-card">
                                    <div className="bg-primary bg-soft background-auth-page">
                                        <Row className="align-items-center auth-head">
                                            <Col xs={7} className='auth-heading py-4'>
                                                <div className="auth-heading-text">
                                                    <h2 className="f-size-24">Reset Password</h2>
                                                    <p className="f-size-12">Enter the 6 digits code that you received on your email and set the new password for your account.</p>
                                                </div>
                                            </Col>
                                            <Col xs={5} className="align-self-end logo-img">
                                                {/* <img src={profile} alt="" className="img-fluid" /> */}
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody>
                                        {/* <div className="d-inline-block">
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img
                                                        src={logo}
                                                        alt="logo"
                                                        className="rounded-circle"
                                                        height="34"
                                                    />
                                                </span>
                                            </div>
                                        </Link>
                                    </div> */}
                                        <div>
                                            <form
                                                className="form-horizontal"
                                                onSubmit={handleSubmit(onSubmit)}
                                            >

                                                <div className="mb-3">
                                                    <Label className="form-label mt-3 f-size-14">Verification Code <span>*</span></Label>
                                                    <input type="number" {...register("verificationToken")} placeholder="xxxxxx" className={errors.verificationToken?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                    {errors.verificationToken?.message ? <p className={`invalid-feedback`} >{errors.verificationToken?.message}</p> : null}
                                                    <div className="countdown-text">
                                                        {seconds > 0 || minutes > 0 ? (
                                                            <p className="f-size-12 timing"> Time remaining: <span className="remaining-time">{minutes < 10 ? `0${minutes}` : minutes}:
                                                                {seconds < 10 ? `0${seconds}` : seconds}</span>
                                                            </p>
                                                        ) : (
                                                            <p className="f-size-12 timing">Didn't receive code?</p>
                                                        )}

                                                        <button className="border-0 bg-transparent p-0 resend-code"
                                                            disabled={seconds > 0 || minutes > 0}
                                                            style={{
                                                                color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#DF0C00",
                                                            }}
                                                            onClick={resendOTP}
                                                        >
                                                            Resend code
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="show-password">

                                                        <Label className="form-label f-size-14">New Password <span>*</span></Label>

                                                    </div>
                                                    <div className="input-group auth-pass-inputgroup">
                                                        <input type={passwordNew ? "text" : "password"} {...register("password")} onChange={handleChange} placeholder="*******" className={errors.password?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                        <div>
                                                            <button onClick={() => setPasswordNew(prevState => !prevState)} type="button" id="password-addon">
                                                                {passwordNew ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                                            </button>
                                                        </div>
                                                        <div className="w-100">
                                                            <PasswordStrengthMeter password={password} setPasswordValidate={setPasswordValidate} />
                                                        </div>
                                                        {errors.password?.message ? <p className={`invalid-feedback`} >{errors.password?.message}</p> : null}
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="show-password">

                                                        <Label className="form-label f-size-14">Confirm Password <span>*</span></Label>

                                                    </div>
                                                    <div className="input-group auth-pass-inputgroup">
                                                        <input type={passwordConf ? "text" : "password"} {...register("confPwd")} placeholder="*******" className={errors.confPwd?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                                        <div>
                                                            <button onClick={() => setPasswordConf(prevState => !prevState)} type="button" id="password-addon">
                                                                {passwordConf ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                                                            </button>
                                                        </div>
                                                        {errors.confPwd?.message ? <p className={`invalid-feedback`} >{errors.confPwd?.message}</p> : null}
                                                    </div>
                                                </div>
                                                {Loader === true ? <div className="d-grid"><AuthLoader /></div> :
                                                    <div className="d-grid">
                                                        <button
                                                            className="btn btn-primary btn-block primary-button"
                                                            type="submit"
                                                        >
                                                            Reset Password
                                                        </button>
                                                    </div>
                                                }
                                                <p className="go-back f-size-12 my-3 text-center">
                                                    Back to
                                                    <Link to="/login" className="font-weight-medium primary-color f-size-12">
                                                        &nbsp;Login
                                                    </Link>{" "}
                                                </p>
                                            </form>


                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                        <div className="text-center copy-right">
                            <p>{campaignDetail?.campaignName || ""} @ {new Date().getFullYear()} All Rights Reserved </p>
                        </div>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

ResetPassword.propTypes = {
    history: PropTypes.object,
};

export default withRouter(ResetPassword);
