import {
    GET_ALL_USERS,
    GET_ALL_USERS_FAIL,
    GET_ALL_USERS_SUCCESS,
    ADD_NEW_USER,
    ADD_NEW_USER_SUCCESS,
    ADD_NEW_USER_FAIL,
    GET_USER_ID,
    GET_USER_ID_SUCCESS,
    GET_USER_ID_FAIL,
    USER_STATUS,
    USER_STATUS_SUCCESS,
    USER_STATUS_FAIL
} from "./actionsTypes";

export const getAllUser = () => ({
    type: GET_ALL_USERS,
});
export const getAllUserSuccess = users => ({
    type: GET_ALL_USERS_SUCCESS,
    payload: users,
});
export const getAllUserFail = error => ({
    type: GET_ALL_USERS_FAIL,
    payload: error,
});

// Add New User
export const AddNewUser = (user, history, id) => {
    return {
        type: ADD_NEW_USER,
        payload: { user, history, id },
    }
}
export const addNewUserSuccess = user => {
    return {
        type: ADD_NEW_USER_SUCCESS,
        payload: user,
    }
}
export const addNewUserError = error => {
    return {
        type: ADD_NEW_USER_FAIL,
        payload: error,
    }
}

//Get User By Id
export const getUserById = id => ({
    type: GET_USER_ID,
    payload: id,
});
export const getUserByIdSuccess = user => ({
    type: GET_USER_ID_SUCCESS,
    payload: user,
});
export const getUserByIdFail = error => ({
    type: GET_USER_ID_FAIL,
    payload: error,
});

// Change User Status
export const userStatusChange = (status, id) => {
    return {
        type: USER_STATUS,
        payload: { status, id },
    }
}
export const userStatusSuccess = user => {
    return {
        type: USER_STATUS_SUCCESS,
        payload: user,
    }
}
export const userStatusError = error => {
    return {
        type: USER_STATUS_FAIL,
        payload: error,
    }
}