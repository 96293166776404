// @flow
import { addprize, freezePrize, managePrize, managePrizePublish, manageSpinner, newSegments, prizestatuschnage, updatePrizebyid, updateprizeimg } from "helpers/backend_helper";
import { PrizeFail, PrizeSuccess, addPrizeFail, addPrizeLoading, addPrizeSuccess, addSegmentFail, addSegmentSuccess, changePrizeFail, changePrizeSuccess, getAllPrizeFail, getAllPrizeSuccess, getSpinnerFail, getSpinnerSuccess, publishManagePrizeFail, publishManagePrizeSuccess, updatePrizeImageFail, updatePrizeImageSuccess } from "./action";
import { call, put, takeEvery } from "redux-saga/effects";
import { ADD_PRIZE, ADD_SEGMENT, CHANGE_PRIZE_STATUS, FREEZE_PRIZE, GET_SPINNER, MANAGE_PRIZE, MANAGE_PRIZE_UPDATE_ID, PUBLISH_PRIZE, UPDATE_IMAGE } from "./actionType";
import toast from "react-hot-toast";

//Get All QR DETAILS
function* fetchQrDetails({ payload: { dispatch, readFile, compaginId } }) {
  try {
    const data = {};
    data['campaign'] = compaginId;
    const response = yield call(managePrize, data);
    if (response.status?.toLowerCase() === "success") {
      yield put(getAllPrizeSuccess(response?.data));
      if (response?.data?.prizes?.length > 0) {
        response?.data?.prizes?.map((item) => {
          if (item.prizeImg) {
            dispatch(readFile({ imageKey: item.prizeImg }))
          }
        })
      }
    } else {
      yield put(getAllPrizeFail(response));
    }
  } catch (error) {
    yield put(getAllPrizeFail(error));
  }
}
export function* ManagePrizeSaga() {
  yield takeEvery(MANAGE_PRIZE, fetchQrDetails);
}

// update prize
function* updatePrizeDetail({ payload: { id, data, getUpdatedRecord } }) {
  try {
    const response = yield call(updatePrizebyid, id, data);
    if (response?.status?.toLowerCase() === "success") {
      yield put(PrizeSuccess(response));
      toast.success(response?.message)
      getUpdatedRecord();
    } else {
      yield put(PrizeFail(response));
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(PrizeFail(error));
  }
}
export function* updatePrizeSaga() {
  yield takeEvery(MANAGE_PRIZE_UPDATE_ID, updatePrizeDetail);
}

// update prize
function* freezePrizeFunction({ payload: { data, getUpdatedRecord } }) {
  try {
    const response = yield call(freezePrize, data);
    if (response?.status?.toLowerCase() === "success") {
      yield put(PrizeSuccess(response));
      getUpdatedRecord();
      toast.success(response?.message)
    } else {
      yield put(PrizeFail(response));
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(PrizeFail(error));
  }
}
export function* FreezePrizeSaga() {
  yield takeEvery(FREEZE_PRIZE, freezePrizeFunction);
}

// add prize
function* addPrizeDetail({ payload: { data, history } }) {
  try {
    yield put(addPrizeLoading(true))
    const response = yield call(addprize, data);
    yield put(addPrizeLoading(false))
    if (response?.status?.toLowerCase() === "success") {
      yield put(addPrizeSuccess(response));
      history.push("/manage-prize");
      toast.success(response?.message)
    } else {
      yield put(addPrizeFail(response));
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(addPrizeLoading(false))
    yield put(addPrizeFail(error));
  }
}
export function* addPrizeSaga() {
  yield takeEvery(ADD_PRIZE, addPrizeDetail);
}

// update prize
function* updateSpinPrizeImage({ payload: { id, data, getUpdatedRecord } }) {
  try {
    const response = yield call(updateprizeimg, id, data, { headers: { "Content-Type": "multipart/form-data" } });

    if (response?.status?.toLowerCase() === "success") {
      yield put(updatePrizeImageSuccess(response));
      getUpdatedRecord()
      toast.success(response?.message)
    } else {
      yield put(updatePrizeImageFail(response));
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(updatePrizeImageFail(error));
  }
}
export function* updatePrizeImageSaga() {
  yield takeEvery(UPDATE_IMAGE, updateSpinPrizeImage);
}

//chnage status
function* changePrizeStatus({ payload: { id, status, getUpdatedRecord } }) {
  try {
    const data = {}
    data["status"] = status
    const response = yield call(prizestatuschnage, id, status);
    if (response?.status?.toLowerCase() === "success") {
      yield put(changePrizeSuccess(response));
      getUpdatedRecord()
      toast.success(response?.message)
    } else {
      yield put(changePrizeFail(response));
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(changePrizeFail(error));
  }
}
export function* changePrizeStatusSaga() {
  yield takeEvery(CHANGE_PRIZE_STATUS, changePrizeStatus);
}

//get all spinner data
function* fetchSpinnerDetails() {
  try {
    const response = yield call(manageSpinner);
    yield put(getSpinnerSuccess(response?.data));
  } catch (error) {
    yield put(getSpinnerFail(error));
  }
}
export function* ManageSpinnerSaga() {
  yield takeEvery(GET_SPINNER, fetchSpinnerDetails);
}

//Add Segments
function* createSegments({ payload: { data, afterSegmentAddSuccess } }) {
  try {
    const response = yield call(newSegments, data)

    if (response.status?.toLowerCase() === 'success') {
      if (afterSegmentAddSuccess) {
        afterSegmentAddSuccess();
      }
      yield put(addSegmentSuccess(response))
      toast.success(response.message)
    } else {
      toast.error(response.message)
    }
  } catch (error) {
    yield put(addSegmentFail(error))
    toast.error(error.response.data.message)
  }
}
export function* AddSegmentsSaga() {
  yield takeEvery(ADD_SEGMENT, createSegments)
}

//Prize Publish
function* prizePublish({ payload: { id, status, afterSuccess } }) {
  try {
    const data = {}
    data["status"] = status
    const response = yield call(managePrizePublish, id, status)
    if (response?.status?.toLowerCase() === "success") {
      yield put(publishManagePrizeSuccess(response))
      toast.success(response?.message);
      afterSuccess();
    } else {
      yield put(publishManagePrizeFail(response))
      toast.error(response?.message);
    }
  } catch (error) {
    yield put(publishManagePrizeFail(error))
  }
}
export function* PrizePublishSaga() {
  yield takeEvery(PUBLISH_PRIZE, prizePublish)
}