// Get All Admin 
export const GET_ALL_ADMIN = "GET_ALL_ADMIN"
export const GET_ALL_ADMIN_SUCCESS = "GET_ALL_ADMIN_SUCCESS"
export const GET_ALL_ADMIN_FAIL = "GET_ALL_ADMIN_FAIL"

//Add Admin
export const ADD_ADMIN = "ADD_ADMIN"
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS"
export const ADD_ADMIN_FAIL = "ADD_ADMIN_FAIL"
export const ADMIN_LOADING = "ADMIN_LOADING"

/* Get Admin By ID */
export const GET_ADMIN_BY_ID = "GET_ADMIN_BY_ID"
export const GET_ADMIN_BY_ID_SUCCESS = "GET_ADMIN_BY_ID_SUCCESS"
export const GET_ADMIN_BY_ID_FAIL = "GET_ADMIN_BY_ID_FAIL"

//Change Admin Status
export const STATUS = "STATUS"
export const STATUS_SUCCESS = "STATUS_SUCCESS"
export const STATUS_FAIL = "STATUS_FAIL"