import { segmentCount } from 'constants/SpinWheelSegment';
import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import Select from "react-select";
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import { addSegment, getAllPrize, readFile } from 'store/actions';
import { useDispatch } from 'react-redux';


const SegmentForm = ({ setSelecteSegment, segmentsName, setSegmentsName, compaginId, managePrizeData, setOpenItem }) => {

    const dispatch = useDispatch();

    const [segment, setSegment] = useState();

    const customValidation = (inputs) => {
        let errors = {};
        inputs.forEach((input, index) => {
            if (!input.segment) {
                errors[`segment[${index}].productCode`] = "Field 1 is required.";
            } else if (input.segment.length > 10) {
                errors[`segment[${index}].productCode`] = "Size should not exceed 10 characters.";
            }
        });
        return errors;
    };

    const { register, control, reset, handleSubmit, getValues, setValue, formState: { errors } } = useForm({
        validate: customValidation
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'segment',
    });

    const chooseSegments = (value) => {
        append(value);
        reset();
        setSegmentsName('');
        setSegment(value);
        setSelecteSegment(value?.value);
    };

    const onSubmit = (value) => {
        const data = {}
        data["segment"] = value?.segment
        data["segmentCount"] = Number(segment?.value)
        data["campaign"] = compaginId
        dispatch(addSegment(data, afterSegmentAddSuccess));
    };

    const afterSegmentAddSuccess = () => {
        if (compaginId && compaginId) {
            dispatch(getAllPrize(dispatch, readFile, compaginId));
            setOpenItem("3");
        }
    }

    const handeleGetName = () => {
        const formValues = getValues();
        setSegmentsName(formValues);
    };

    useEffect(() => {
        if (managePrizeData && managePrizeData?.length > 0) {
            managePrizeData?.forEach((segment, index) => {
                setValue(`segment[${index}].productCode`, segment?.productCode);
            });
            setSegment({ label: `${managePrizeData?.length}`, value: `${managePrizeData?.length}` });
            setSelecteSegment(`${managePrizeData?.length}`);
            handeleGetName();
        } else {
            setSelecteSegment();
            setSegment();
            setSegmentsName([]);
        }
    }, [managePrizeData, append]);

    const handleClear = () => {
        reset();
        setSegmentsName('');
    }

    return (
        <div className='segment-form'>
            <form
                className={`${managePrizeData !== undefined && managePrizeData.length > 0 ? "diableSelect" : ""}`} onSubmit={handleSubmit(onSubmit)}>
                <Label>Select your prize count</Label>
                <Select
                    className={`select-menu ${managePrizeData !== undefined && managePrizeData.length > 0 ? "diableSelect" : ""}`}
                    value={segment}
                    isDisabled={managePrizeData?.length > 0 ? true : false}
                    isSearchable={managePrizeData !== undefined && managePrizeData.length > 0 ? false : true}
                    defaultValue={segment}
                    onChange={(e) => { chooseSegments(e) }}
                    options={segmentCount}
                />
                <div>
                    {Array.from({ length: segment?.value || 0 }).map((_, index) => (
                        <div key={`segement-input-${index}`} className='segment-item'>
                            <Controller
                                control={control}
                                name={`segment[${index}].productCode`}
                                rules={{ required: true }}
                                defaultValue={index === 0 ? "No win" : ""}
                                render={({ field }) => (
                                    <input
                                        type="text"
                                        placeholder='Enter prize name'
                                        className={`form-control ${errors[`segment[${index}].productCode`] ? 'error' : ''}`}
                                        value={field.value}
                                        disabled={index === 0 ? true : false || managePrizeData?.length > 0 ? true : false}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handeleGetName();
                                        }}
                                    />
                                )}
                            />
                            {errors.segment && errors.segment[index] && errors.segment[index].productCode && (
                                <p className="error-message ">Prize name is required</p>
                            )}
                        </div>
                    ))}
                    <div className='d-flex gap-3 mt-3'>
                        <PrimaryButton btnName={'Save'} disabled={managePrizeData?.length > 0 ? true : false} />
                        <SecountryBtn btnName={"Clear"} btnType={"button"}
                            onClick={() => handleClear()}
                            disabled={managePrizeData?.length > 0 ? true : false}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default SegmentForm;