import SecountryBtn from "components/Common/Buttons/SecountryBtn";
import TableContainer from "components/Common/TableContainer";
import TableSkeleton from "components/Common/TableSkeleton";
import DocumentTitle from "components/DocumentTitle";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, UncontrolledTooltip } from "reactstrap";
import UploadAds from "components/Common/Modals/UploadAds";
import { AdsStatus, DeleteAd, getAllAdvertisement, readFile, readFileSuccess } from "store/manageads/actions";
import Confirmation from "components/Common/Modals/Confirmation";
import { useHistory } from "react-router-dom"
import getMimeType from "constants/getMimeType";
import PhotoView from "../../components/Common/Modals/PhotoView";
import playBack from "../../assets/images/svgicons/play-circle.svg";
import VideoView from "components/Common/Modals/videoView";

export default function ManageAds() {

    const dispatch = useDispatch();
    const history = useHistory()

    const [uploadadvertizeModal, setUploadadvertizeModal] = useState(false);
    const [activeModal, setActiveModal] = useState(false);
    const [statusdata, setstatusdata] = useState();
    const [deleteAdId, setDeleteAdId] = useState();
    const [adToDelete, setAdToDelete] = useState(false);
    const [fileSource, setFileSource] = useState();
    const [imageOpen, setImageOpen] = useState(false);
    const [videoOpen, setVideoOpen] = useState(false);

    const getallAds = useSelector((state) => state?.GetallAdds?.data?.images);
    const getallAdsLoading = useSelector((state) => state?.GetallAdds?.loading)
    const AdsactivateLoader = useSelector((state) => state?.AdsStatusChange?.loading)
    const AdsdeleteLoader = useSelector((state) => state?.DeleteAdById?.loading)
    const readAdsImage = useSelector((state) => state?.ReadAdvertisementImage?.data)
    const readAdsImageloader = useSelector((state) => state?.ReadAdvertisementImage?.loading)

    const getAdsList = () => {
        dispatch(getAllAdvertisement(dispatch, readFile));
    };
    useEffect(() => {
        getAdsList()
    }, []);


    const handleUploadAdvertizement = () => {
        setUploadadvertizeModal(true);
    };

    const getUpdatedRecord = () => {
        getAdsList()
        setActiveModal(false);
        setAdToDelete(false);
    };

    const adsActivateEvent = () => {
        const status = {
            status: statusdata?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
        }
        dispatch(AdsStatus(statusdata?._id, status, getUpdatedRecord));

    };

    const handleAdId = (adStatus) => {
        setstatusdata(adStatus)
        setActiveModal(true);
    };

    function deleteToggleModal() {
        setAdToDelete(!deleteAdId);
    }
    const DeleteAdAction = () => {
        dispatch(DeleteAd(deleteAdId, getUpdatedRecord));

    }

    const handleImageClick = (path) => {
        setImageOpen(true);
        setFileSource(path);
    };

    const handleVideoClick = (path) => {
        setVideoOpen(true);
        setFileSource(path);
    }

    const Header = useMemo(
        () => [

            {
                Header: `S.No`,
                Cell: (cellProps) => {
                    return (
                        <div >
                            <p className="m-0">{cellProps?.row?.index + 1}</p>
                        </div>
                    );
                },
            },
            {
                Header: `Advertisements`,
                Cell: (cellProps) => {
                    const path = readAdsImage[`${cellProps?.row?.original?.image}`]
                    let base64Encoded = null;
                   
                    if (path) {  
                        base64Encoded = path;
                    }

                    return (
                        <div className="m-0 td">
                            <div className="avatar-container">
                                <div className="avatar" >
                                    {cellProps?.row?.original?.fileType === "image" ?
                                        <img
                                            alt="ads-avatar"
                                            className="img-thumbnail cursor-pointer"
                                            src={`data:${getMimeType(base64Encoded)};base64,${base64Encoded}`}
                                            width={50}
                                            onClick={() =>
                                                handleImageClick(path ? `data:${getMimeType(base64Encoded)};base64,${base64Encoded}` : "")
                                            }
                                        /> :
                                        <img
                                            src={playBack}
                                            width={50}
                                            className="img-thumbnail cursor-pointer"
                                            alt="play-icon"
                                            onClick={() =>
                                                handleVideoClick(path ?  base64Encoded : "")
                                            }
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    )
                },
            },
            {
                Header: `Status`,
                Cell: (cellProps) => {
                    return (
                        <div>
                            <div className="square-switch "
                                onClick={() => {
                                    handleAdId(cellProps.row.original);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    // eslint-disable-next-line react/no-unknown-property
                                    switch="bool"
                                    checked={cellProps.row.original.status === "ACTIVE" ? true : false}
                                />
                                <label
                                    htmlFor={`${cellProps.row.original._id}`}
                                    data-on-label="Yes"
                                    data-off-label="No"
                                    className='m-0'
                                />
                            </div>
                        </div>
                    );
                },
            },
            {
                Header: `Action`,
                Cell: (cellProps) => {
                    return (
                        <div
                            onClick={() => {
                                const id = cellProps?.row?.original?._id;
                                setDeleteAdId(id);
                                setAdToDelete(true);
                            }} >
                            <i className="mdi mdi-trash-can-outline delete-box cursor-pointer" id="delete-tooltip" />
                            <UncontrolledTooltip placement="top" target="delete-tooltip">
                                Delete
                            </UncontrolledTooltip>
                        </div>
                    );
                },
            },

        ],
        [readAdsImage, getallAds]
    )


    return (
        <React.Fragment>
            <DocumentTitle title={`Manage Ads`} />
            <div className="page-content manage-ads">
                <Container fluid>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="page-title">Manage Ads</div>
                        <div className="add-button d-flex gap-2">
                            <SecountryBtn
                                btnName={"Custom Ads image"}
                                onClick={() => {
                                    history.push("/manage-ads/designTool")
                                }}
                            />
                            <SecountryBtn
                                btnName={"Upload Ads"}
                                onClick={() => {
                                    handleUploadAdvertizement()
                                }}
                            />
                        </div>
                    </div >
                    <Card>
                        {!getallAdsLoading && !readAdsImageloader && getallAds ? (
                            <div className="p-4">
                                <TableContainer
                                    columns={Header}
                                    data={getallAds}
                                    isGlobalFilter={false}
                                    isAddOptions={false}
                                    customPageSize={getallAds?.length}
                                    handleOrderClicks={() => null}
                                    iSPageOptions={false}
                                    className="custom-header-css"
                                />
                            </div>
                        ) : (
                            <TableSkeleton datacount={7} listcount={15} />
                        )}
                    </Card>
                    <UploadAds
                        showModal={uploadadvertizeModal}
                        setShowModal={setUploadadvertizeModal}
                    />
                    <Confirmation
                        showModal={activeModal}
                        toggle={() => setActiveModal(false)}
                        primaryAction={adsActivateEvent}
                        modalTitle={`Confirmation!`}
                        loader={AdsactivateLoader}
                        primaryBtn={`${statusdata?.status === "INACTIVE" ? 'Activate' : 'Suspend'}`}
                        secountBtn="Cancel"
                        description={`Are you sure do you want to ${statusdata?.status === "INACTIVE" ? 'activate' : 'suspend'} this Advertisement?`}
                    />
                    <Confirmation
                        showModal={adToDelete}
                        toggle={deleteToggleModal}
                        primaryAction={DeleteAdAction}
                        modalTitle={`Delete Advertisemant`}
                        loader={AdsdeleteLoader}
                        primaryBtn="Delete"
                        secountBtn="Cancel"
                        description={`Are you sure do you want to delete this Advertisement?`}
                    />
                </Container >
                <PhotoView
                    src={fileSource}
                    showModal={imageOpen}
                    setShowModal={setImageOpen}
                />
                 <VideoView
                    src={fileSource}
                    showModal={videoOpen}
                    setShowModal={setVideoOpen}
                />
            </div >
        </React.Fragment >

    )
}