import { addAdvertizement, changeStatus, deleteAd, manageAdds, manageAds, readAdvertizementFile } from "helpers/backend_helper";
import { ADD_ADVERTISEMNET, CHANGE_ADS_STATUS, DEL_AD, GET_ALL_ADDVERTISEMENT, READ_ADVERTISEMNET_IMAGE } from "./actionTypes";
import { AdsStatusFail, AdsStatusSuccess, DeleteAdError, addAdvertisementFail, addAdvertisementSuccess, getAllAdvertisementFail, getAllAdvertisementSuccess, readFileFail, readFileSuccess } from "./actions";
import { call, put, takeEvery } from "redux-saga/effects";
import toast from "react-hot-toast";

//Get All adds
function* fetchAdds({ payload: { dispatch, readFile } }) {
  try {
    yield put(readFileSuccess());
    const response = yield call(manageAds);
    if (response?.status?.toLowerCase() === 'success') {
      yield put(getAllAdvertisementSuccess(response));
      if (response?.images?.length > 0 && dispatch) {
        response?.images.map((item) => {
          dispatch(readFile({ imageKey: item.image }))
        })
      }
    } else {
      yield put(getAllAdvertisementFail(response));
    }
  } catch (error) {
    yield put(getAllAdvertisementFail(error));
  }
}
export function* ManageadsSaga() {
  yield takeEvery(GET_ALL_ADDVERTISEMENT, fetchAdds);
}
//ads status
function* adsStatus({ payload: { id, status, getUpdatedRecord } }) {
  try {
    const data = {}
    data["status"] = status
    const response = yield call(changeStatus, id, status);
    if (response.status?.toLowerCase() === "success") {
      toast.success(response.message);
      getUpdatedRecord()
      yield put(AdsStatusSuccess(response));
    } else {
      yield put(AdsStatusFail(response));
      toast.error(response.message);
    }
  } catch (error) {
    yield put(AdsStatusFail(error));
    toast.error(error.message);
  }
}

export function* adsStatusSaga() {
  yield takeEvery(CHANGE_ADS_STATUS, adsStatus);
}
//delete ads
function* deleteAdId({ payload: { id, getUpdatedRecord } }) {
  try {
    const response = yield call(deleteAd, id)
    if (response?.status?.toLowerCase() === 'success') {
      toast.success(response?.message)
      getUpdatedRecord()
      yield put(DeleteAdSuccess(response))
    } else {
      yield put(DeleteAdError(response))
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(DeleteAdError(error))
  }
}
export function* DeleteAdIdSaga() {
  yield takeEvery(DEL_AD, deleteAdId)
}
//add advertisement
function* addAdvertisementImage({ payload: { data, getUpdatedRecord } }) {
  try {
    const response = yield call(addAdvertizement, data, { headers: { "Content-Type": "multipart/form-data" } });
    if (response?.status?.toLowerCase() === "success") {
      yield put(addAdvertisementSuccess(response));
      toast.success(response?.message)
      if (getUpdatedRecord) getUpdatedRecord()
    } else {
      yield put(addAdvertisementFail(response));
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(addAdvertisementFail(error));
  }
}
export function* addAdvertisementImageSaga() {
  yield takeEvery(ADD_ADVERTISEMNET, addAdvertisementImage);
}

//read file advertisement
function* readAdvertisementImage({ payload: data }) {
  try {
    const response = yield call(readAdvertizementFile, data);
    if (response?.status?.toLowerCase() === "success") {
      yield put(readFileSuccess(response));
    } else {
      yield put(readFileFail(response));
      toast.error(response?.message)
    }
  } catch (error) {
    yield put(readFileFail(error));
  }
}
export function* ReadAdvertisementImageSaga() {
  yield takeEvery(READ_ADVERTISEMNET_IMAGE, readAdvertisementImage);
}
