//get all prize
export const MANAGE_PRIZE = "GET_ALL_PRIZE"
export const GET_MANAGE_PRIZE_SUCCESS = "GET_ALL_PRIZE_SUCCESS"
export const GET_MANAGE_PRIZE_FAIL = "GET_ALL_PRIZE_FAIL"

//manage prize update
export const MANAGE_PRIZE_UPDATE_ID = "GET_ALL_PRIZE_UPDATE_ID"
export const GET_MANAGE_PRIZE_SUCCESS_UPDATE_ID = "GET_ALL_PRIZE_SUCCESS_UPDATE_ID"
export const GET_MANAGE_PRIZE_FAIL_UPDATE_ID = "GET_ALL_PRIZE_FAIL_UPDATE_ID"

//freeze prize
export const FREEZE_PRIZE = "FREEZE_PRIZE"
export const FREEZE_PRIZE_SUCCESS = "FREEZE_PRIZE_SUCCESS"
export const FREEZE_PRIZE_FAIL = "FREEZE_PRIZE_FAIL"

//add prize
export const ADD_PRIZE = "ADD_PRIZE"
export const ADD_PRIZE_SUCCESS = "ADD_PRIZE_SUCCESS"
export const ADD_PRIZE_FAIL = "ADD_PRIZE_FAIL"
export const ADD_PRIZE_LOADING = "ADD_PRIZE_LOADING"

//update image
export const UPDATE_IMAGE = "UPDATE_IMAGE"
export const UPDATE_IMAGE_SUCCESS = "UPDATE_IMAGE_SUCCESS"
export const UPDATE_IMAGE_FAIL = "UPDATE_IMAGE_FAIL"

// chnage status
export const CHANGE_PRIZE_STATUS = "CHANGE_PRIZE_STATUS"
export const CHANGE_PRIZE_STATUS_SUCCESS = "CHANGE_PRIZE_STATUS_SUCCESS"
export const CHANGE_PRIZE_STATUS_FAIL = "CHANGE_PRIZE_STATUS_FAIL"

//get spinner
export const GET_SPINNER = "GET_SPINNER"
export const GET_SPINNER_SUCCESS = "GET_SPINNER_SUCCESS"
export const GET_SPINNER_FAIL = "GET_SPINNER_FAIL"

//Add Segment 
export const ADD_SEGMENT = "ADD_SEGMENT"
export const ADD_SEGMENT_SUCCESS = "ADD_SEGMENT_SUCCESS"
export const ADD_SEGMENT_FAIL = "ADD_SEGMENT_FAIL"

//Publish Prize
export const PUBLISH_PRIZE = "PUBLISH_PRIZE"
export const PUBLISH_PRIZE_SUCCESS = "PUBLISH_PRIZE_SUCCESS"
export const PUBLISH_PRIZE_FAIL = "PUBLISH_PRIZE_FAIL"
