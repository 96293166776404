import {
  GET_ALL_WINNERS,
  GET_WINNERS_FAIL,
  GET_WINNERS_SUCCESS,
  REDEEM_PRIZE,
  REDEEM_PRIZE_FAIL,
  REDEEM_PRIZE_SUCCESS
} from "./actionType";

//Get All QR DETAILS
const initialState = {
  data: [],
  error: {},
  loading: false,
};
export const GetallWinners = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_WINNERS:
      return {
        ...state,
        loading: true,
      };
    case GET_WINNERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case GET_WINNERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

//redeem prize
const redeem_initialState = {
  data: null,
  error: null,
  loading: false,
};
export const RedeemPrizeReducer = (state = redeem_initialState, action) => {
  switch (action.type) {
    case REDEEM_PRIZE:
      return {
        data: null,
        error: null,
        loading: true,
      };
    case REDEEM_PRIZE_SUCCESS:
      return {
        error: null,
        data: action.payload,
        loading: false,
      };

    case REDEEM_PRIZE_FAIL:
      return {
        data: null,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};