import { fontFamilyList, fontSizeList } from 'constants/DesignTool';
import React from 'react'
import Select from "react-select";
import boldSvg from '../../../assets/images/svgicons/designTool/bold.svg'
import italicSvg from '../../../assets/images/svgicons/designTool/italic.svg'
import underlineSvg from '../../../assets/images/svgicons/designTool/underline.svg'
import resetSvg from '../../../assets/images/svgicons/designTool/reset.svg'
import undoSvg from '../../../assets/images/svgicons/designTool/undo.svg'
import redoSvg from '../../../assets/images/svgicons/designTool/redo.svg'
import deleteIcon from '../../../assets/images/svgicons/designTool/delete.svg'
import zoominSvg from '../../../assets/images/svgicons/designTool/zoom-in.svg'
import zoomoutSvg from '../../../assets/images/svgicons/designTool/zoom-out.svg'
import saveIcon from '../../../assets/images/svgicons/designTool/save.svg'



export default function TopToolBar(
    {
        canvasInstance,
        selectedObj,
        selectedTool,
        selectedFonstFamily,
        handleFontFamilyChange,
        selectedFonstsize,
        handleFontSizeChange,
        textColor,
        handleColorChange,
        toggleBold,
        toggleunderline,
        toggleitalic,
        redoStack,
        actionStack,
        redo,
        undo,
        handleZoomIn,
        handleZoomOut,
        zoom,
        removeObject,
        handleToolClick,
        objectsLength,
        activeToggleModal,
        bold,
        underline, italic

    }
) {
    return (
        <div className='default-option'>

            {
                canvasInstance?.current?.getActiveObject() && selectedObj === "textbox" || selectedTool === "text" ?
                    <div className='more-tools'>
                        <div>
                            <Select
                                className='select-menu'
                                value={selectedFonstFamily}
                                onChange={handleFontFamilyChange}
                                options={fontFamilyList}
                            />
                        </div>
                        <div>
                            <Select
                                className='select-menu fs-size'
                                value={selectedFonstsize}
                                onChange={handleFontSizeChange}
                                options={fontSizeList}
                            />
                        </div>

                        <div className='option add-border'>
                            <input className='color-input' type="color" value={textColor} onChange={handleColorChange} />
                        </div>
                        <div className={`option add-border ${(canvasInstance?.current?.getActiveObject() && selectedObj === "textbox" && bold === true) ? "active" : ""} `} onClick={toggleBold}>
                            <img src={boldSvg} alt="" />
                            <span className="tooltip-text">Bold</span>
                        </div>
                        <div className={`option add-border ${(canvasInstance?.current?.getActiveObject() && selectedObj === "textbox" && underline === true) ? "active" : ""} `} onClick={toggleunderline}>
                            <img src={underlineSvg} alt="" />
                            <span className="tooltip-text">Underline</span>

                        </div>
                        <div className={`option add-border ${(canvasInstance?.current?.getActiveObject() && selectedObj === "textbox" && italic === true) ? "active" : ""} `} onClick={toggleitalic}>
                            <img src={italicSvg} alt="" />
                            <span className="tooltip-text">Italic</span>
                        </div>

                    </div>
                    :
                    <div></div>
            }

            <div className='more-tools icons-only'>
                <div className={`option ${redoStack?.length == 0 && "disable"} `} onClick={redoStack?.length == 0 ? null : redo}>
                    <img src={redoSvg} />
                    <span className="tooltip-text">Redo</span>
                </div>
                <div className={`option  ${actionStack?.length == 0 && "disable"}`} onClick={actionStack?.length == 0 ? null : undo}>
                    <img src={undoSvg} />
                    <span className="tooltip-text">Undo</span>
                </div>
                <div className={`option ${Math.round(zoom * 100) == 100 && "disable"}`} onClick={Math.round(zoom * 100) == 100 ? null : handleZoomIn}>
                    <img src={zoominSvg} />
                    <span className="tooltip-text">Zoom out</span>
                    {/* <span className="tooltip-text">{Math.round(zoom * 100)}%</span> */}
                </div>
                <div className={`option ${Math.round(zoom * 100) == 10 && "disable"}`} onClick={Math.round(zoom * 100) == 10 ? null : handleZoomOut}>
                    <img src={zoomoutSvg} />
                    <span className="tooltip-text">Zoom in</span>
                    {/* <span className="tooltip-text">{Math.round(zoom * 100)}%</span> */}
                </div>
                <div className={`option ${!canvasInstance?.current?.getActiveObject() && "disable"}`} onClick={!canvasInstance?.current?.getActiveObject() ? null : () => { removeObject(); handleToolClick('delete') }}>
                    <img src={deleteIcon} />
                    <span className="tooltip-text">Delete</span>
                </div>
                <div className={`option  ${objectsLength == 0 && "disable"}`} onClick={objectsLength == 0 ? null : () => {
                    activeToggleModal()
                    handleToolClick('clear')
                }}>
                    <img src={resetSvg} />
                    <span className="tooltip-text">Reset</span>
                </div>
                <div className={`option  ${objectsLength == 0 && "disable"}`} onClick={objectsLength == 0 ? null : () => {
                    activeToggleModal()
                    handleToolClick('save')
                }}>
                    <img src={saveIcon} />
                    <span className="tooltip-text">Save</span>
                </div>
            </div>
        </div>
    )
}
