//get all image
export const GET_ALL_ADDVERTISEMENT = "GET_ALL_ADDVERTISEMENT"
export const GET_ALL_ADDVERTISEMENT_SUCCESS = "GET_ALL_ADDVERTISEMENT_SUCCESS"
export const GET_ALL_ADDVERTISEMENT_FAIL = "GET_ALL_ADDVERTISEMENT_FAIL"
//change status
export const CHANGE_ADS_STATUS = "CHANGE_ADS_STATUS"
export const CHANGE_ADS_STATUS_SUCCESS = "CHANGE_ADS_STATUS_SUCCESS"
export const CHANGE_ADS_STATUS_FAIL = "CHANGE_ADS_STATUS_FAIL"
//delete ads
export const DEL_AD = "DEL_AD"
export const DEL_AD_SUCCESS = "DEL_AD_SUCCESS"
export const DEL_AD_FAIL = "DEL_AD_FAIL"
//add advertizement
export const ADD_ADVERTISEMNET = "ADD_ADVERTISEMNET "
export const ADD_ADVERTISEMNET_SUCCESS = "ADD_ADVERTISEMNET _SUCCESS"
export const ADD_ADVERTISEMNET_FAIL = "ADD_ADVERTISEMNET_FAIL"
export const ADD_ADVERTISEMNET_LOADING = "ADD_ADVERTISEMNET_LOADING"
//read advertizement file
export const READ_ADVERTISEMNET_IMAGE = "READ_ADVERTISEMNET_IMAGE "
export const READ_ADVERTISEMNET_IMAGE_SUCCESS = "READ_ADVERTISEMNET_IMAGE _SUCCESS"
export const READ_ADVERTISEMNET_IMAGE_FAIL = "READ_ADVERTISEMNET_IMAGE_FAIL"