import React from 'react'
import sampleImage from '../../../assets/images/designToolImage/bannerTwo.png'
import sampleBanner from '../../../assets/images/designToolImage/sampleBanner.jpg'
import sampleBanner2 from '../../../assets/images/designToolImage/bannerOne.jpg'
import fill from '../../../assets/images/designToolImage/bucket-fill.png'
import demo from '../../../assets/images/svgicons/edit-icon.svg'
import textIcon from '../../../assets/images/svgicons/designTool/text.svg'
import clipboardIcon from '../../../assets/images/svgicons/clipboard.svg'
import uploadLocal from '../../../assets/images/svgicons/upload-local.svg'
import closeArrow from '../../../assets/images/svgicons/designTool/close.svg'
import imageIcon from '../../../assets/images/svgicons/designTool/image.svg'


export default function MenuBar(
    {
        selectedTool,
        openMenuBar,
        handleToolClick,
        addText,
        setOpenMenu,
        backgroundColor,
        handleChangeBgColor,
        handleAddBackground,
        handleImageUpload,
        handleAddImage,
        handleUpload,
        openMenu,

    }
) {
    return (
        <>

            <div className='tool-sec'>
                <div className={`option ${selectedTool === "Background color" ? "active" : ""} `} onClick={() => { openMenuBar(); handleToolClick('Background color') }} >
                    <img src={fill} />
                    <span className="tooltip-text">Background color</span>
                </div>
                <div className={`option ${selectedTool === "background" ? "active" : ""} `} onClick={() => { openMenuBar(); handleToolClick('background') }}>
                    <img src={imageIcon} />
                    <span className="tooltip-text">Background</span>

                </div  >
                <div className={`option ${selectedTool === "text" ? "active" : ""} `} onClick={() => { addText(); handleToolClick('text'); setOpenMenu(false) }}>
                    <img src={textIcon} />
                    <span className="tooltip-text">Text</span>

                </div>
                <div className={`option ${selectedTool === "clipboard image" ? "active" : ""} `} onClick={() => { openMenuBar(); handleToolClick('clipboard image') }}>
                    <img src={clipboardIcon} />
                    <span className="tooltip-text">Clipboard </span>
                </div>
            </div>
            {
                openMenu &&
                <div className='relative-parent'>
                    <div className='tools-option'>
                        <div className='select-header'>
                            <h5>Select {selectedTool}</h5>
                            <img className='closeArrow' src={closeArrow} alt="close" onClick={() => setOpenMenu(false)} />
                        </div>
                        {
                            selectedTool === "Background color" &&
                            <div>
                                <input type="color" value={backgroundColor} onChange={handleChangeBgColor} />
                            </div>
                        }
                        {
                            selectedTool === "background" &&
                            <div>
                                <div className='image-template'>
                                    <img src={sampleBanner} onClick={() => { handleAddBackground(sampleBanner) }} />
                                    <img src={sampleBanner2} onClick={() => { handleAddBackground(sampleBanner2) }} />
                                </div>

                                <label htmlFor="file-upload" className="custom-file-upload">
                                    <input id="file-upload" type="file" accept=".png, .jpeg, .jpg" onChange={handleImageUpload} />
                                    <img src={uploadLocal} className='upload-icon' alt='file-upload' width={'22px'} /> Upload
                                </label>
                                <div>
                                    [Dimension:1080 x 1920px]
                                </div>
                                <div>
                                    [File Format:PNG,JPEG & JPG]
                                </div>

                            </div>
                        }
                        {
                            selectedTool === "clipboard image" &&
                            <>
                                <div className='image-template'>
                                    <img src={sampleImage} onClick={() => { handleAddImage(sampleImage) }} />
                                </div>
                                <div>
                                    <label htmlFor="file-upload" className="custom-file-upload">
                                        <input id="file-upload" type="file" accept=".png, .jpeg, .jpg" onChange={handleUpload} />
                                        <img src={uploadLocal} />
                                        Upload
                                    </label>

                                </div>
                            </>
                        }
                    </div>
                </div>
            }
        </>
    )
}
