import DocumentTitle from "components/DocumentTitle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Container, Card, CardBody, Label, } from "reactstrap";
import { campaignSettingsUpdate, getSettings } from "store/actions";

export default function Settings() {

    const dispatch = useDispatch()
    const history = useHistory();

    const [isCampaignEnabled, setIsCampaignEnabled] = useState(null);
    const [isadEnabled, setIsAdEnabled] = useState(null);
    const getllasettings = useSelector((state) => state?.GetSettings?.data?.settings);

    useEffect(() => {
        dispatch(getSettings())
    }, [])

    const handleCampaignCheckboxChange = (e) => {
        const value = e.target.checked;
        if (getllasettings && getllasettings.length > 0) {
            const campaign = getllasettings[0];
            const data = {
                isCampaignLive: !value,
            };
            dispatch(campaignSettingsUpdate(data, campaign?._id, history));
        }
    };

    const handleAdsCheckboxChange = (e) => {
        const value = e.target.checked;
        if (getllasettings && getllasettings.length > 0) {
            const campaign = getllasettings[0];
            const data = {
                isAdsLive: !value
            };
            dispatch(campaignSettingsUpdate(data, campaign?._id, history));
        }
    };

    useEffect(() => {
        if (getllasettings && getllasettings?.length > 0) {
            setIsCampaignEnabled(getllasettings[0].isCampaignLive);
            setIsAdEnabled(getllasettings[0].isAdsLive);
        }
    }, [getllasettings])

    return (
        <>
            <DocumentTitle title={`Settings`} />

            <div className="page-content settings-screen">
                <Container fluid>
                    <div className="page-title">Settings</div>
                    <Card className="settings-card">
                        <CardBody>
                            <div className="p-4 settings-conatiner">
                                <div className='pe-4'>
                                    <h3 className="page-title font-size-18 p-0 mb-3">Live campaign</h3>
                                    <label>Enable / Disable</label>
                                    <div className="square-switch">
                                        <input
                                            checked={isCampaignEnabled}
                                            type="checkbox"
                                            // eslint-disable-next-line react/no-unknown-property
                                            switch="bool"
                                            id='isEnabled'
                                            onClick={(e) => handleCampaignCheckboxChange(e)}
                                        />
                                        <label
                                            htmlFor={`isEnabled`}
                                            data-on-label="Yes"
                                            data-off-label="No"
                                            className="m-0"
                                        />
                                    </div>
                                </div>
                                <div className='pe-4'>
                                    <h3 className="page-title font-size-18 p-0 mb-3">Live ads</h3>
                                    <label>Enable / Disable</label>
                                    <div className="square-switch">
                                        <input
                                            checked={isadEnabled}
                                            type="checkbox"
                                            // eslint-disable-next-line react/no-unknown-property
                                            switch="bool"
                                            id='isAdEnabled'
                                            onClick={(e) => handleAdsCheckboxChange(e)}
                                        />
                                        <label
                                            htmlFor={`isAdEnabled`}
                                            data-on-label="Yes"
                                            data-off-label="No"
                                            className="m-0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    )
}