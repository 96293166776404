import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import Webcam from "react-webcam";
import reTake from "../../../assets/images/svg/icons/take.svg";
import take from "../../../assets/images/svg/icons/re_take.svg";

const WebCam = ({ setImage, showModel, setShowModal, qrid }) => {
  const [image, setImagesrc] = useState();

  const togglemodal = () => {
    setShowModal(!showModel);
  };

  const webcamRef = useRef(null);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImagesrc(imageSrc);
  }, [webcamRef]);

  return (
    <div>
      <Modal
        size="lg"
        isOpen={showModel}
        centered={true}
        className="doc-modal confirmation web-cam-modal"
      // toggle={togglemodal}
      >
        <div className="modal-header px-0 border-0">
          <div className="head-item">
            <span
              onClick={() => {
                setImagesrc();
                togglemodal();
              }}
              aria-hidden="true"
              className="close-icon"
            >
              <i className="bx bx-x"></i>
            </span>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          {!image ? (
            <div className="webcam-container">
              <div className="overlay">
                <div className="overlay-element top-left"></div>
                <div className="overlay-element top-right"></div>
                <div className="overlay-element bottom-left"></div>
                <div className="overlay-element bottom-right"></div>
              </div>
              <Webcam
                audio={false}
                height={720}
                screenshotQuality={1}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ facingMode: "environment", width: 720, height: 720 }}
                width={720}
              // style={{ width: "1000px", height: "1000px", objectFit: "cover", objectPosition: "center" }}
              />
            </div>
          ) : (
            <div className="webcam-container">
              <img src={image} alt="photo" />
            </div>
          )}
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              justifyContent: "center",
            }}
          >
            {!image && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => capture(qrid)}
              >
                <img
                  id="take"
                  style={{ width: "30px" }}
                  src={take}
                  alt="take"
                />
                <UncontrolledTooltip placement="top" target={`take`}>
                  Capture
                </UncontrolledTooltip>
              </div>
            )}
            {image && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setImagesrc();
                }}
              >
                <img
                  id="re-take"
                  style={{ width: "25px" }}
                  src={reTake}
                  alt="retake"
                />
                <UncontrolledTooltip placement="top" target={`re-take`}>
                  Re-take
                </UncontrolledTooltip>
              </div>
            )}
          </div>
          {image && (
            <div style={{ paddingTop: "20px" }}>
              <button
                className="primary-button"
                style={{ width: "auto" }}
                onClick={() => {
                  setImage(image, qrid);
                  setImagesrc();
                }}
              >
                Continue to Redeem
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default WebCam;
