
import { GENERATE_QRCODES, SET_STATUS_QRCODES, DEL_QRCODES, GET_ALL_QRCODES, GET_QRCODES_BY_ID, ISSUE_QRCODES, SEND_QRCODE } from "./actionTypes";
import { call, put, takeEvery } from "redux-saga/effects"
import {
    getAllQrcodesSuccess,
    getAllQrcodesFail,
    getQrcodesByIdSuccess,
    getQrcodesIdFail,
    addNewQrcodesSuccess,
    addNewQrcodesError,
    qrcodesLoading,
    setStatusQrcodesError,
    setStatusQrcodesSuccess,
    DeleteQrcodesError,
    issueQrcodesSuccess,
    issueQrcodesError,
    sendQrcodesSuccess,
    sendQrcodesError,
} from "./actions";
import { addNewQrcodes, deleteQrcodes, getAllQrcodes, getQrcodesId, issueQr, sendQRCodes, setStatusNewQrcodes } from "helpers/backend_helper";
import toast from "react-hot-toast";
import { addNewUserError } from "store/actions";

//Get All Admnins
function* fetchQrcodes() {
    try {
        const response = yield call(getAllQrcodes);
        yield put(getAllQrcodesSuccess(response))
    } catch (error) {
        yield put(getAllQrcodesFail(error))
    }
}
export function* QrcodesSaga() {
    yield takeEvery(GET_ALL_QRCODES, fetchQrcodes)
}

//Get   qrcodes   id
function* qrcodesId({ payload: id }) {
    try {
        const response = yield call(getQrcodesId, id)
        yield put(getQrcodesByIdSuccess(response))
    } catch (error) {
        yield put(getQrcodesIdFail(error))
    }
}
export function* GetQrcodesIdSaga() {
    yield takeEvery(GET_QRCODES_BY_ID, qrcodesId)
}

//Delete qrcodes by id
function* deleteQrcodesId({ payload: id }) {
    try {
        const response = yield call(deleteQrcodes, id?.id)
        if (response.status.toLowerCase() === 'error') {
            toast.error(response.message)
        } else {
            yield put(DeleteQrcodesSuccess(response))
            toast.success(response.message)
        }
    } catch (error) {
        yield put(DeleteQrcodesError(error))
    }
}
export function* DeleteQrcodesIdSaga() {
    yield takeEvery(DEL_QRCODES, deleteQrcodesId)
}

//add qrcodes
function* generateQrcodes({ payload: { count } }) {
    try {
        yield put(qrcodesLoading(true));

        const apiCall = addNewQrcodes;

        const response = yield call(apiCall, { count: count });

        yield put(qrcodesLoading(false))
        if (response.status === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(addNewQrcodesSuccess(response))
        }
    } catch (error) {
        yield put(qrcodesLoading(false))
        yield put(addNewQrcodesError(error))
    }
}
export function* AddQrcodesSaga() {
    yield takeEvery(GENERATE_QRCODES, generateQrcodes)
}

//set status qrcodes
function* setStatusQrcodes({ payload: { Qrnumbers } }) {
    try {
        const apiCall = setStatusNewQrcodes;
        const response = yield call(apiCall, { qrnumbers: Qrnumbers });

        if (response.status === 'error') {
            toast.error(response.message)
        } else {
            toast.success(response.message)
            yield put(setStatusQrcodesSuccess(response))
        }
    } catch (error) {
        yield put(qrcodesLoading(false))
        yield put(setStatusQrcodesError(error))
    }
}
export function* SetStatusQrcodesSaga() {
    yield takeEvery(SET_STATUS_QRCODES, setStatusQrcodes)
}

//ISSUE qrcodes
function* issueQrcodes({ payload: { count, data } }) {
    try {
        const apiCall = issueQr;
        const response = yield call(apiCall, count, data);

        if (response?.status?.toLowerCase() === 'error') {
            toast.error(response.message)
            yield put(issueQrcodesError(response))
        } else {
            yield put(issueQrcodesSuccess(response?.qrlist))
        }
    } catch (error) {
        yield put(issueQrcodesError(error))
    }
}
export function* issueQrcodesSaga() {
    yield takeEvery(ISSUE_QRCODES, issueQrcodes)
}

//Send Qr Code
//Add & Update User
function* sendNewQrCode({ payload: { data, handleClear } }) {

    try {
        const response = yield call(sendQRCodes, data)
        if (response.status?.toLowerCase() === 'error') {
            toast.error(response.message)
            yield put(sendQrcodesError(response))
        } else {
            toast.success(response.message)
            yield put(sendQrcodesSuccess(response))
            handleClear();
        }
    } catch (error) {
        yield put(sendQrcodesError(error))
        toast.error(error.response.data.message)
    }
}
export function* SendQRCodeSaga() {
    yield takeEvery(SEND_QRCODE, sendNewQrCode)
}