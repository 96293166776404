
//Get Admin Role
export const GET_ADMIN_ROLES = "GET_ADMIN_ROLES"
export const GET_ADMIN_ROLES_SUCCESS = "GET_ADMIN_ROLES_SUCCESS"
export const GET_ADMIN_ROLES_FAIL = "GET_ADMIN_ROLES_FAIL"

//Get Admin Role Type
export const GET_ADMIN_ROLETYPE = "GET_ADMIN_ROLETYPE"
export const GET_ADMIN_ROLETYPE_SUCCESS = "GET_ADMIN_ROLETYPE_SUCCESS"
export const GET_ADMIN_ROLETYPE_FAIL = "GET_ADMIN_ROLETYPE_FAIL"
