import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { authProtectedRoutes, publicRoutes } from "./routes";
import Authmiddleware from "./routes/route";
import { Toaster } from 'react-hot-toast';
import jwt_decode from "jwt-decode";
import { campaignOptions, getCampaignDetails, getImagePath } from 'store/actions';
import { authInfoSuccess } from 'store/authInfo/action';
import { logoutUser } from "./store/actions"
import AuthVerify from "./helpers/AuthVerify";
// layouts Format
import 'react-intl-tel-input/dist/main.css';
import 'react-loading-skeleton/dist/skeleton.css';
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

import tempOne_A from './assets/images/wheels/template-one/temp-1.png';
import tempOne_B from './assets/images/wheels/template-one/temp-2.png';
import tempOne_C from './assets/images/wheels/template-one/temp-3.png';
import tempOne_D from './assets/images/wheels/template-one/temp-4.png';
import tempTwo_A from './assets/images/wheels/template-two/temp-1a.png';
import tempTwo_B from './assets/images/wheels/template-two/temp-2a.png';
import tempTwo_C from './assets/images/wheels/template-two/temp-3a.png';
import tempTwo_D from './assets/images/wheels/template-two/temp-4a.png';
import tempTwo_E from './assets/images/wheels/template-two/temp-5a.png';
import tempTwo_F from './assets/images/wheels/template-two/temp-6a.png';
import tempThree_A from './assets/images/wheels/template-three/prize-360.png'
import tempThree_B from './assets/images/wheels/template-three/prize-100.png'
import tempThree_C from './assets/images/wheels/template-three/prize-10.png'
import tempThree_D from './assets/images/wheels/template-three/prize-36.png'
import tempThree_E from './assets/images/wheels/template-three/no-win.png'
import tempThree_F from './assets/images/wheels/template-three/grand-prize.png'
import tempThree_G from './assets/images/wheels/template-three/second-prize.png'
import tempThree_H from './assets/images/wheels/template-three/third-prize.png'
import tempFoure_A from './assets/images/wheels/template-foure/temp-a.png'
import tempFoure_B from './assets/images/wheels/template-foure/temp-b.png'
import tempFoure_C from './assets/images/wheels/template-foure/temp-c.png'
import tempFoure_D from './assets/images/wheels/template-foure/temp-d.png'
import tempFoure_E from './assets/images/wheels/template-foure/temp-e.png'
import tempFoure_F from './assets/images/wheels/template-foure/temp-f.png'
import tempFoure_G from './assets/images/wheels/template-foure/temp-g.png'
import tempFoure_H from './assets/images/wheels/template-foure/temp-h.png'
import tempFoure_I from './assets/images/wheels/template-foure/temp-i.png'
import tempFoure_J from './assets/images/wheels/template-foure/temp-j.png'



const App = props => {

  const dispatch = useDispatch();

  const accessToken = localStorage.getItem('authUser');

  const campaignData = useSelector((state) => state?.GetCampaignDetails?.data?.data);

  useEffect(() => {
    if (accessToken) {
      const decoded = jwt_decode(accessToken);
      dispatch(authInfoSuccess(decoded))
    }
  }, [accessToken]);

  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }
  // const obj = JSON.parse(localStorage.getItem("authUser"));
  // useEffect(() => {
  //   if (obj) {
  //     const imageKey = { imageKey: obj?.user?.profileImageKey }
  //     if (obj?.user?.profileImageKey) {
  //       dispatch(getImagePath(imageKey))
  //     }

  //   }
  // }, [obj?.user?.role])

  const Layout = getLayout();

  const history = useHistory();

  const logOut = () => {
    dispatch(logoutUser(history))
  };

  useEffect(() => {
    dispatch(getCampaignDetails());
  }, [])

  useEffect(() => {
    if (campaignData && campaignData?.length > 0) {
      const newCampaignOptions = campaignData.map((item) => {
        return {
          label: item?.campaignName,
          value: item?._id,
          status: item?.status
        };
      });
      dispatch(campaignOptions(newCampaignOptions))
    }
  }, [campaignData])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              role={route.role}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
        {<AuthVerify logOut={logOut} />}
      </Router>
      <Toaster position="bottom-right" reverseOrder={false} toastOptions={{
        success: {
          iconTheme: {
            primary: '#fff',
            secondary: '#34c38f',
          },
          style: {
            background: '#34c38f',
            color: '#fff',
          },

        },
        error: {
          iconTheme: {
            primary: 'white',
            secondary: '#f46a6a',
          },
          style: {
            background: '#f46a6a',
            color: '#fff',
          },
        },
      }} />

      <div className='d-none'>
        <img src={tempOne_A} alt='tempOne_A'/>
        <img src={tempOne_B} alt='tempOne_A' />
        <img src={tempOne_C} alt='tempOne_A'/>
        <img src={tempOne_D} alt='tempOne_A'/>
        <img src={tempTwo_A} alt='tempTwo_A'/>
        <img src={tempTwo_B} alt='tempTwo_A'/>
        <img src={tempTwo_C} alt='tempTwo_A'/>
        <img src={tempTwo_D} alt='tempTwo_A'/>
        <img src={tempTwo_E} alt='tempTwo_A'/>
        <img src={tempTwo_F} alt='tempTwo_A'/>
        <img src={tempThree_A} alt='tempThree_A'/>
        <img src={tempThree_B} alt='tempThree_A'/>
        <img src={tempThree_C} alt='tempThree_A'/>
        <img src={tempThree_D} alt='tempThree_A'/>
        <img src={tempThree_E} alt='tempThree_A'/>
        <img src={tempThree_F} alt='tempThree_A'/>
        <img src={tempThree_G} alt='tempThree_A'/>
        <img src={tempThree_H} alt='tempThree_A'/>
        <img src={tempFoure_A} alt='tempFoure_A'/>
        <img src={tempFoure_B} alt='tempFoure_B'/>
        <img src={tempFoure_C} alt='tempFoure_C'/>
        <img src={tempFoure_D} alt='tempFoure_D'/>
        <img src={tempFoure_E} alt='tempFoure_E'/>
        <img src={tempFoure_F} alt='tempFoure_F'/>
        <img src={tempFoure_G} alt='tempFoure_G'/>
        <img src={tempFoure_H} alt='tempFoure_H'/>
        <img src={tempFoure_I} alt='tempFoure_I'/>
        <img src={tempFoure_J} alt='tempFoure_J'/>
      </div>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
