import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import SecountryBtn from "components/Common/Buttons/SecountryBtn";
//import QRCode from "react-qr-code";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { issueQrcodes, printConfirm } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "components/Common/PageLoader";
import { useParams } from "react-router-dom";

import QRCode from "qrcode.react";
import { useReactToPrint } from "react-to-print";

import minusIcon from "../../assets/images/svg/printQr/ic-minus.svg";
import plusIcon from "../../assets/images/svg/printQr/ic-plus.svg";
import moment from "moment";
import AppLogo from '../../assets/images/logo.png'
import DocumentTitle from "components/DocumentTitle";

const PrintQrForm = (props) => {
  const { eventData } = props;

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => ({
    loading: state.issueQrcodes.loading,
  }));

  const campaignDetail = useSelector(state => state.GetCampaignDetails.data);

  const { issueqrcodes, printStatus } = useSelector((state) => ({
    issueqrcodes: state?.issueQrcodes?.qrCodes,
    printStatus: state?.issueQrcodes?.print,
  }));

  const [qrCount, setQrCount] = useState(1);
  const PrintQRForm = {
    noofQR: "",
  };

  const PrintQRFormSchema = Yup.object().shape({
    noofQR: Yup.string().required("Enter the  No. of QR Code Required").trim(),
  });

  const {
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PrintQRFormSchema),
    defaultValues: PrintQRForm,
  });

  //set form values
  const setFormValues = () => {
    reset({
      noofQR: 1,
    });
    setQrCount(1)
  };

  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      // componentRef.current = null;
      setFormValues();
    },
    onPrintError: (error) => {
      console.log("error", error);
    },
  });

  const increment = () => {
    setQrCount((prev) => {
      if (prev >= 19) return 20;
      return prev + 1
    });
  };
  const decrement = () => {
    setQrCount((prev) => {
      if (prev > 2 && prev != 2) return prev - 1;
      else return (prev = 1);
    });
  };
  const printQr = async () => {
    dispatch(issueQrcodes(qrCount, { campaign: eventData?._id }));
  };

  useEffect(() => {
    if (printStatus) {
      setTimeout(async () => {
        await handlePrint();
        await dispatch(printConfirm(false));
      }, 1000);
    }
  }, [printStatus]);

  return (
    <React.Fragment>
      <div className="print-qr">
        {loading || printStatus ? <PageLoader /> : ""}
        <Container fluid={true}>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  {eventData ? (
                    <>
                      <Row>
                        <Col md="12">
                          <div className="quantity">
                            <h4 className="mb-4"><strong>Campaign: {eventData?.campaignName}</strong></h4>
                            <p>Quantity</p>
                            <p className="limit">Maximum allowed limit is 20 per print</p>
                          </div>
                          <div className="d-flex justify-content-center print-count">
                            <button style={{ cursor: qrCount <= 1 ? "text" : "pointer", opacity: qrCount <= 1 ? 0.2 : 1 }} className="buttons" onClick={decrement}><img src={minusIcon}></img></button>
                            <span className="d-flex justify-content-center align-items-center count">
                              {qrCount}
                            </span>
                            <button style={{ cursor: qrCount >= 20 ? "text" : "pointer", opacity: qrCount >= 20 ? 0.2 : 1 }} className="buttons" onClick={increment}><img src={plusIcon}></img></button>
                          </div>
                          <div className="d-flex justify-content-center">
                            <PrimaryButton
                              btnName="Print"
                              onClick={() => printQr()}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <p className="campaign-details pt-5">No active campaign</p>
                  )}
                </CardBody>
              </Card>
              <div>
                <div style={{ display: "none" }}>
                  <div ref={componentRef}>
                    {issueqrcodes?.length > 0 &&
                      issueqrcodes?.map((item, index) => {
                        const date = moment(item?.issuedDateAndTime).format(
                          "MMMM Do YYYY"
                        );
                        const time = moment(item?.issuedDateAndTime).format(
                          "LT"
                        );
                        return (
                          <>
                            <div className="print-container">
                              <div className="p-header">
                                <div className="print-logo">
                                  <img src={AppLogo} width={80} alt="logo" />
                                </div>
                                <h2>
                                  The {eventData?.campaignName || ""}
                                  <br />
                                  Lucky Draw Ticket
                                </h2>
                                <span>
                                  Issued on:{" "}
                                  {item?.issuedDateAndTime
                                    ? date + ", " + time
                                    : ""}
                                </span>
                              </div>
                              <div className="qr-container">
                                <div className="qt-code">
                                  <QRCode value={item.qrCode} size={150} />
                                </div>
                                <p>{item.qrCode}</p>
                              </div>
                              <div className="print-footer">
                                <h3>
                                  Scan this QR ticket at the kiosk in
                                  <br /> front of the entrance. Good luck!
                                </h3>
                                <p>
                                  This QR ticket is for one-time use only. Contact our staff <br />if you encounter any issues with the ticket.
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrintQrForm;
