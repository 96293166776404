//update settings
export const SETTINGS_UPDATE = "SETTINGS_UPDATE"
export const SETTINGS_UPDATE_SUCCESS = "SETTINGS_UPDATE_SUCCESS"
export const SETTINGS_UPDATE_FAIL = "SETTINGS_UPDATE_FAIL"
export const SETTINGS_UPDATE_LOADING = "SETTINGS_UPDATE_LOADING"

//get settings
export const GET_SETTINGS = "GET_SETTINGS"
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS"
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL"
export const GET_SETTINGS_LOADING = "GET_SETTINGS_LOADING"

//get notification
export const GET_NOTIFICATION = "GET_NOTIFICATION"
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"
export const GET_NOTIFICATION_LOADING = "GET_NOTIFICATION_LOADING"

//campaign settings
export const CAMPAIGN_SETTINGS_UPDATE = "CAMPAIGN_SETTINGS_UPDATE"
export const CAMPAIGN_SETTINGS_UPDATE_SUCCESS = "CAMPAIGN_SETTINGS_UPDATE_SUCCESS"
export const CAMPAIGN_SETTINGS_UPDATE_FAIL = "CAMPAIGN_SETTINGS_UPDATE_FAIL"

//campaign settings
export const GET_CAMPAIGN_DETAILS = "GET_CAMPAIGN_DETAILS"
export const GET_CAMPAIGN_DETAILS_SUCCESS = "GET_CAMPAIGN_DETAILS_SUCCESS"
export const GET_CAMPAIGN_DETAILS_FAIL = "GET_CAMPAIGN_DETAILS_FAIL"

//campaign options
export const CAMPAIGN_OPTIONS = "CAMPAIGN_OPTIONS"

//campaign settings
export const UPDATE_CAMPAIGN_DETAILS = "UPDATE_CAMPAIGN_DETAILS"
export const UPDATE_CAMPAIGN_DETAILS_SUCCESS = "UPDATE_CAMPAIGN_DETAILS_SUCCESS"
export const UPDATE_CAMPAIGN_DETAILS_FAIL = "UPDATE_CAMPAIGN_DETAILS_FAIL"

//Add Campaign 
export const ADD_CAMPAIGN_DETAILS = "ADD_CAMPAIGN_DETAILS"
export const ADD_CAMPAIGN_DETAILS_SUCCESS = "ADD_CAMPAIGN_DETAILS_SUCCESS"
export const ADD_CAMPAIGN_DETAILS_FAIL = "ADD_CAMPAIGN_DETAILS_FAIL"

//Get Campaign
export const GET_BY_CAMPAIGN_ID = "GET_BY_CAMPAIGN_ID"
export const GET_BY_CAMPAIGN_ID_SUCCESS = "GET_BY_CAMPAIGN_ID_SUCCESS"
export const GET_BY_CAMPAIGN_ID_FAIL = "GET_BY_CAMPAIGN_ID_FAIL"