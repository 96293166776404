import {
    GET_ADMIN_ROLES,
    GET_ADMIN_ROLES_FAIL,
    GET_ADMIN_ROLES_SUCCESS,
    GET_ADMIN_ROLETYPE,
    GET_ADMIN_ROLETYPE_FAIL,
    GET_ADMIN_ROLETYPE_SUCCESS
} from "./actionsType"

//Get Admin Roles
const roleInitialState = {
    roles: [],
    error: {},
    loader: false,
}
export const GetAdminRoles = (state = roleInitialState, action) => {
    switch (action.type) {
        case GET_ADMIN_ROLES:
            return {
                ...state,
                loader: true,
            }

        case GET_ADMIN_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload,
                loader: false,
            }

        case GET_ADMIN_ROLES_FAIL:
            return {
                ...state,
                error: action.payload,
                loader: false,
            }
        default:
            return state
    }
}

//Get Admin Roles
const roleTypeInitialState = {
    rolesType: [],
    error: {},
    loader: false,
}
export const GetAdminRolesType = (state = roleTypeInitialState, action) => {
    switch (action.type) {
        case GET_ADMIN_ROLETYPE:
            return {
                ...state,
                loader: true,
            }

        case GET_ADMIN_ROLETYPE_SUCCESS:
            return {
                ...state,
                rolesType: action.payload,
                loader: false,
            }

        case GET_ADMIN_ROLETYPE_FAIL:
            return {
                ...state,
                error: action.payload,
                loader: false,
            }
        default:
            return state
    }
}