import { AUTH_lOGIN_INFO, AUTH_lOGIN_INFO_SUCCESS } from "./actionTypes"

export const authLoginInfo = (user) => {
    return {
      type: AUTH_lOGIN_INFO,
      payload: { user },
    }
  }
  
  export const authInfoSuccess = (user) => {
    return {
      type: AUTH_lOGIN_INFO_SUCCESS,
      payload: {user},
    }
  }