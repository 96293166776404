import TableContainer from "components/Common/TableContainer";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { changePrize, getAllPrize, getSpinner, PrizeUpdate, publishManagePrize, updatePrizeImage } from "store/managePrize/action";
import save from "../../assets/images/svg/managePrize/ic-tick-ok.svg";
import cancel from "../../assets/images/svg/managePrize/ic-x-cancel.svg";
import SecountryBtn from "components/Common/Buttons/SecountryBtn";
import TableSkeleton from "components/Common/TableSkeleton";
import { freezePrize, getCampaignDetails, readFile } from "../../store/actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import avatarsquare from '../../assets/images/image-square.png'
import edit from "./../../assets/images/image-edit.png"
import View from "./../../assets/images/view-mode.png"
import PhotoView from "components/Common/Modals/PhotoView";
import Confirmation from "components/Common/Modals/Confirmation";
import SpinnerModal from "components/Common/Modals/SpinnerModal";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import edit_icon from "../../assets/images/svgicons/edit-icon.svg";
import DocumentTitle from "components/DocumentTitle";
import getMimeType from "constants/getMimeType";

export default function ManagePrize({ compaginId, setManagePrizeData, currentCampaignData }) {

  const history = useHistory();
  const dispatch = useDispatch();

  const [editID, setEditID] = useState();
  const [statusdata, setstatusData] = useState();
  const [spinnerPreviewModal, setspinnerPreviewModal] = useState(false)
  const [editedValue, setEditedValue] = useState({
    productCode: "",
    totalPrizes: "",
    probability: 0,
    focus: null
  });
  const [totalProbability, setTotalProbability] = useState();
  const [currentProbability, setCurrentProbability] = useState();

  const allPrize = useSelector((state) => state?.GetallPrize?.QRlist);
  const allPrizeLength = useSelector((state) => state?.GetallPrize?.QRlist?.prizes?.length);
  const allPrizeLoading = useSelector((state) => state?.GetallPrize?.loading);
  const updatePrizeLoading = useSelector((state) => state?.updatePrize?.loading);
  const SpinWheelImageData = useSelector((state) => state?.GetSpinner?.spinnerData);
  const readAdsImage = useSelector((state) => state?.ReadAdvertisementImage?.data)
  const readAdsImageloader = useSelector((state) => state?.ReadAdvertisementImage?.loading)

  const [avatarImage, setAvatarImage] = useState(avatarsquare);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [prizePublishModal, setPrizePublishModal] = useState(false);

  function activeToggleModal() {
    setChangeStatusModal(!changeStatusModal);
  }

  const toggle = (rowIndex) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [rowIndex]: !prevState[rowIndex],
    }));
  };

  const getPrizeList = () => {
    dispatch(getAllPrize(dispatch, readFile, compaginId));
  };
  useEffect(() => {
    if (compaginId && compaginId) {
      getPrizeList();
    }
  }, [compaginId]);

  //image upload
  const handleViewClick = (imageUrl) => {
    setSelectedImage(imageUrl || avatarsquare);
    setViewImageModal(true);
  };

  const handleFileChange = (event, id, type) => {
    const file = event.target.files[0];
    dispatch(updatePrizeImage(id, { type, image: file }, getUpdatedRecord));
  };

  const handleInputChange = (e, field, type) => {
    let { value, min, max } = e.target;
    const numericValue = Number(value);
    if (type === 'number') {
      value = Math.max(Number(min), Math.min(Number(max), numericValue));
    } else if (type === 'string') {
      value = value;
    }
    setEditedValue((prevState) => ({
      ...prevState,
      [field]: value,
      focus: field
    }));
    if (field === 'probability') {
      setTotalProbability(allPrize?.total - currentProbability + value);
    }
  };

  const getUpdatedRecord = () => {
    getPrizeList();
    setEditID();
  };

  const handleApprove = (data, id) => {

    dispatch(PrizeUpdate(id, data, getUpdatedRecord));
  };

  const handleCheckBoxClick = (id, selected) => {
    const data = {};
    data['id'] = id;
    if (selected) {
      data['selected'] = false;
    } else {
      data['selected'] = true;
    }
    dispatch(freezePrize(data, getUpdatedRecord))
  }
  useEffect(() => {
    if (compaginId) {
      setManagePrizeData(allPrize?.prizes);
    }
  }, [allPrize, compaginId]);
  //spinner preview modal
  const handlespinnerPreviewClick = async () => {
    await dispatch(getSpinner());
    setspinnerPreviewModal(true);
  };

  //add prize navigation
  const AddingPrize = () => {
    history.push(`/manage-prize/add-prize`);
  }

  //status chnage 
  const changeActiveStatus = () => {
    const status = {
      status: statusdata?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
    }
    dispatch(changePrize(statusdata?._id, status, getUpdatedRecord));
    setChangeStatusModal(false);
  };

  //badge color
  const getSubcategoryBadgeColor = status => {
    return status === "ACTIVE" ? "success" : "danger"
  }

  const prizeToogleModal = () => {
    setPrizePublishModal(!prizePublishModal)
  };

  const publishCampaign = () => {
    const status = {
      status: currentCampaignData?.status === "INACTIVE" ? "ACTIVE" : "INACTIVE",
    }
    dispatch(publishManagePrize(compaginId, status, afterPublishSuccess))
    prizeToogleModal();

  }

  const afterPublishSuccess = () => {
    getPrizeList();
    dispatch(getCampaignDetails());
  }

  //table
  const Header = useMemo(
    () => [
      {
        Header: `S.No`,
        Cell: (cellProps) => {
          return (
            <div >
              <p className="m-0">{cellProps?.row?.index + 1}</p>
            </div>
          );
        },
      },
      {
        Header: `Prize`,
        Cell: (cellProps) => {
          return (
            <div>
              <div>
                {editID == cellProps?.row?.original?._id && cellProps?.row?.original?.productCode !== "No win" ? (
                  <>
                    <input
                      style={{
                        maxWidth: "80px",
                        padding: "8px",
                        textAlign: "center",
                        borderRadius: "5px",
                        border: "1px solid #000",
                      }}
                      type="text"
                      autoFocus={editedValue?.focus === 'productCode'}
                      value={editedValue?.productCode}
                      onChange={(e) => {
                        handleInputChange(e, 'productCode', 'string');
                      }}
                      required
                    />
                  </>
                ) : (
                  <p
                    style={{ maxWidth: "80px" }}
                    className="m-0"
                  >
                    {cellProps?.row?.original?.productCode || "No win"}
                  </p>
                )}
              </div>
              {/* <div>
                {!editID || editID !== cellProps?.row?.original?._id ? (
                  <Badge
                    className="ms-0 badge-design"
                    color={getSubcategoryBadgeColor(cellProps?.row?.original?.status)}
                  >
                    {cellProps?.row?.original?.status === "ACTIVE"
                      ? "Activated"
                      : "Suspended"}
                  </Badge>
                ) : null}

              </div> */}
            </div>
          );
        },
      },
      {
        Header: `Total Prizes`,
        Cell: (cellProps) => {
          return (
            <div>
              <div>
                {editID == cellProps?.row?.original?._id && cellProps?.row?.original?.productCode !== "No win" ? (
                  <>
                    <input
                      style={{
                        maxWidth: "80px",
                        padding: "8px",
                        textAlign: "center",
                        borderRadius: "5px",
                        border: "1px solid #000",

                      }}
                      type="number"
                      min="0"
                      max="999999999999999"
                      autoFocus={editedValue?.focus === 'totalPrizes'}
                      value={editedValue?.totalPrizes}
                      onChange={(e) => {
                        handleInputChange(e, 'totalPrizes', "number");
                      }}
                    />
                  </>
                ) : (
                  <p
                    style={{ textAlign: "center", maxWidth: "80px" }}
                    className="m-0"
                  >
                    {cellProps?.row?.original?.prize === 0 ? " ∞" : cellProps?.row?.original?.totalPrizes}
                  </p>
                )}
              </div>
            </div>
          );
        },
      },
      {
        Header: `Balance`,
        Cell: (cellProps) => {
          const { prize, totalPrizes, prizesIssued } = cellProps.row.original;
          const prizesLeft = totalPrizes - prizesIssued;

          return (
            <div>
              <p className="m-0 td">
                {prize === 0 ? " ∞" : `${prizesLeft}/${totalPrizes}`}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Probability",
        id: "Probability",
        Header: ({ cellProps }) => {
          const color = allPrize?.total == 100 ? "#32AA89" : "#f46a6a";
          return (
            <div className="checkbox-head">
              Probability ({" "}
              <span style={{ color: color, fontWeight: 600 }}>
                {allPrize?.total || 0}
              </span>
              %)
            </div>
          );
        },
        isInputEdit: true,
        Cell: (cellProps) => {
          const color = totalProbability === 100 ? "#32AA89" : "#f46a6a";
          return (
            <div className="probability-cell">
              {editID == cellProps?.row?.original?._id ? (
                <>
                  <input
                    style={{
                      maxWidth: "80px",
                      padding: "8px",
                      textAlign: "center",
                      borderRadius: "5px",
                      border: "1px solid #000",
                    }}
                    type="number"
                    min="0"
                    max="100"
                    autoFocus={editedValue?.focus === 'probability'}
                    value={editedValue?.probability}
                    onChange={(e) => {
                      handleInputChange(e, 'probability', "number");
                    }}
                  />
                  <p className="mb-0">Remaining <span style={{ color: color }}>{100 - totalProbability}</span></p>
                </>
              ) : (
                <p
                  style={{ textAlign: "center", maxWidth: "80px" }}
                  className="m-0"
                >
                  {cellProps?.row?.original?.probability}
                </p>
              )}
            </div>
          );
        },
      },
      {
        Header: `Action `,
        Cell: (cellProps) => {
          const disabled = editID == cellProps?.row?.original?._id ? true : false;
          return (
            <div className="manage-prize-action">
              {/* <Dropdown isOpen={dropdownOpen[cellProps.row.index]} disabled={editID === cellProps.row.original._id}
                toggle={() => toggle(cellProps.row.index)} direction={'top'} end={true}>
                <DropdownToggle caret className='more-option'>
                  <i className='bx bx-dots-vertical-rounded'></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => {
                      setEditID(cellProps?.row?.original?._id);
                      setEditedValue({
                        probability: cellProps?.row?.original?.probability,
                        totalPrizes: cellProps?.row?.original?.totalPrizes,
                        productCode: cellProps?.row?.original?.productCode || 'No win'
                      });
                    }} >Edit</DropdownItem>
                  <DropdownItem disabled={cellProps?.row?.original?.status === "ACTIVE"}
                    onClick={() => {
                      activeToggleModal();
                      setstatusData(cellProps?.row?.original);

                    }}
                  >
                    Activate
                  </DropdownItem>
                  <DropdownItem disabled={cellProps?.row?.original?.status === "INACTIVE"}
                    onClick={() => {
                      activeToggleModal();
                      setstatusData(cellProps?.row?.original);
                    }}
                  >
                    De-activate
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown> */}

              {
                editID == cellProps?.row?.original?._id ? (
                  <div className="action">
                    <span
                      onClick={() => {
                        const prizeIssued = cellProps?.row?.original?.prizesIssued
                        handleApprove(editedValue, cellProps?.row?.original?._id, prizeIssued);
                      }}
                    >
                      <img src={save} width={14} height={10}></img>
                    </span>
                    <span
                      onClick={() => {
                        setEditID();
                      }}
                    >
                      <img src={cancel} width={14} height={10}></img>
                    </span>
                  </div>
                ) : <div className="edit cursor-pointer">
                  <img src={edit_icon} alt="edit" onClick={() => {
                    const newData = allPrize?.total - cellProps?.row?.original?.probability;
                    setCurrentProbability(cellProps?.row?.original?.probability);
                    setTotalProbability(allPrize?.total);
                    setEditID(cellProps?.row?.original?._id);
                    setEditedValue({
                      probability: cellProps?.row?.original?.probability,
                      totalPrizes: cellProps?.row?.original?.totalPrizes,
                      productCode: cellProps?.row?.original?.productCode || 'No win'
                    });
                  }} />
                </div>
              }
            </div >
          );
        },
      },
      {
        Header: ` Priority   `,
        Cell: (cellProps) => {
          const { _id, prizeSelected, productName, totalPrizes, prizesIssued, status } = cellProps.row.original;
          const prizesLeft = totalPrizes - prizesIssued;
          return (
            <>
              {productName != "No win" && prizesLeft > 0 && status !== "INACTIVE" &&
                <div className="form-group position-relative">
                  <input className="d-none" type="checkbox" name="" id={_id} checked={prizeSelected} onChange={() => { }} />
                  <label className="mb-0" htmlFor={_id} onClick={() => handleCheckBoxClick(_id, prizeSelected)}></label>
                </div>
              }
            </>
          );
        },
      },
      {
        Header: `PrizeImage`,
        Cell: (cellProps) => {
          const editImageID = cellProps.row.original?._id;
          const path = readAdsImage[`${cellProps?.row?.original?.prizeImg}`];

          return (
            <div className="m-0 td">
              <div className="avatar-container">
                <div className="avatar" >
                  <img
                    alt="prize"
                    className="img-thumbnail"
                    src={path ? `data:${getMimeType(path)};base64,${path}` : avatarsquare}
                  />
                  <div className="sliding-container">
                    <label className="m-0 cursor-pointer" htmlFor={`prizeInput_${editImageID}`}>
                      <img src={edit} alt="Choose Image" width={16} />
                    </label>
                    <input
                      name="imgUpload" type="file" accept="image/jpg,image/jpeg,image/png" hidden id={`prizeInput_${editImageID}`}
                      onChange={(event) => handleFileChange(event, editImageID, "PRIZE")}
                    />
                    <div className="cursor-pointer" onClick={() => handleViewClick(path ? `data:${getMimeType(path)};base64,${path}` : "")}>
                      <img src={View} alt="View Image" width={16} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      },
      // {
      //   Header: `Spinner Image`,
      //   Cell: (cellProps) => {
      //     const editImageID = cellProps.row.original?._id;
      //     const imageUrl = cellProps.row.original?.spinnerImg;
      //     return (
      //       <div className="m-0 td">
      //         <div className="avatar-container" >
      //           <div className="avatar" >
      //             <img
      //               alt="spinner"
      //               className="img-thumbnail"
      //               src={imageUrl || avatarsquare}
      //             />
      //             <div className="sliding-container">
      //               <label className="m-0 cursor-pointer" htmlFor={`spinnerInput_${editImageID}`}>
      //                 <img src={edit} alt="Choose Image" width={16} />
      //               </label>
      //               <input
      //                 name="imgUpload" type="file" accept="image/jpg,image/jpeg,image/png" hidden id={`spinnerInput_${editImageID}`}
      //                 onChange={(event) => handleFileChange(event, editImageID, "SPINNER")}
      //               />
      //               <div className="cursor-pointer" onClick={() => handleViewClick(imageUrl)}>
      //                 <img src={View} alt="View Image" width={16} />
      //               </div>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     );
      //   },
      // },
    ],
    [allPrize?.prizes, editID, editedValue, dropdownOpen, readAdsImage]
  );

  return (
    <React.Fragment>
      <div className="manage-prize">
        <Container fluid className="p-0">
          {!allPrizeLoading && !readAdsImageloader && !updatePrizeLoading ? (
            <div className="p-4">
              <Card>
                <TableContainer
                  columns={Header}
                  data={allPrize?.prizes?.length > 0 && compaginId ? allPrize?.prizes : []}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageSize={10}
                  handleOrderClicks={() => null}
                  iSPageOptions={false}
                  className="custom-header-css"
                />
                <p className="ms-4"><strong>** Make sure that sum of each prize's probability should be 100</strong></p>
              </Card>
              <div className="d-flex justify-content-center pb-2">
                {compaginId &&
                  <PrimaryButton btnName={`${currentCampaignData?.status === "INACTIVE" ? 'Publish' : 'Unpublish'}`} onClick={prizeToogleModal} />
                }
              </div>
            </div>
          ) : (
            <TableSkeleton datacount={7} listcount={15} />
          )}
          <PhotoView
            src={selectedImage}
            showModal={viewImageModal}
            setShowModal={setViewImageModal}
          />
          <Confirmation
            showModal={changeStatusModal}
            toggle={activeToggleModal}
            primaryAction={changeActiveStatus}
            modalTitle={`${statusdata?.status === "INACTIVE" ? 'Activate' : 'Suspend'} Prize`}
            primaryBtn={`${statusdata?.status === "INACTIVE" ? 'Activate' : 'Suspend'}`}
            secountBtn="Cancel"
            description={`Are you sure, do you want to  ${statusdata?.status === "ACTIVE" ? 'Suspend' : 'activate'} this Prize "${statusdata?.productCode}"?`}
          />
          <SpinnerModal
            SpinWheelImageData={SpinWheelImageData}
            showModal={spinnerPreviewModal}
            setShowModal={setspinnerPreviewModal}
          />

          <Confirmation
            showModal={prizePublishModal}
            toggle={prizeToogleModal}
            primaryAction={publishCampaign}
            modalTitle={`${currentCampaignData?.status === "INACTIVE" ? 'Publish' : 'UnPublish'} Prize`}
            primaryBtn={`${currentCampaignData?.status === "INACTIVE" ? 'Publish' : 'UnPublish'}`}
            secountBtn="Cancel"
            description={`Are you sure, do you want to  ${currentCampaignData?.status === "INACTIVE" ? 'publish' : 'unublish'} this Prize"?`}
          />
        </Container >
      </div >
    </React.Fragment >
  );
}
