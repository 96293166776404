import {
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    PASSWORD_CHANGE,
    PASSWORD_CHANGE_SUCCESS,
    PASSWORD_CHANGE_FAIL,
    UPDATE_PROFILE_IMAGE,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IMAGE_FAIL,
    UPDATE_PROFILE_IMAGE_LOADING
} from "./actionTypes";

//update profile
const initialStateUpdateProfile = {
    error: "",
    success: "",
    loading:false,
}

export const UpdateProfile = (state = initialStateUpdateProfile, action) => {
    switch (action.type) {
        case UPDATE_PROFILE:
            state = {
                ...state,
                loading:true,
            }
            break
        case UPDATE_PROFILE_SUCCESS:
            state = {
                ...state,
                success: action.payload,
                loading:false,
            }
            break
        case UPDATE_PROFILE_FAIL:
            state = {
                ...state,
                error: action.payload,
                loading:false,
            }
            break
    }
    return state
}

//password change
const initialStatePasswordChange = {
    error: "",
    success: "",
    loading: false,
}

export const LoginPasswordChange = (state = initialStatePasswordChange, action) => {
    switch (action.type) {
        case PASSWORD_CHANGE:
            state = {
                ...state,
                loading: true
            }
            break
        case PASSWORD_CHANGE_SUCCESS:
            state = {
                ...state,
                success: action.payload,
                loading: false,
            }
            break
        case PASSWORD_CHANGE_FAIL:
            state = {
                ...state,
                error: action.payload,
                loading: false,
            }
            break
    }
    return state
}

//update profile image
const initialStateProfileImg = {
    msg: "",
    status: "",
    loading: false,
}

export const UpdateProfileImg = (state = initialStateProfileImg, action) => {
    switch (action.type) {
        case UPDATE_PROFILE_IMAGE:
            state = {
                ...state,
                loading:true,
            }
            break
        case UPDATE_PROFILE_IMAGE_SUCCESS:
            state = {
                ...state,
                msg: action.payload,
                status: '200',
                loading:false,
            }
            break
        case UPDATE_PROFILE_IMAGE_FAIL:
            state = {
                ...state,
                msg: action.payload,
                status: '404',
                loading:false,
            }
            break
        case UPDATE_PROFILE_IMAGE_LOADING:
            state = {
                ...state,
                loading: action.payload,
            }
            break
    }
    return state
}