import {
    UPDATE_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    PASSWORD_CHANGE,
    PASSWORD_CHANGE_SUCCESS,
    PASSWORD_CHANGE_FAIL,
    UPDATE_PROFILE_IMAGE,
    UPDATE_PROFILE_IMAGE_FAIL,
    UPDATE_PROFILE_IMAGE_SUCCESS,
    UPDATE_PROFILE_IMAGE_LOADING,
    GET_IMAGE_PATH
} from "./actionTypes";

//update prifile
export const updateProfile = (user, history, id) => {
    return {
        type: UPDATE_PROFILE,
        payload: { user, history, id },
    }
}
export const updateProfileSuccess = user => {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        payload: user,
    }
}
export const updateProfileError = error => {
    return {
        type: UPDATE_PROFILE_FAIL,
        payload: error,
    }
}

//password change
export const loginPasswordChange = (data, history) => {
    return {
        type: PASSWORD_CHANGE,
        payload: { data, history },
    }
}
export const loginPasswordChangeSuccess = data => {
    return {
        type: PASSWORD_CHANGE_SUCCESS,
        payload: data,
    }
}
export const loginPasswordChangeError = error => {
    return {
        type: PASSWORD_CHANGE_FAIL,
        payload: error,
    }
}

//update profile image
export const getImagePath = data => {
    return {
        type: GET_IMAGE_PATH,
        payload: data,
    }
}
export const updateProfileImage = (data, dispatch) => {
    return {
        type: UPDATE_PROFILE_IMAGE,
        payload: { data, dispatch },
    }
}
export const updateProfileImageSuccess = img => {
    return {
        type: UPDATE_PROFILE_IMAGE_SUCCESS,
        payload: img,
    }
}
export const updateProfileImageError = error => {
    return {
        type: UPDATE_PROFILE_IMAGE_FAIL,
        payload: error,
    }
}
export const updateProfileImageLoading = loading => {
    return {
        type: UPDATE_PROFILE_IMAGE_LOADING,
        payload: loading
    }
}