// @flow
import { manageQr } from "helpers/backend_helper";
import { getAllQr, getAllQrFail, getAllQrSuccess } from "./action";
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_MANAGE_QR } from "./actionType";
import toast from "react-hot-toast";

//Get All QR DETAILS
function* fetchQrDetails({ payload: { data, currentPage, pageSize } }) {
  try {
    const response = yield call(manageQr, data, currentPage, pageSize);
    yield put(getAllQrSuccess(response));
  } catch (error) {
    yield put(getAllQrFail(error));
  }
}
export function* QrSaga() {
  yield takeEvery(GET_MANAGE_QR, fetchQrDetails);
}
