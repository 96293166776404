import React, { useRef, useEffect, useState } from 'react';
import { fabric } from 'fabric';
import DocumentTitle from 'components/DocumentTitle'
import { Card } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { addAdvertisement } from 'store/manageads/actions';
import Confirmation from 'components/Common/Modals/Confirmation';
import TopToolBar from './components/TopToolBar';
import MenuBar from './components/MenuBar';
import base64ToFile from 'constants/base64ToFile';
import toast from "react-hot-toast";

function DesignTool() {
    const dispatch = useDispatch();

    const canvasRef = useRef(null);
    const canvasInstance = useRef(null);
    const [openMenu, setOpenMenu] = useState(false)
    const [textColor, setTextColor] = useState('#000000');
    const [fontFamily, setFontFamily] = useState('Arial');
    const [selectedObj, setSelectedObj] = useState('');
    const [fontSize, setFontSize] = useState("74");
    const [backgroundColor, setBackgroundColor] = useState('#ffff');
    const [selectedTool, setSelectedTool] = useState("");
    const [zoom, setZoom] = useState(0.6);
    const [selectedFonstFamily, setSelectedOptionFonstFamily] = useState(null);
    const [selectedFonstsize, setSelectedOptionFonstsize] = useState(null);
    const [actionStack, setActionStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [bold, setBold] = useState(false)
    const [italic, setItalic] = useState(false)
    const [underline, setUndserline] = useState(false)
    const [itemCount, setItemCount] = useState(0)
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [changeStatusModal, setChangeStatusModal] = useState(false);

    const MAX_WIDTH = 1080;
    const MAX_HEIGHT = 1920;
    const MAX_ZOOM_OUT = 0.1;
    const objectsLength = canvasInstance?.current?.getObjects()?.length ?? 0;

    useEffect(() => {
        if (canvasRef.current) {
            canvasInstance.current = new fabric.Canvas(canvasRef.current, {
                width: MAX_WIDTH,
                height: MAX_HEIGHT,
            });
            canvasRef.current.style.backgroundColor = backgroundColor;
        }
        return () => {
            if (canvasInstance?.current) {
                canvasInstance?.current.dispose();
            }
        };
    }, []);

    useEffect(() => {
        const handleSelectionChanged = () => {
            const activeObject = canvasInstance.current?.getActiveObject();
            setSelectedObj(activeObject?.type);
            if (activeObject && activeObject.type === 'textbox') {
                const { fontFamily, fontSize, fill, fontWeight, fontStyle, underline } = activeObject;
                setSelectedOptionFonstFamily({ label: fontFamily, value: fontFamily })
                setSelectedOptionFonstsize({ label: fontSize, value: fontSize })
                setFontFamily(fontFamily);
                setFontSize(fontSize);
                setTextColor(fill);
                setUndserline(underline)
                if (fontWeight === "normal") setBold(false);
                else setBold(true)
                if (fontStyle === "normal") setItalic(false);
                else setItalic(true)

            }
        };

        const handleKeyDown = (event) => {
            if (event.code === 'Delete') {
                removeObject()
            }
        };

        canvasInstance.current?.on('selection:created', handleSelectionChanged);
        canvasInstance.current?.on('selection:updated', handleSelectionChanged);
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            canvasInstance.current?.off('selection:created', handleSelectionChanged);
            canvasInstance.current?.off('selection:updated', handleSelectionChanged);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [canvasInstance])


    const undo = () => {
        if (actionStack.length === 0) return;
        const lastAction = actionStack[actionStack.length - 1];
        setRedoStack((prevStack) => [...prevStack, lastAction]);
        actionStack.pop()


        switch (lastAction.action) {
            case 'add':
                canvasInstance.current.remove(lastAction.object);
                break;
            case 'remove':
                canvasInstance.current.add(lastAction.object);
                break;
            case 'background':
                setBackgroundColor('#ffffff');
                canvasInstance.current.setBackgroundColor('#ffffff', canvasInstance.current.renderAll.bind(canvasInstance.current));
                break;
            case 'backgroundImage':
                setBackgroundImage(null);
                canvasInstance.current.setBackgroundImage(null, canvasInstance.current.renderAll.bind(canvasInstance.current));
                break;
            case 'textProperties':
                let id = lastAction?.objId
                const textProperties = lastAction.object;
                const objects = canvasInstance.current.getObjects();
                if (objects) {
                    objects?.forEach((data => {
                        if (data?.id == id) {
                            data.set(textProperties)
                        }
                    }))

                }
                canvasInstance.current.renderAll();
                break;
            default:
                break;
        }
    };

    const redo = () => {
        if (redoStack.length === 0) return;

        const nextAction = redoStack.pop();
        setActionStack((prevStack) => [...prevStack, nextAction]);

        switch (nextAction.action) {
            case 'add':
                canvasInstance.current.add(nextAction.object);
                break;
            case 'remove':
                canvasInstance.current.remove(nextAction.object);
                break;
            case 'background':
                setBackgroundColor(nextAction.value);
                canvasInstance.current.setBackgroundColor(nextAction.value, canvasInstance.current.renderAll.bind(canvasInstance.current));
                break;
            case 'backgroundImage':
                setBackgroundImage(nextAction.value);
                handleAddBackground(nextAction.value);
                break;
            case 'textProperties':
                let id = nextAction?.objId
                const textProperties = nextAction.object;
                const objects = canvasInstance.current.getObjects();
                if (objects) {
                    objects?.forEach((data => {
                        if (data?.id == id) {
                            data.set(textProperties)
                        }
                    }))
                }
                canvasInstance.current.renderAll();
                break;
            default:
                break;
        }
    };

    const clearAll = () => {
        setOpenMenu(false)
        setBackgroundColor("#fff")
        canvasInstance.current?.clear();
        canvasInstance.current?.renderAll();

    };

    const handleToolClick = (tool) => {
        setSelectedTool(tool);
    };

    const addText = () => {
        const text = new fabric.Textbox('Type here', {
            left: 100,
            top: 100,
            fill: textColor,
            fontFamily: fontFamily,
            backgroundColor: "transparent",
            fontSize: fontSize,
            fontWeight: "normal",
            fontStyle: "normal",
            underline: false,
            id: itemCount
        });
        canvasInstance.current.add(text);
        canvasInstance.current.setActiveObject(text);
        setItemCount(prev => prev + 1);
        pushToActionStack({ action: 'add', object: text });

    };

    const openMenuBar = () => {
        setOpenMenu(true)
    }

    const updateTextProperties = (properties) => {
        const activeObject = canvasInstance.current.getActiveObject();
        pushToActionStack({ action: 'textProperties', object: properties, objId: activeObject?.id });
        if (activeObject && activeObject.type === 'textbox') {
            activeObject.set('backgroundColor', properties.backgroundColor);
            if (properties.bold === true) {
                activeObject.set('fontWeight', 'bold');
                setBold(true)
            }
            else if (properties.bold === false) {
                activeObject.set('fontWeight', 'normal');
                setBold(false)
            }
            if (properties.italic === true) {
                activeObject.set('fontStyle', 'italic');
                setItalic(true)
            } else if (properties.italic === false) {
                activeObject.set('fontStyle', 'normal');
                setItalic(false)

            }
            if (properties.underline === true) {
                activeObject.set('underline', 'underline');
                setUndserline(true)
            } else if (properties.underline === false) {
                activeObject.set('underline', false);
                setUndserline(false)
            }
            activeObject.set(properties);

            canvasInstance.current.renderAll();
        }
    };

    const handleFontFamilyChange = (font) => {
        setSelectedOptionFonstFamily(font)
        setFontFamily(font.value);
        updateTextProperties({ fontFamily: font.value });

    };

    const handleFontSizeChange = (font) => {
        setSelectedOptionFonstsize(font)
        setFontSize(font.value);
        updateTextProperties({ fontSize: font.value });

    };

    const handleColorChange = (event) => {
        const color = event.target.value;
        setTextColor(color);
        setTextColor(color);
        updateTextProperties({ fill: color });
    };

    const addBackground = (colorCode) => {
        if (canvasRef.current) {
            canvasInstance.current.backgroundColor = colorCode;
            pushToActionStack({ action: 'background', value: colorCode });
            canvasInstance.current.renderAll();
        }
    }

    const removeObject = () => {
        const activeObject = canvasInstance.current?.getActiveObject();
        if (activeObject) {
            canvasInstance.current?.remove(activeObject);
            canvasInstance.current?.renderAll();
        }
    }

    const saveAsImage = async () => {
        const canvas = canvasInstance.current;
        const ctx = canvas.getContext('2d');
        const dataURL = canvas.toDataURL('image/jpeg', 1); // Start with max quality (1)
        const img = new Image();

        img.onload = async () => {
            let width = img.width;
            let height = img.height;

            if (width > MAX_WIDTH || height > MAX_HEIGHT) {
                const ratio = Math.min(MAX_WIDTH / width, MAX_HEIGHT / height);
                width *= ratio;
                height *= ratio;
            }

            canvas.width = width;
            canvas.height = height;

            ctx.drawImage(img, 0, 0, width, height);

            let quality = 0.9; // Start with a high quality
            let compressedDataURL = canvas.toDataURL('image/jpeg', quality);
            let attempts = 0;

            while (compressedDataURL.length > 1.9 * 1024 * 1024 && attempts < 10) {
                // Reduce quality
                quality -= 0.1;
                // Clear the canvas
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                // Redraw the image with the new quality
                ctx.drawImage(img, 0, 0, width, height);
                compressedDataURL = canvas.toDataURL('image/jpeg', quality);
                attempts++;
            }

            const file = base64ToFile(compressedDataURL, "custom_ads", "image/png")
            dispatch(addAdvertisement({ ads: file }));
        };

        img.src = dataURL;
    };


    const handleChangeBgColor = (e) => {
        const color = e.target.value;
        addBackground(color)
        setBackgroundColor(color)
        pushToActionStack({ action: 'background', object: color });

    }

    const toggleBold = () => {
        const activeObject = canvasInstance.current.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
            const bold = !activeObject.get('fontWeight') || activeObject.get('fontWeight') !== 'bold';
            updateTextProperties({ bold });
        }
    };

    const toggleunderline = () => {
        const activeObject = canvasInstance.current.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
            const underline = !activeObject.get('underline');
            updateTextProperties({ underline });
        }
    };

    const toggleitalic = () => {
        const activeObject = canvasInstance.current.getActiveObject();
        if (activeObject && activeObject.type === 'textbox') {
            const italic = !activeObject.get('fontStyle') || activeObject.get('fontStyle') !== 'italic';
            updateTextProperties({ italic });
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        event.target.value = null;

        // Check file type
        if (file) {
            const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
            const maxDimension = { width: 1080, height: 1920 };

            if (!allowedFormats.includes(file.type)) {
                toast.error("Only PNG and JPEG file formats are allowed.");
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.src = e.target.result;
                img.onload = () => {
                    if (img.width !== maxDimension.width || img.height !== maxDimension.height) {
                        toast.error(`Image dimensions should be like ${maxDimension.width} x ${maxDimension.height}px.`);
                    } else {
                        const fabricImage = new fabric.Image(img, {
                            scaleX: canvasRef.current.width / img.width,
                            scaleY: canvasRef.current.height / img.height,
                            originX: 'left',
                            originY: 'top',
                            crossOrigin: 'anonymous',
                            backgroundImageStretch: false,
                            backgroundImage: true,
                            backgroundImageWidth: canvasRef.current.width,
                            backgroundImageHeight: canvasRef.current.height,
                        });
                        canvasInstance.current.setBackgroundImage(fabricImage, canvasInstance.current.renderAll.bind(canvasInstance.current));
                        pushToActionStack({ action: 'backgroundImage', object: img });
                    }
                };
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAddBackground = (image) => {
        const img = new Image();
        img.src = image; // Assuming sampleImage is the image source
        img.onload = () => {
            const fabricImage = new fabric.Image(img, {
                scaleX: canvasRef.current.width / img.width,
                scaleY: canvasRef.current.height / img.height,
                originX: 'left',
                originY: 'top',
                crossOrigin: 'anonymous',
                backgroundImageStretch: false,
                backgroundImage: true,
                backgroundImageWidth: canvasRef.current.width,
                backgroundImageHeight: canvasRef.current.height,
            });
            canvasInstance.current.setBackgroundImage(fabricImage, canvasInstance.current.renderAll.bind(canvasInstance.current));
            pushToActionStack({ action: 'backgroundImage', object: fabricImage });
        };


    }

    const handleAddImage = (image) => {
        fabric.Image.fromURL(image, (img) => {
            img.set({
                left: 100, // Set default left position
                top: 100, // Set default top position
                scaleX: 0.5, // Set default scale
                scaleY: 0.5,
                id: itemCount
            });
            canvasInstance?.current.add(img);
            pushToActionStack({ action: 'add', object: img });
            canvasInstance.current.setActiveObject(img);
            setItemCount(prev => prev + 1);
            setOpenMenu(false)

        });
    }

    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;
        const reader = new FileReader();
        reader.onload = (e) => {
            fabric.Image.fromURL(e.target.result, (img) => {
                img.set({
                    left: 100, // Set default left position
                    top: 100, // Set default top position
                    scaleX: 0.5, // Set default scale
                    scaleY: 0.5,
                    id: itemCount
                });
                canvasInstance.current.add(img);
                pushToActionStack({ action: 'add', object: img });
                canvasInstance.current.setActiveObject(img);
                setItemCount(prev => prev + 1);
                setOpenMenu(false)
            });
        };
        reader.readAsDataURL(file);
    }

    const pushToActionStack = (action) => {
        setActionStack((prevStack) => [...prevStack, action]);
        setRedoStack([]);
    };

    const calculateMaxZoom = () => {
        const canvasWidth = canvasInstance.current.getWidth();
        const canvasHeight = canvasInstance.current.getHeight();

        const maxZoomWidth = MAX_WIDTH / canvasWidth;
        const maxZoomHeight = MAX_HEIGHT / canvasHeight;

        return Math.min(maxZoomWidth, maxZoomHeight);
    };

    const handleZoomIn = () => {
        const maxZoom = calculateMaxZoom();
        setZoom((prevZoom) => Math.min(maxZoom, prevZoom + 0.1));
    };

    const handleZoomOut = () => {
        setZoom((prevZoom) => Math.max(MAX_ZOOM_OUT, prevZoom - 0.1));
    };

    const activeToggleModal = () => {
        setChangeStatusModal(!changeStatusModal);
    }

    const resetAll = () => {
        clearAll();
        activeToggleModal()
        setActionStack([])
        setRedoStack([])
    }

    const saveAll = () => {
        saveAsImage()
        activeToggleModal()
    }

    return (
        <>
            <DocumentTitle title={`Design Tool`} />
            <div className="page-content design-tool-container">
                <Card>
                    <TopToolBar
                        canvasInstance={canvasInstance}
                        selectedObj={selectedObj}
                        selectedTool={selectedTool}
                        actionStack={actionStack}
                        activeToggleModal={activeToggleModal}
                        handleColorChange={handleColorChange}
                        handleFontFamilyChange={handleFontFamilyChange}
                        handleFontSizeChange={handleFontSizeChange}
                        handleToolClick={handleToolClick}
                        handleZoomIn={handleZoomIn}
                        handleZoomOut={handleZoomOut}
                        objectsLength={objectsLength}
                        redo={redo}
                        redoStack={redoStack}
                        removeObject={removeObject}
                        selectedFonstFamily={selectedFonstFamily}
                        selectedFonstsize={selectedFonstsize}
                        textColor={textColor}
                        toggleBold={toggleBold}
                        toggleitalic={toggleitalic}
                        toggleunderline={toggleunderline}
                        undo={undo}
                        zoom={zoom}
                        underline={underline}
                        italic={italic}
                        bold={bold}

                    />

                    <div className="design-tool">

                        <MenuBar
                            addText={addText}
                            backgroundColor={backgroundColor}
                            handleAddBackground={handleAddBackground}
                            handleAddImage={handleAddImage}
                            handleChangeBgColor={handleChangeBgColor}
                            handleImageUpload={handleImageUpload}
                            handleToolClick={handleToolClick}
                            handleUpload={handleUpload}
                            openMenuBar={openMenuBar}
                            selectedTool={selectedTool}
                            setOpenMenu={setOpenMenu}
                            openMenu={openMenu}
                        />
                        <div className='canvas-sec'>
                            <div className='canvas-container'>
                                <div className='d-flex justify-content-center' style={{ transform: `scale(${zoom})`, transformOrigin: '50% 0%' }}>
                                    <canvas id='canvasRef' ref={canvasRef} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

            <Confirmation
                showModal={changeStatusModal}
                toggle={activeToggleModal}
                primaryAction={selectedTool === "clear" ? resetAll : saveAll}
                modalTitle={``}
                primaryBtn={` ${selectedTool === "clear" ? "Reset" : "save"} `}
                secountBtn="Cancel"
                description={(
                    <>
                        Are you sure you want to {selectedTool === "clear" ? "reset?." : "save?."}
                        <br />
                        {selectedTool === "clear" ? "This action will remove all the customization you added and you cannot revert them back." : "This action will generate & save the Ads and take you to Ads listing page."}
                    </>
                )}
            />
        </>
    )
}

export default DesignTool