import React, { memo } from 'react';

const SecountryBtn = ({btnType, btnName, btnIcon, showIcon, onClick, disabled, className}) => {
    return (
        <>
            <button className={`btn-rounded btn-common secountry-btn ${className}`} type={btnType} onClick={onClick} disabled={disabled}>
                {showIcon ? <img src={btnIcon} alt='btnicon' /> : ''}
              <span>{btnName}</span>
            </button>
        </>
    );
};

export default memo(SecountryBtn);