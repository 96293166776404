import React from "react";
import { Redirect } from "react-router-dom";
// Profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import UnAuthorized from "components/UnAuthorized";
import InternalServer from "components/InternalServer";
import BadRequest from "components/BadRequest";
import InnerScreenNotFound from "components/InnerScreenNotFound";
import PrintQrForm from "../pages/PrintQr/PrintQrForm";
import ManageQr from "pages/ManageQr/ManageQr";
import ManagePrize from "pages/ManagePrize/ManagePrize";
import Winners from "pages/winner/Winners";
import AddPrize from "pages/ManagePrize/AddPrize";
import Settings from "pages/settings/Settings";
import PrintOr from "pages/PrintQr/PrintOr";
import ManageAds from "pages/Advertisement/ManageAds";
import DesignTool from "pages/Design Tool/DesignTool";
import ManageCampaign from "pages/ManagePrize/ManageCampaign";

const authProtectedRoutes = [
  { path: "/winners", component: Winners, role: ["staff", "management"] },
  { path: "/profile", component: UserProfile, role: ["staff", "management"] },
  {
    path: "/notfound",
    component: InnerScreenNotFound,
    role: ["staff", "management"],
  },
  { path: "/print-qr", component: PrintOr, role: ["staff", "management"] },
  { path: "/manageqr", component: ManageQr, role: ["management"] },
  { path: "/manage-prize", component: ManagePrize, role: ["management"] },
  { path: "/manage-prize/add-prize", component: AddPrize, role: ["management"], },
  { path: "/manage-ads", component: ManageAds, role: ["management"] },
  { path: "/settings", component: Settings, role: ["management"] },
  { path: "/manage-ads/designTool", component: DesignTool, role: ["management"] },
  { path: "/manage-campaign", component: ManageCampaign, role: ["management"] },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/winners" /> },
  { path: "*", component: () => <Redirect to="/notfound" /> },
];
const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/reset-password/:email", component: ResetPassword },
  { path: "/unauthorized", component: UnAuthorized },
  { path: "/servererror", component: InternalServer },
  { path: "/badrequest", component: BadRequest },
  //auth router
];

export { authProtectedRoutes, publicRoutes }; 
