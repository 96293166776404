import {
  GET_MANAGE_QR,
  GET_MANAGE_QR_FAIL,
  GET_MANAGE_QR_SUCCESS,
} from "./actionType";

export const getAllQr = ({ data, currentPage, pageSize }) => ({
  type: GET_MANAGE_QR,
  payload: { data, currentPage, pageSize }
});
export const getAllQrSuccess = (QrData) => ({
  type: GET_MANAGE_QR_SUCCESS,
  payload: QrData,
});
export const getAllQrFail = (error) => ({
  type: GET_MANAGE_QR_FAIL,
  payload: error,
});
