import {
  CAMPAIGN_SETTINGS_UPDATE,
  CAMPAIGN_SETTINGS_UPDATE_FAIL,
  CAMPAIGN_SETTINGS_UPDATE_SUCCESS,
  GET_SETTINGS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  SETTINGS_UPDATE,
  SETTINGS_UPDATE_FAIL,
  SETTINGS_UPDATE_LOADING,
  SETTINGS_UPDATE_SUCCESS,
  GET_CAMPAIGN_DETAILS,
  GET_CAMPAIGN_DETAILS_FAIL,
  GET_CAMPAIGN_DETAILS_SUCCESS,
  UPDATE_CAMPAIGN_DETAILS,
  UPDATE_CAMPAIGN_DETAILS_SUCCESS,
  UPDATE_CAMPAIGN_DETAILS_FAIL,
  CAMPAIGN_OPTIONS,
  GET_BY_CAMPAIGN_ID,
  GET_BY_CAMPAIGN_ID_SUCCESS,
  GET_BY_CAMPAIGN_ID_FAIL,
} from "./actionType"

//Update settings
export const settingsUpdate = (data, setDisabled, id) => {
  return {
    type: SETTINGS_UPDATE,
    payload: { data, setDisabled, id }
  }
}
export const settingsUpdateSuccess = data => {
  return {
    type: SETTINGS_UPDATE_SUCCESS,
    payload: data,
  }
}
export const settingsUpdateError = error => {
  return {
    type: SETTINGS_UPDATE_FAIL,
    payload: error
  }
}
export const settingsUpdateLoading = (loading) => {
  return {
    type: SETTINGS_UPDATE_LOADING,
    payload: loading
  }
}

//Get settings
export const getSettings = () => {
  return {
    type: GET_SETTINGS
  }
}
export const getSettingsSuccess = (data) => {
  return {
    type: GET_SETTINGS_SUCCESS,
    payload: data,
  }
}
export const getSettingsError = error => {
  return {
    type: GET_SETTINGS_FAIL,
    payload: error
  }
}
export const getSettingsLoading = (loading) => {
  return {
    type: GET_SETTINGS_LOADING,
    payload: loading
  }
}

//campaign settings 
export const campaignSettingsUpdate = (data, id, history) => {
  return {
    type: CAMPAIGN_SETTINGS_UPDATE,
    payload: { data, id, history }
  }
}
export const campaignSettingsUpdateSuccess = (data) => {
  return {
    type: CAMPAIGN_SETTINGS_UPDATE_SUCCESS,
    payload: data,
  }
}
export const campaignSettingsUpdateError = error => {
  return {
    type: CAMPAIGN_SETTINGS_UPDATE_FAIL,
    payload: error
  }
}


//get campaign details
export const getCampaignDetails = () => {
  return {
    type: GET_CAMPAIGN_DETAILS
  }
}
export const getCampaignDetailsSuccess = data => {
  return {
    type: GET_CAMPAIGN_DETAILS_SUCCESS,
    payload: data,
  }
}
export const getCampaignDetailsError = error => {
  return {
    type: GET_CAMPAIGN_DETAILS_FAIL,
    payload: error
  }
}

//update campaign details
export const updateCampaignDetails = (data, id, afterSuccess) => {
  return {
    type: UPDATE_CAMPAIGN_DETAILS,
    payload: { data, id, afterSuccess }
  }
}
export const updateCampaignDetailsSuccess = data => {
  return {
    type: UPDATE_CAMPAIGN_DETAILS_SUCCESS,
    payload: data,
  }
}
export const updateCampaignDetailsError = error => {
  return {
    type: UPDATE_CAMPAIGN_DETAILS_FAIL,
    payload: error
  }
}

//campaign optioons
export const campaignOptions = (data) => {
  return {
    type: CAMPAIGN_OPTIONS,
    payload: data,
  }
}

//Get By CampaignId
export const getByCampaignId = (id) => {
  return {
    type: GET_BY_CAMPAIGN_ID,
    payload: id
  }
}
export const getByCampaignIdSuccess = data => {
  return {
    type: GET_BY_CAMPAIGN_ID_SUCCESS,
    payload: data,
  }
}
export const getByCampaignIdFail = error => {
  return {
    type: GET_BY_CAMPAIGN_ID_FAIL,
    payload: error
  }
}