import React, { useEffect, useState } from 'react';
import PrintQrForm from './PrintQrForm';
import SendQr from './Components/SendQr';
import DocumentTitle from 'components/DocumentTitle';
import { useSelector } from 'react-redux';

const PrintOr = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [eventData, setEventData] = useState();

    const campaignData = useSelector((state) => state?.GetCampaignDetails?.data?.data);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        if (campaignData?.length > 0) {
            const event = campaignData.filter(item => item.status === "ACTIVE");
            setEventData(event[0])
        }
    }, [campaignData])

    return (
        <>
            <DocumentTitle title={`Print QR / Send QR`} />
            <div className="page-content">
                <div className="page-title">Print QR / Send QR</div>
                <div className='print-tabs'>
                    <div className="tabs-container">
                        <div className="tabs">
                            <div className={`tab-item ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>Print QR</div>
                            <div className={`tab-item ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>Send QR</div>
                        </div>
                        <div className="tab-content">
                            {activeTab === 0 && <PrintQrForm eventData={eventData} />}
                            {activeTab === 1 && <SendQr eventData={eventData} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrintOr;