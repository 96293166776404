
import print from '../assets/images/print.png'
import award from '../assets/images/trophy.png'
import settings from '../assets/images/settings.png'
import prize from '../assets/images/ribbon.png'
import logoutIcon from '../assets/images/logout-icon.svg'
import qr from '../assets/images/qr.svg'
import megaphone from '../assets/images/megaphone.png'


export const MenuItems = [
  {
    name: "Manage QR",
    path: "/manageqr",
    icon: qr,
    role: ["management"],
  },
  {
    name: "Print OR / Send QR",
    path: "/print-qr",
    icon: print,
    role: ["staff", "management"],
  },
  {
    name: "Manage Campaign",
    path: "/manage-campaign",
    icon: prize,
    role: ["management"],
  },
  {
    name: "Winners",
    path: "/winners",
    icon: award,
    role: ["staff", "management"],
  },
  {
    name: "Manage Ads",
    path: "/manage-ads",
    icon: megaphone,
    role: ["management"],
  },
  {
    name: "Settings",
    path: "/settings",
    icon: settings,
    role: ["management"],
  },
  {
    name: "Logout",
    path: "/logout",
    icon: logoutIcon
  }

];
