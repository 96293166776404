import {
  GET_MANAGE_PRIZE_FAIL,
  GET_MANAGE_PRIZE_SUCCESS,
  MANAGE_PRIZE,
  GET_MANAGE_PRIZE_FAIL_UPDATE_ID,
  GET_MANAGE_PRIZE_SUCCESS_UPDATE_ID,
  MANAGE_PRIZE_UPDATE_ID,
  FREEZE_PRIZE,
  FREEZE_PRIZE_FAIL,
  FREEZE_PRIZE_SUCCESS,
  ADD_PRIZE,
  ADD_PRIZE_SUCCESS,
  ADD_PRIZE_FAIL,
  ADD_PRIZE_LOADING,
  UPDATE_IMAGE,
  UPDATE_IMAGE_SUCCESS,
  UPDATE_IMAGE_FAIL,
  CHANGE_PRIZE_STATUS,
  CHANGE_PRIZE_STATUS_SUCCESS,
  CHANGE_PRIZE_STATUS_FAIL,
  GET_SPINNER,
  GET_SPINNER_SUCCESS,
  GET_SPINNER_FAIL,
  ADD_SEGMENT,
  ADD_SEGMENT_SUCCESS,
  ADD_SEGMENT_FAIL,
  PUBLISH_PRIZE,
  PUBLISH_PRIZE_FAIL,
  PUBLISH_PRIZE_SUCCESS
} from "./actionType";

//get all prize
export const getAllPrize = (dispatch, readFile, compaginId) => ({
  type: MANAGE_PRIZE,
  payload: { dispatch, readFile, compaginId }
});
export const getAllPrizeSuccess = (prize) => ({
  type: GET_MANAGE_PRIZE_SUCCESS,
  payload: prize,
});
export const getAllPrizeFail = (error) => ({
  type: GET_MANAGE_PRIZE_FAIL,
  payload: error,
});

//update prize
export const PrizeUpdate = (id, data, getUpdatedRecord) => ({
  type: MANAGE_PRIZE_UPDATE_ID,
  payload: { id, data, getUpdatedRecord },
});
export const PrizeSuccess = ({ id, prob, productCode, totalprizes }) => ({
  type: GET_MANAGE_PRIZE_SUCCESS_UPDATE_ID,
  payload: { id: id, prob: prob, productCode: productCode, totalprizes: totalprizes },
});
export const PrizeFail = (error) => ({
  type: GET_MANAGE_PRIZE_FAIL_UPDATE_ID,
  payload: error,
});

//freeze price
export const freezePrize = (data, getUpdatedRecord) => ({
  type: FREEZE_PRIZE,
  payload: { data, getUpdatedRecord },
});
export const freezePrizeSuccess = ({ id, prob }) => ({
  type: FREEZE_PRIZE_SUCCESS,
  payload: { id: id, prob: prob, },
});
export const freezePrizeFail = (error) => ({
  type: FREEZE_PRIZE_FAIL,
  payload: error,
});

//add prize
export const addPrize = (data, history) => ({
  type: ADD_PRIZE,
  payload: { data, history },
});
export const addPrizeSuccess = (data) => ({
  type: ADD_PRIZE_SUCCESS,
  payload: data,
});
export const addPrizeFail = (error) => ({
  type: ADD_PRIZE_FAIL,
  payload: error,
});
export const addPrizeLoading = (loading) => {
  return {
    type: ADD_PRIZE_LOADING,
    payload: loading,
  }
}

//update  image
export const updatePrizeImage = (id, data, getUpdatedRecord) => {
  return ({
    type: UPDATE_IMAGE,
    payload: { id, data, getUpdatedRecord },
  });
}
export const updatePrizeImageSuccess = (data) => ({
  type: UPDATE_IMAGE_SUCCESS,
  payload: data,
});
export const updatePrizeImageFail = (error) => ({
  type: UPDATE_IMAGE_FAIL,
  payload: error,
});

//chnage status
export const changePrize = (id, status, getUpdatedRecord) => {
  return ({
    type: CHANGE_PRIZE_STATUS,
    payload: { id, status, getUpdatedRecord },
  });
}
export const changePrizeSuccess = (data) => ({
  type: CHANGE_PRIZE_STATUS_SUCCESS,
  payload: data,
});
export const changePrizeFail = (error) => ({
  type: CHANGE_PRIZE_STATUS_FAIL,
  payload: error,
});

//get spinner
export const getSpinner = (data) => ({
  type: GET_SPINNER,
});
export const getSpinnerSuccess = (data) => ({
  type: GET_SPINNER_SUCCESS,
  payload: data,
});
export const getSpinnerFail = (error) => ({
  type: GET_SPINNER_FAIL,
  payload: error,
});

//Add Segments

export const addSegment = (data, afterSegmentAddSuccess) => ({
  type: ADD_SEGMENT,
  payload: { data, afterSegmentAddSuccess },
});
export const addSegmentSuccess = (data) => ({
  type: ADD_SEGMENT_SUCCESS,
  payload: data,
});
export const addSegmentFail = (error) => ({
  type: ADD_SEGMENT_FAIL,
  payload: error,
});

//Publish Prize
export const publishManagePrize = (id, status, afterSuccess) => {
  return {
    type: PUBLISH_PRIZE,
    payload: { id, status, afterSuccess }
  }
}
export const publishManagePrizeSuccess = data => {
  return {
    type: PUBLISH_PRIZE_SUCCESS,
    payload: data,
  }
}
export const publishManagePrizeFail = error => {
  return {
    type: PUBLISH_PRIZE_FAIL,
    payload: error
  }
}