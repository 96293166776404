import { ADD_ADVERTISEMNET, ADD_ADVERTISEMNET_FAIL, ADD_ADVERTISEMNET_LOADING, ADD_ADVERTISEMNET_SUCCESS, CHANGE_ADS_STATUS, CHANGE_ADS_STATUS_FAIL, CHANGE_ADS_STATUS_SUCCESS, DEL_AD, DEL_AD_FAIL, DEL_AD_SUCCESS, GET_ALL_ADDVERTISEMENT, GET_ALL_ADDVERTISEMENT_FAIL, GET_ALL_ADDVERTISEMENT_SUCCESS, READ_ADVERTISEMNET_IMAGE, READ_ADVERTISEMNET_IMAGE_FAIL, READ_ADVERTISEMNET_IMAGE_SUCCESS } from "./actionTypes";

//get all advertisement
export const getAllAdvertisement = (dispatch, readFile) => ({
  type: GET_ALL_ADDVERTISEMENT,
  payload: { dispatch, readFile }
});
export const getAllAdvertisementSuccess = (prize) => ({
  type: GET_ALL_ADDVERTISEMENT_SUCCESS,
  payload: prize,
});
export const getAllAdvertisementFail = (error) => ({
  type: GET_ALL_ADDVERTISEMENT_FAIL,
  payload: error,
});
//change ads status
export const AdsStatus = (id, status, getUpdatedRecord) => ({
  type: CHANGE_ADS_STATUS,
  payload: { id, status, getUpdatedRecord },
});
export const AdsStatusSuccess = (data) => ({
  type: CHANGE_ADS_STATUS_SUCCESS,
  payload: data,
});
export const AdsStatusFail = (error) => ({
  type: CHANGE_ADS_STATUS_FAIL,
  payload: error,
});
//Delete Ad
export const DeleteAd = (id, getUpdatedRecord) => {
  return {
    type: DEL_AD,
    payload: { id, getUpdatedRecord },
  }
}
export const DeleteAdSuccess = ads => {
  return {
    type: DEL_AD_SUCCESS,
    payload: ads,
  }
}
export const DeleteAdError = error => {
  return {
    type: DEL_AD_FAIL,
    payload: error,
  }
}
//adding ads 
export const addAdvertisement = (data, getUpdatedRecord) => ({
  type: ADD_ADVERTISEMNET,
  payload: { data, getUpdatedRecord },
});
export const addAdvertisementSuccess = (data) => ({
  type: ADD_ADVERTISEMNET_SUCCESS,
  payload: data,
});
export const addAdvertisementFail = (error) => ({
  type: ADD_ADVERTISEMNET_FAIL,
  payload: error,
});
export const addAdvertisementLoading = (loading) => {
  return {
    type: ADD_ADVERTISEMNET_LOADING,
    payload: loading,
  }
}

//adding ads 
export const readFile = (data) => ({
  type: READ_ADVERTISEMNET_IMAGE,
  payload: data,
});
export const readFileSuccess = (data) => ({
  type: READ_ADVERTISEMNET_IMAGE_SUCCESS,
  payload: data,
});
export const readFileFail = (error) => ({
  type: READ_ADVERTISEMNET_IMAGE_FAIL,
  payload: error,
});
