import {
  GET_MANAGE_PRIZE_FAIL,
  GET_MANAGE_PRIZE_SUCCESS,
  MANAGE_PRIZE,
  GET_MANAGE_PRIZE_FAIL_UPDATE_ID,
  GET_MANAGE_PRIZE_SUCCESS_UPDATE_ID,
  MANAGE_PRIZE_UPDATE_ID,
  FREEZE_PRIZE,
  FREEZE_PRIZE_SUCCESS,
  FREEZE_PRIZE_FAIL,
  ADD_PRIZE,
  ADD_PRIZE_SUCCESS,
  ADD_PRIZE_FAIL,
  ADD_PRIZE_LOADING,
  UPDATE_IMAGE,
  UPDATE_IMAGE_SUCCESS,
  UPDATE_IMAGE_FAIL,
  CHANGE_PRIZE_STATUS,
  CHANGE_PRIZE_STATUS_SUCCESS,
  CHANGE_PRIZE_STATUS_FAIL,
  GET_SPINNER,
  GET_SPINNER_SUCCESS,
  GET_SPINNER_FAIL,
  ADD_SEGMENT,
  ADD_SEGMENT_SUCCESS,
  ADD_SEGMENT_FAIL,
  PUBLISH_PRIZE,
  PUBLISH_PRIZE_SUCCESS,
  PUBLISH_PRIZE_FAIL
} from "./actionType";

//Get All PRIZE DETAILS
const initialState = {
  QRlist: [],
  error: {},
  loading: false,
};
export const GetallPrize = (state = initialState, action) => {
  switch (action.type) {
    case MANAGE_PRIZE:
      return {
        ...state,
        loading: true,
      };
    case GET_MANAGE_PRIZE_SUCCESS:
      return {
        ...state,
        QRlist: action.payload,
        loading: false,
      };

    case GET_MANAGE_PRIZE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
//update prize
const updatePriseInitialState = {
  data: [],
  error: {},
  loading: false,
};
export const updatePrize = (state = updatePriseInitialState, action) => {
  switch (action.type) {
    case MANAGE_PRIZE_UPDATE_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_MANAGE_PRIZE_SUCCESS_UPDATE_ID:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case GET_MANAGE_PRIZE_FAIL_UPDATE_ID:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
//freeze price
const freezePrice = {
  data: null,
  error: null,
  loading: false,
};

export const FreezePrize = (state = freezePrice, action) => {
  switch (action.type) {
    case FREEZE_PRIZE:
      return {
        data: null,
        error: null,
        loading: true,
      };
    case FREEZE_PRIZE_SUCCESS:
      return {
        error: null,
        data: action.payload,
        loading: false,
      };

    case FREEZE_PRIZE_FAIL:
      return {
        data: null,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
//addprize
const addPrize = {
  data: "",
  error: "",
  loading: false,
};
export const AddPrize = (state = addPrize, action) => {
  switch (action.type) {
    case ADD_PRIZE:
      return { ...state, loading: true };
    case ADD_PRIZE_SUCCESS:
      return { ...state, data: action.payload, loading: false }
    case ADD_PRIZE_FAIL:
      return { ...state, error: action.payload, loading: false };
    case ADD_PRIZE_LOADING:
      return {
        loading: action.payload
      };
    default:
      return state;
  }
};
//update image 
const updatePrizeImage = {
  data: "",
  error: "",
  loading: false,
};
export const UpdatePrizeImage = (state = updatePrizeImage, action) => {
  switch (action.type) {
    case UPDATE_IMAGE:
      return { ...state, loading: true };
    case UPDATE_IMAGE_SUCCESS:
      return { ...state, data: action.payload, loading: false }
    case UPDATE_IMAGE_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};
//change status
const changestatus = {
  data: "",
  error: "",
  loading: false,
};
export const ChangePrizeStatus = (state = changestatus, action) => {
  switch (action.type) {
    case CHANGE_PRIZE_STATUS:
      return { ...state, loading: true };
    case CHANGE_PRIZE_STATUS_SUCCESS:
      return { ...state, data: action.payload, loading: false }
    case CHANGE_PRIZE_STATUS_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};

//get spinner
const spinnerinitialState = {
  spinnerData: [],
  error: {},
  loading: false,
};
export const GetSpinner = (state = spinnerinitialState, action) => {
  switch (action.type) {
    case GET_SPINNER:
      return {
        ...state,
        loading: true,
      };
    case GET_SPINNER_SUCCESS:
      return {
        ...state,
        spinnerData: action.payload,
        loading: false,
      };
    case GET_SPINNER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};


//Add Segments
const addSegment = {
  data: "",
  error: "",
  loading: false,
};
export const AddSegments = (state = addSegment, action) => {
  switch (action.type) {
    case ADD_SEGMENT:
      return { ...state, loading: true };
    case ADD_SEGMENT_SUCCESS:
      return { ...state, data: action.payload, loading: false }
    case ADD_SEGMENT_FAIL:
      return { ...state, error: action.payload, loading: false };

    default:
      return state;
  }
};


// Get CampaignId
const prizePublishInitialState = {
  error: "",
  success: "",
  loading: false
}

export const MagangePrizePublish = (state = prizePublishInitialState, action) => {
  switch (action.type) {
    case PUBLISH_PRIZE:
      return {
        ...state,
        loading: true,
      }
    case PUBLISH_PRIZE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }

    case PUBLISH_PRIZE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
  }
  return state
}