import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import { MenuItems } from "../../constants/MenuItems";
import { useSelector } from "react-redux";
import logoutIcon from "../../assets/images/logout-icon.svg";

const SidebarContent = (props) => {
  const ref = useRef();
  const authUser = useSelector((state) => state?.authInfo?.data?.user);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        // activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref} style={{ height: "100%" }}>
        {/* <div className="logo-360">
          <img src={home360Logo} alt="logo" width="100%" />
        </div> */}
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {MenuItems.map((item) => {
              if (item?.role && item?.role?.length > 0) {
                if (item?.role?.includes(authUser?.role))
                  return (
                    <li key={item.name}>
                      {item.path ? (
                        <NavLink
                          to={item.path}
                          className={({ isActive }) =>
                            isActive ? "active" : "inactive"
                          }
                        >
                          <img src={`${item.icon}`} width={30} className={``} />
                          <span>{item.name}</span>
                        </NavLink>
                      ) : (
                        <div
                          className="logout"
                          onClick={() => {
                            // Perform logout action here
                            props.history.push("/logout");
                          }}
                        >
                          <span className="remove-sm">{item.name}</span>
                          <img src={item.icon} alt="logout" />
                        </div>
                      )}
                    </li>
                  );
              } else {
                return (
                  <li key={item.name}>
                    {item.path ? (
                      <NavLink
                        to={item.path}
                        className={({ isActive }) =>
                          isActive ? "active" : "inactive"
                        }
                      >
                        <img src={`${item.icon}`} width={30} className={``} />
                        <span>{item.name}</span>
                      </NavLink>
                    ) : (
                      <div
                        className="logout"
                        onClick={() => {
                          // Perform logout action here
                          props.history.push("/logout");
                        }}
                      >
                        <span className="remove-sm">{item.name}</span>
                        <img src={item.icon} alt="logout" />
                      </div>
                    )}
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
