import { Error_Request, Error_Reset } from "./actionTypes"

//Get All Error
const INITIAL_STATE = {
  errorcode: null,
}
const ErrorScreenCode = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Error_Request: 
      return {
        ...state,
        errorcode: action.payload,
      }
 
    case Error_Reset:
      return {
        ...state,
        errorcode: null,
      }
    default:
      return state
  }
}
export default ErrorScreenCode
