import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import AppLogo from '../../assets/images/logo.png'
// Hook Form Validation
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import AuthLoader from "components/Common/AuthLoader";
import DocumentTitle from "components/DocumentTitle";

const ForgetPasswordPage = props => {

  //meta title

  const dispatch = useDispatch();

  const campaignDetail = useSelector(state => state.GetCampaignDetails.data);

  const forgetFormValues = {
    email: '',
  }

  const forgetFormSchema = Yup.object({
    email: Yup.string().required("Enter email address").test('email', "Enter valid email address", val => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
      else return true
    }).trim(),
  })

  const { register, handleSubmit, formState: { errors, }, } = useForm({
    resolver: yupResolver(forgetFormSchema),
    defaultValues: forgetFormValues
  });

  const onSubmit = (values) => {
    dispatch(userForgetPassword(values, props.history));
  }

  const { forgetLoader } = useSelector(state => ({
    forgetLoader: state.ForgetPassword.forgetLoader,
  }));

  return (
    <React.Fragment>
      <DocumentTitle title={`Forget Password`} />

      <div className="account-pages">
        <Container>
          <div className="text-center auth-logo">
            <img src={AppLogo} alt="Logo" />
          </div>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden auth-card">
                <div className="bg-primary bg-soft background-auth-page">
                  <Row className="align-items-center auth-head">
                    <Col xs={7} className='auth-heading py-4'>
                      <div className="auth-heading-text">
                        <h2 className="f-size-24">Forget Password</h2>
                        <p className="f-size-12">Enter your email for the verification process. We will send 6 digits code to your email.</p>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end logo-img">
                      {/* <img src={profile} alt="" className="img-fluid" /> */}
                    </Col>
                  </Row>
                </div>
                <CardBody>
                  {/* <div className="d-inline-block">
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt="logo"
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div>
                    <form
                      noValidate
                      className="form-horizontal"
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                    >
                      <div>
                        <Label className="form-label mt-3 f-size-14">Email address <span>*</span></Label>
                        <input type="email" {...register("email")} placeholder="email@address.com" className={errors.email?.message ? 'form-control mb-0 is-invalid' : '' + 'form-control mb-0'} />
                        {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                      </div>

                      {forgetLoader === true ? <AuthLoader /> :
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-block primary-button"
                            type="submit"
                          >
                            Send code
                          </button>
                        </div>
                      }
                      <p className="go-back f-size-12">
                        Go back to{" "}
                        <Link to="/login" className="font-weight-medium primary-color f-size-12">
                          Login
                        </Link>{" "}
                      </p>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center copy-right">
                <p>{campaignDetail?.campaignName || ""} @ {new Date().getFullYear()} All Rights Reserved</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
