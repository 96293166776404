import React from 'react';
// import LoadingGif from '../../assets/images/loading.gif';
import { useLottie } from 'lottie-react';
import animationData from '../../assets/images/lottie/preloader.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
}

const PageLoader = () => {
    const { View } = useLottie(defaultOptions, { height: 80, width: 80 })
    return (
        <div className='loaderoverlay'>
            <div className='icon'>
                <div className="spinner"></div>
            </div>
        </div>
    );
};

export default PageLoader;