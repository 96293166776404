// Get All Admin 
export const GET_ALL_QRCODES = "GET_ALL_QRCODES"
export const GET_ALL_QRCODES_SUCCESS = "GET_ALL_QRCODES_SUCCESS"
export const GET_ALL_QRCODES_FAIL = "GET_ALL_QRCODES_FAIL"
//Get faq by ID

export const GET_QRCODES_BY_ID = "GET_QRCODES_BY_ID"
export const GET_QRCODES_BY_ID_SUCCESS = "GET_QRCODES_BY_ID_SUCCESS"
export const GET_QRCODES_BY_ID_FAIL = "GET_QRCODES_BY_ID_FAIL"

//Add QRCode
export const GENERATE_QRCODES = "GENERATE_QRCODES"
export const GENERATE_QRCODES_SUCCESS = "GENERATE_QRCODES_SUCCESS"
export const GENERATE_QRCODES_FAIL = "GENERATE_QRCODES_FAIL"
export const QRCODES_LOADING = "QRCODES_LOADING"

//set Status QRCode
export const SET_STATUS_QRCODES = "SET_STATUS_QRCODES"
export const SET_STATUS_QRCODES_SUCCESS = "SET_STATUS_QRCODES_SUCCESS"
export const SET_STATUS_QRCODES_FAIL = "SET_STATUS_QRCODES_FAIL"

//Delete Faq
export const DEL_QRCODES = "DEL_QRCODES"
export const DEL_QRCODES_SUCCESS = "DEL_QRCODES__SUCCESS"
export const DEL_QRCODES__FAIL = "DEL_FAQ__FAIL"
//issue Faq
export const ISSUE_QRCODES = "ISSUE_QRCODES"
export const ISSUE_QRCODES_SUCCESS = "ISSUE_QRCODES__SUCCESS"
export const ISSUE_QRCODES__FAIL = "ISSUE_QR__FAIL"
export const CONFIRM_PRINT_QR = "CONFIRM_PRINT_QR"

//Send QR
export const SEND_QRCODE = 'SEND_QRCODE'
export const SEND_QRCODE_SUCCESS = 'SEND_QRCODE_SUCCESS'
export const SEND_QRCODE_FAIL = 'SEND_QRCODE_FAIL'
