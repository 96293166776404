import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./application_url_helper";

// Login Method
export const applicationLogin = (data) => post(url.APPLICATION_LOGIN, data);
export const forgetPwd = (data) => post(url.FORGET_PASSWORD, data);
export const forgetPwdResendCode = (data) =>
  post(url.FORGET_PASSWORD_RESEND_CODE, data);
export const resetPwd = (data) => post(url.RESET_PASSWORD_URL, data);

//Admin
export const getAllAdmins = () => get(url.GET_ALL_ADMIN);
export const postNewAdmin = (data) => post(url.POST_NEW_ADMIN, data);
export const updateAdmin = (data, id) => put(`${url.UPDATE_ADMIN}/${id}`, data);
export const getAdminById = (id) => {
  return get(`${url.GET_ADMIN_BY_ID}/${id}`, { params: { id } });
};
export const updateStatus = (data, id) =>
  put(`${url.UPDATE_STATUS}/${id}`, data);

//Users
export const getAllUserList = () => get(url.GET_ALL_USERS);
export const addNewUser = (data) => post(url.ADD_NEW_USER, data);
export const getByUserId = (id) => {
  return get(`${url.GET_BY_USER_ID}/${id}`, { params: { id } });
};
export const updateUser = (data, id) => put(`${url.UPDATE_USER}/${id}`, data);
export const changeUserStatus = (data, id) =>
  put(`${url.USER_UPDATE_STATUS}/${id}`, data);

//Qrcodes
export const getAllQrcodes = () => get(url.GET_ALL_QRCODES);
export const addNewQrcodes = (data) => post(url.ADD_NEW_QRCODES, data);
export const setStatusNewQrcodes = (data) => post(url.SET_STATUS_QRCODES, data);
export const updateQrcodes = (data, id) =>
  put(`${url.UPDATE_QRCODES}/${id}`, data);
export const getQrcodesId = (id) => {
  return get(`${url.GET_QRCODES_ID}/${id}`, { params: { id } });
};
export const deleteQrcodes = (id) => {
  return del(`${url.DELETE_QRCODES}/${id}`);
};
export const issueQr = (count, data) => post(`${url.ISSUE_QRCODES}/${count}`, data);
export const sendQRCodes = (data) => post(url.SEND_QRCODES, data);

//Generate password for user and admin
export const generatePwd = (data) => post(url.GENERATE_PASSWORD, data);
export const updateProfile = (data, id) =>
  put(`${url.UPDATE_PROFILE}/${id}`, data);
export const changeLoginPassword = (data) => post(url.CHANGE_PASSWORD, data);

//Update profile image
export const updateProfileImg = (header, data) =>
  post(url.UPDATE_PROFILE_IMG, data, header);
export const getDecodeImage = (data, type) =>
  post(`${type}${url.GET_DECODE_IMAGE}`, data);

//Settings
export const postSettingsUpdate = (data, id) => put(`${url.SETTINGS_UPDATE}/${id}`, data);
export const postCampaignSettingsUpdate = (data, id) => put(`${url.CAMPAIGNING_SETTINGS_UPDATE}/${id}`, data);
export const getSettings = () => get(url.GET_SETTINGS);
export const getNotificationUrl = () => get(url.GET_NOTIFICATION);
export const getCampaignDetails = () => get(url.GET_CAMPAIGN_DETAILS);
export const updateCampaignDetails = (data, id) => put(`${url.GET_CAMPAIGN_DETAILS}/${id}`, data);

//Roles
export const getAllAdminRoles = () => get(url.GET_ALL_ADMIN_ROLES);
export const adminRoleType = (type) => get(`${url.ADMIN_ROLE_TYPE}/${type}`);

//Manage QR GET_QRCODES
export const manageQr = (search, currentPage, pageSize) => post(`${url.GET_QRCODES_DETAILS}/${currentPage}/${pageSize}`, search);

//Manage PRIZWE
export const managePrize = (data) => post(url.GET_PRIZE_DETAILS, data);
export const updatePrizebyid = (id, data) => put(`${url.PRIZE_DETAILS_UPDATE}/${id}`, data);
export const freezePrize = (data) => put(url.GET_PRIZE_DETAILS, data);
export const addprize = (data) => post(url.ADD_NEW_PRIZE, data);
export const updateprizeimg = (id, data, header) => put(`${url.PRIZE_IMAGE_UPDATE}/${id}`, data, header)
export const prizestatuschnage = (id, data) => put(`${url.PRIZE_STATUS_CHANGE}/${id}`, data)
export const newSegments = (data) => post(url.ADD_NEW_SEGMENTS, data);
export const addCampaignDetails = (data) => post(url.ADD_CAMPAIGN, data);
export const getCampaignId = (id) => get(`${url.GET_CAMPAIGN_ID}/${id}`);
export const managePrizePublish = (id, data) => put(`${url.PRIZE_PUBLISH}/${id}`, data);

export const manageSpinner = () => get(url.GET_SPINNER_DETAILS);
//WINNERS
export const winnersDetaIls = (data, currentPage, pageSize) => post(`${url.ALL_WINNERS_LIST}/${currentPage}/${pageSize}`, data);
export const redeemPrize = (data, header) => post(url.REDEEM_PRIZE, data, header);

//Advertisement
export const manageAds = () => get(url.GET_ALL_ADDS);
export const changeStatus = (id, data) => put(`${url.CHANGE_STATUS}/${id}`, data)
export const deleteAd = (id) => del(`${url.DELETE_AD}/${id}`)
export const addAdvertizement = (data, header) => post(url.ADDING_ADS, data, header);
export const readAdvertizementFile = (data) => post(url.READ_ADS_FILE, data);
