import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import upload from "../../../assets/images/svgicons/upload.svg";
import { useDispatch } from "react-redux";
import { addAdvertisement, getAllAdvertisement, readFile } from "store/manageads/actions";
import toast from "react-hot-toast";

const UploadAds = ({ src, showModal, setShowModal }) => {

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("image");
  const dispatch = useDispatch();

  const toggleModal = () => {
    setShowModal(!showModal);
    setErrorMessage("");
  };

  const getUpdatedRecord = () => {
    dispatch(getAllAdvertisement(dispatch, readFile));
  };
 
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const maxFileSizeImage = 2 * 1024 * 1024; // 2MB
      const maxFileSizeVideo = 10 * 1024 * 1024; // 10MB
      const maxDimension = { width: 1080, height: 1920 };
      const maxFileSize = selectedFileType === "image" ? maxFileSizeImage : maxFileSizeVideo;
      //error scenarios
      if ((selectedFileType === "image" && !file.type.startsWith("image")) ||
        (selectedFileType === "video" && file.type !== "video/mp4")) {
        setErrorMessage(selectedFileType === "image" ? "File format should be PNG, JPEG, or JPG." : "Only MP4 videos are allowed.");
        return;
      }
      
      if (file.size > maxFileSize) {
        setErrorMessage(selectedFileType === "image" ? "Image size should not exceed 2MB." : "Video size should not exceed 100MB.");
        return;
      }

      if (file.type.startsWith('image')) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const img = new Image();
          //image upload
          img.onload = function () {
            if (img.width !== maxDimension.width || img.height !== maxDimension.height) {
              setErrorMessage(`* Image dimensions should be like ${maxDimension.width} x ${maxDimension.height}px.`);
            } else {
              dispatch(addAdvertisement({ ads: file }, getUpdatedRecord));
              toggleModal();
            }
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
        //video upload 
      } else if (file.type.startsWith('video')) {
        dispatch(addAdvertisement({ ads: file }, getUpdatedRecord));
        toggleModal();
      } else {
        setErrorMessage("Unsupported file type.");
      }
    }
  };


  return (
    <div>
      <Modal
        size="md"
        isOpen={showModal}
        centered={true}
        className="doc-modal confirmation"
      >
        <div className="modal-header px-0 pt-0 border-0">
          <div className="head-item">
            <h3 className="page-title font-size-18 p-0">Upload Ads </h3>
            <span onClick={toggleModal} aria-hidden="true" className="close-icon">
              <i className="bx bx-x"></i>
            </span>
          </div>
        </div>
        <div className="modal-body">
          <div className="upload-conatiner" >
            <div>
              <input
                type="radio"
                id="image"
                name="fileType"
                value="image"
                defaultChecked={selectedFileType === "image"}
                onChange={(event) =>  setSelectedFileType(event.target.value)}
              />
              <label htmlFor="image" className="ms-1">Image</label>
              <input
                type="radio"
                className="ms-2"
                id="video"
                name="fileType"
                value="video"
                defaultChecked={selectedFileType === "video"}
                onChange={(event) =>  setSelectedFileType(event.target.value)}
              />
              <label htmlFor="video" className="ms-1">Video</label>
            </div>

          </div>

          {selectedFileType === "image" && (
            <>
              <div className="upload-container" >
                <input
                  type="file"
                  id="profileUpload"
                  accept="image/jpg,image/jpeg,image/png"
                  name="profileUpload"
                  onChange={(event) => handleFileChange(event)}
                />
                <div className="upload-content">
                  <label className="m-0" htmlFor="Upload"></label>
                  <img src={upload} alt="Upload" width={50} />
                  <h5 className="p-0">Please click here to upload files</h5>
                  <h5>We only accepts PNG, JPEG & JPG images</h5>
                  <h6>Dimension should be like 1080X1920px</h6>
                  <h6>Size should not exceed more than 2MB</h6>
                </div>
              </div>
            </>
          )}

          {selectedFileType === "video" && (
            <>
              <div className="upload-container" >
                <input
                  type="file"
                  id="profileUpload"
                  accept="video/mp4"
                  name="profileUpload"
                  onChange={(event) => handleFileChange(event)}
                />
                <div className="upload-content">
                  <label className="m-0" htmlFor="Upload"></label>
                  <img src={upload} alt="Upload" width={50} />
                  <h5 className="p-0">Please click here to upload video files</h5>
                  <h5>Only MP4 videos are allowed</h5>
                  <h6>File size should not exceed 10MB</h6>
                </div>
              </div>
            </>
          )}
          {errorMessage && <p className="text-danger  mt-2">{errorMessage}</p>}
        </div>
      </Modal>
    </div>
  );
};

export default UploadAds;
