import { AUTH_lOGIN_INFO, AUTH_lOGIN_INFO_SUCCESS } from "./actionTypes"

   
  const initialState = {
    data: [],
    loading: null,
  }
  
  const authInfo = (state = initialState, action) => { 
    switch (action.type) {
      case AUTH_lOGIN_INFO:
        state = {
          data: "",
          loading: true,
        }
        break
      case AUTH_lOGIN_INFO_SUCCESS:
        state = {
            data: action.payload,
            loading: false,
        }
        break 
    }
    return state
  }
  
  export default authInfo
  