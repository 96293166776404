import { segmentTemplateFoure, segmentTemplateOne, segmentTemplateThree, segmentTemplateTwo } from 'constants/SpinWheelSegment';
import React, { useEffect, useState } from 'react';

const SpinPreview = ({ selectedSegment, segmentsName }) => {

    const [wheel, setWheel] = useState(null);
    const [spinImage, setSpinImage] = useState();

    useEffect(() => {
        if (selectedSegment === "4") {
            setSpinImage(segmentTemplateOne)
        } else if (selectedSegment === "6") {
            setSpinImage(segmentTemplateTwo)
        } else if (selectedSegment === "8") {
            setSpinImage(segmentTemplateThree)
        } else if (selectedSegment === "10") {
            setSpinImage(segmentTemplateFoure)
        }

    }, [selectedSegment, spinImage]);

    useEffect(() => {
        const segments = spinImage?.map((spin, index) => {
            const text = (segmentsName && segmentsName?.segment?.length > 0) ? segmentsName?.segment[index] : "";
            return ({
                'image': spin?.image,
                "text": text?.productCode || ""
            })
        });

        const newWheel = new window.Winwheel({
            canvasId: "myCanvas",
            numSegments: selectedSegment,
            outerRadius: 150,
            drawText: true,
            textFontSize: 30,
            textAlignment: 'inner',
            textMargin: '120',
            txtFontFamily: 'Montserrat',
            textFillStyle: '#fff',
            drawMode: "segmentImage",
            size: 50,
            segments: segments,

            animation: {
                type: "custom",
                duration: 5,
                propertyName: "rotationAngle",
            },
        });
        setWheel(newWheel);

        return () => {
            if (wheel) {
                wheel.stopAnimation();
            }
        };

    }, [selectedSegment, spinImage, segmentsName]);

    return (
        <div className='segment-view'>
            {selectedSegment?.length > 0 ? <canvas id="myCanvas" width="840" height="840" ></canvas> : <p>Select prize count</p>}
        </div>
    );
};

export default SpinPreview;