
//Auth
export const APPLICATION_LOGIN = "/adminpanel/auth/login";
export const FORGET_PASSWORD = "/adminpanel/admin/forgot-password-token";
export const FORGET_PASSWORD_RESEND_CODE = "/adminpanel/admin/forgot-password-resend-token";
export const RESET_PASSWORD_URL = "/adminpanel/admin/forgot-password-reset";

//Admin
export const GET_ALL_ADMIN = "/adminpanel/admin/get-all/0/0";
export const POST_NEW_ADMIN = "/adminpanel/admin/add";
export const UPDATE_ADMIN = "/adminpanel/admin";
export const GET_ADMIN_BY_ID = "/adminpanel/admin";
export const UPDATE_STATUS = "/adminpanel/admin/admin-status";

//Users
export const GET_ALL_USERS = "/adminpanel/user/get-all/0/0";
export const ADD_NEW_USER = "/adminpanel/user/add";
export const UPDATE_USER = "/adminpanel/user";
export const GET_BY_USER_ID = "/adminpanel/user";
export const USER_UPDATE_STATUS = "/adminpanel/user/user-status";

//Manage Advisors
export const GET_MANAGE_ADVISORS = "/adminpanel/advisor/get-all/0/0";
export const GET_ADVISOR_ID = "/adminpanel/advisor";
export const ADD_NEW_ADVISOR = "/adminpanel/advisor/add"
export const UPDATE_ADVISOR = "/adminpanel/advisor"
export const ADVAICER_UPDATE_STATUS = "/adminpanel/advisor/advisor-status";
export const GET_ACTIVE_ADVISORS = "/adminpanel/advisor/get-by-status/0/0";

//Manage Tips
export const GET_TIPS = "/adminpanel/tips/get-all/0/0";
export const ADD_NEW_TIPS = "/adminpanel/tips/add";
export const UPDATE_TIPS = "/adminpanel/tips";
export const GET_BY_TIPS_ID = "/adminpanel/tips";
export const CHANGE_TIPS_STATUS = "/adminpanel/tips/tip-status";
export const DELETE_TIPS = "/adminpanel/tips";
export const GET_ALL_TIPTYPE = "/adminpanel/tiptype/get-all";

//Category
export const GET_MAIN_CATEGORY = "/adminpanel/category/get-all/0/0";
export const GET_SUBCATEGORY = "/adminpanel/subcategory/get-by-category";

//Manage QRCODES
export const GET_ALL_QRCODES = "/adminpanel/qrnumbers/get-all/0/0";
export const GET_QRCODES_ID = "/adminpanel/qrnumbers";
export const ADD_NEW_QRCODES = "/adminpanel/qrnumbers/add";
export const SET_STATUS_QRCODES = "/adminpanel/qrnumbers/set-status";
export const UPDATE_QRCODES = "/adminpanel/qrnumbers"
export const DELETE_QRCODES = "/adminpanel/qrnumbers"
export const ISSUE_QRCODES = "/adminpanel/qrcode/get-qr"
export const SEND_QRCODES = "/adminpanel/qrcode/send-qr"

//Generate password
export const GENERATE_PASSWORD = "/adminpanel/user/reset-password-user";
export const UPDATE_PROFILE = "/adminpanel/admin/profile";
export const CHANGE_PASSWORD = "/adminpanel/admin/profile/change-pwd";

//update profile image
export const UPDATE_PROFILE_IMG = "/adminpanel/user/update-profile-image";
export const GET_DECODE_IMAGE = "/adminpanel/read";

//settings
export const SETTINGS_UPDATE = "/adminpanel/settings";
export const CAMPAIGNING_SETTINGS_UPDATE = "/adminpanel/settings/update"
export const GET_SETTINGS = "/adminpanel/settings";
export const GET_NOTIFICATION = "/adminpanel/settings/notification";
export const GET_CAMPAIGN_DETAILS = "/adminpanel/campaign";
export const GET_CAMPAIGN_ID = "/adminpanel/campaign";

//Roles
export const GET_ALL_ADMIN_ROLES = "/adminpanel/roles/get-all";
export const ADMIN_ROLE_TYPE = "/adminpanel/roles/get-by-";

//Manage QR
export const GET_QRCODES_DETAILS = "/adminpanel/qrcode/get-all";
//Manage Prize
export const GET_PRIZE_DETAILS = "/adminpanel/prize";
export const ADD_NEW_PRIZE = "/adminpanel/prize/add";
export const PRIZE_DETAILS_UPDATE = "/adminpanel/prize/update-prize";
export const PRIZE_IMAGE_UPDATE = "/adminpanel/prize/update-prize-img";
export const PRIZE_STATUS_CHANGE = "/adminpanel/prize/update-status";
export const GET_SPINNER_DETAILS = "/adminpanel/prize/get-spinner-img"
export const ADD_NEW_SEGMENTS = "/adminpanel/prize/segment"
export const ADD_CAMPAIGN = "/adminpanel/campaign"
export const PRIZE_PUBLISH = "/adminpanel/prize/publish"
//get winners
export const ALL_WINNERS_LIST = "/adminpanel/qrcode/get-all-winners";
export const REDEEM_PRIZE = "/adminpanel/qrcode/redeem";
//Advertisement
export const GET_ALL_ADDS = "/adminpanel/ads/get-all";
export const CHANGE_STATUS = "/adminpanel/ads/update-status"
export const DELETE_AD = "/adminpanel/ads"
export const ADDING_ADS = "/adminpanel/ads/add"
export const READ_ADS_FILE = "/adminpanel/ads/read-file"
