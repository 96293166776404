import React, { useEffect } from 'react';
import zxcvbn from 'zxcvbn';

const PasswordStrengthMeter = ({ password, setPasswordValidate }) => {
    const testResult = zxcvbn(password);
    const num = testResult.score * 100 / 4;
    useEffect(() => {
        setPasswordValidate(testResult.score)
    }, [password])

    const createPassLabel = () => {
        switch (testResult.score) {
            case 0:
                return 'Very weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Medium';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return '';
        }
    }

    const funcProgressColor = (n) => {
        switch (n) {
            case 0:
                return '#D7D7D7';
            case 1:
                return '#EA1111';
            case 2:
                return '#FFAD00';
            case 3:
                return '#9bc158';
            case 4:
                return '#00b500';
            default:
                return 'none';
        }
    }

    const changePasswordColor = (n) => ({
        width: `95%`,
        background: funcProgressColor(n),
        height: '7px'
    })

    return (
        <>
            <div className='d-flex justify-content-between'>
                <div className="progress mt-2 w-100 bg-transparent" style={{ height: '7px' }}>
                    <div className="progress-bar rounded-pill" style={changePasswordColor((testResult.score >= 1) ? 1 : 0)}></div>
                </div>
                <div className="progress mt-2 w-100 bg-transparent" style={{ height: '7px' }}>
                    <div className="progress-bar rounded-pill" style={changePasswordColor((testResult.score >= 2) ? 2 : 0)}></div>
                </div>
                <div className="progress mt-2 w-100 bg-transparent" style={{ height: '7px' }}>
                    <div className="progress-bar rounded-pill" style={changePasswordColor((testResult.score >= 3) ? 3 : 0)}></div>
                </div>
                <div className="progress mt-2 w-100 bg-transparent" style={{ height: '7px' }}>
                    <div className="progress-bar rounded-pill" style={changePasswordColor((testResult.score >= 4) ? 4 : 0)}></div>
                </div>
            </div>
            <p className='pb-0 mb-0 f-size-12' style={{ color: funcProgressColor(testResult.score) }}>{createPassLabel()}</p>
        </>
    )
}

export default PasswordStrengthMeter