import {
  GET_MANAGE_QR,
  GET_MANAGE_QR_FAIL,
  GET_MANAGE_QR_SUCCESS,
} from "./actionType";

//Get All QR DETAILS
const initialState = {
  QRlist: [],
  error: {},
  loading: false,
};
export const GetallQr = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGE_QR:
      return {
        ...state,
        loading: true,
      };
    case GET_MANAGE_QR_SUCCESS:
      return {
        ...state,
        QRlist: action.payload,
        loading: false,
      };

    case GET_MANAGE_QR_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
