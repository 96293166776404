// @flow
import { redeemPrize, winnersDetaIls } from "helpers/backend_helper";
import {
  getAllWinnersSuccess,
  getWinnersFail,
  redeemPrizeAction,
  redeemPrizeActionFail,
} from "./action";
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_ALL_WINNERS, REDEEM_PRIZE } from "./actionType";
import toast from "react-hot-toast";
import { readFileSuccess } from "store/actions";

//Get All QR DETAILS
function* fetchWinnersDetails({ payload: { data, currentPage, pageSize, dispatch, readFile } }) {
  try {
    yield put(readFileSuccess());
    const response = yield call(winnersDetaIls, data, currentPage, pageSize);
    if (response?.status?.toLowerCase() === "success") {
      yield put(getAllWinnersSuccess(response));
      if (response?.qrlist?.length > 0 && dispatch) {
        response?.qrlist.map((item) => {
          if (item.redeemedPerson) {
            dispatch(readFile({ imageKey: item.redeemedPerson }))
          }
        })
      }
    } else {
      yield put(getWinnersFail(response));
    }
  } catch (error) {
    yield put(getWinnersFail(error));
  }
}
export function* WinnersSaga() {
  yield takeEvery(GET_ALL_WINNERS, fetchWinnersDetails);
}

//redeem prize
function* redeemprize({ payload: { data, resetRedeemModel } }) {
  try {
    const response = yield call(redeemPrize, data, { headers: { "Content-Type": "multipart/form-data" } });
    if (response?.status?.toLowerCase() === "success") {
      yield put(redeemPrizeAction(response?.qrlist));
      resetRedeemModel();
    } else {
      yield put(redeemPrizeActionFail(response));
    }
  } catch (error) {
    yield put(redeemPrizeActionFail(error));
  }
}
export function* RedeemPrizeSaga() {
  yield takeEvery(REDEEM_PRIZE, redeemprize);
}
