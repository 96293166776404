import PropTypes from "prop-types";
import React, { useState } from "react";
import { Row, Col, CardBody, Card, Container, Label } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";

import AppLogo from '../../assets/images/logo.png'
//redux
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../store/actions";

// Components
import AuthLoader from "components/Common/AuthLoader";
import { withTranslation } from "react-i18next";
import DocumentTitle from "components/DocumentTitle";

const Login = (props) => {
  //meta title
  const dispatch = useDispatch();
  const redirectUrl = (sessionStorage.getItem("redirectUrl"));

  const campaignDetail = useSelector(state => state.GetCampaignDetails.data);

  const loginForm = {
    email: "",
    password: ""
  }
  const loginSchema = Yup.object().shape({
    email: Yup.string().required("Enter email address").test('email', "Enter valid email address", val => {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)) return false
      else return true
    }).trim(),
    password: Yup.string().required("Enter password").trim(),
  })

  const { register, handleSubmit, formState: { errors, }, } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: loginForm
  });

  const onSubmit = (values) => {
    dispatch(loginUser(values, props.history, redirectUrl));
  }

  const [passwordShow, setPasswordShow] = useState(false);
  const { error, loader } = useSelector(state => ({
    error: state.Login.error,
    loader: state.Login.loading,
  }));

  return (
    <React.Fragment>
      <DocumentTitle title={`Login`} />
      <div className="account-pages">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="text-center auth-logo">
                <img src={AppLogo} alt="Logo" />
              </div>
              <Card className="overflow-hidden auth-card rounded-0">
                <div className="bg-primary bg-soft background-auth-page">
                  <Row className="align-items-center auth-head">
                    <Col xs={7} className='auth-heading py-4'>
                      <div className="auth-heading-text">
                        <h2 className="f-size-24">Welcome Back!</h2>
                        <p className="f-size-12">Sign in to {campaignDetail?.campaignName || ""} AdminPanel</p>
                      </div>
                    </Col>

                  </Row>
                </div>
                <CardBody className="">
                  {/* <div className="d-inline-block">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt="Logo"
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div> */}
                  <div className="">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                      className="form-horizontal" >
                      <div className="mb-3">
                        <Label className="form-label f-size-14">Email Address<span>*</span></Label>
                        <input type="text" {...register("email")} placeholder={'email@address.com'} className={errors.email?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                        {errors.email?.message ? <p className={`invalid-feedback`} >{errors.email?.message}</p> : null}
                      </div>
                      <div className="mb-3">
                        <div className="show-password">
                          <Label className="form-label f-size-14">Password<span>*</span></Label>

                        </div>
                        <div className="show-icon">
                          <div className="input-group auth-pass-inputgroup">
                            <input type={passwordShow ? "text" : "password"} {...register("password")} placeholder="*******" className={errors.password?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                            {errors.password?.message ? <p className={`invalid-feedback`} >{errors.password?.message}</p> : null}

                          </div>
                          <div>  <button onClick={() => setPasswordShow(prevState => !prevState)} type="button" id="password-addon">
                            {passwordShow ? <i className="mdi mdi-eye-off"></i> : <i className="mdi mdi-eye"></i>}
                          </button></div>
                        </div>
                      </div>
                      {/* <div className="forgot-password d-flex justify-content-end">
                        <Link to="/forgot-password" className="f-size-14">
                          Forgot Password?
                        </Link>
                      </div> */}
                      {loader === true ? <div className="d-grid"><AuthLoader /></div> :
                        <div className="d-grid">
                          <button
                            className="btn btn-primary btn-block primary-button"
                            type="submit"
                          >
                            Login
                          </button>
                        </div>
                      }

                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center copy-right">
                <p>{campaignDetail?.campaignName || ""} @ {new Date().getFullYear()} All Rights Reserved</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(withTranslation()(Login));
Login.propTypes = {
  history: PropTypes.object,
};
