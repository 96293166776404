import { segmentCount } from "constants/SpinWheelSegment"
import React, { useState } from "react"
import { Card, Col, Container, Label, Row } from "reactstrap"
import SegmentForm from "./component/SegmentForm";
import SpinPreview from "./component/SpinPreview";

const CustomSpinWheel = ({ compaginId, managePrizeData, setOpenItem }) => {
  
  const [selectedSegment, setSelecteSegment] = useState();
  const [segmentsName, setSegmentsName] = useState([]);

  return (
    <React.Fragment>
      <div >
        <Container fluid className="p-0">
          <div className="custom-spin">
            <Card>
              <Row>
                <Col lg="6">
                  <div className="spinWheel-container">
                    <div className="">
                      <SpinPreview
                        selectedSegment={selectedSegment}
                        segmentsName={segmentsName}
                        managePrizeData={managePrizeData} />
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="spinWheel-container">
                    <div>
                      <SegmentForm
                        setSelecteSegment={setSelecteSegment}
                        segmentsName={segmentsName}
                        setSegmentsName={setSegmentsName}
                        compaginId={compaginId}
                        setOpenItem={setOpenItem}
                        managePrizeData={managePrizeData}
                      />
                    </div>
                  </div>

                </Col>
              </Row>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CustomSpinWheel
