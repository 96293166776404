import {
  CAMPAIGN_SETTINGS_UPDATE,
  CAMPAIGN_SETTINGS_UPDATE_FAIL,
  CAMPAIGN_SETTINGS_UPDATE_SUCCESS,
  GET_SETTINGS_FAIL,
  GET_SETTINGS_LOADING,
  GET_SETTINGS_SUCCESS,
  SETTINGS_UPDATE,
  SETTINGS_UPDATE_FAIL,
  SETTINGS_UPDATE_LOADING,
  SETTINGS_UPDATE_SUCCESS,
  GET_CAMPAIGN_DETAILS,
  GET_CAMPAIGN_DETAILS_FAIL,
  GET_CAMPAIGN_DETAILS_SUCCESS,
  UPDATE_CAMPAIGN_DETAILS,
  UPDATE_CAMPAIGN_DETAILS_SUCCESS,
  UPDATE_CAMPAIGN_DETAILS_FAIL,
  CAMPAIGN_OPTIONS,
  GET_BY_CAMPAIGN_ID_FAIL,
  GET_BY_CAMPAIGN_ID_SUCCESS,
  GET_BY_CAMPAIGN_ID
} from "./actionType"

//update settings
const initialState = {
  error: "",
  success: "",
  loading: false
}

export const SettingsUpdate = (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_UPDATE:
      state = {
        ...state,
      }
      break
    case SETTINGS_UPDATE_SUCCESS:

      state = { ...state, success: action.payload, loading: false }
      break
    case SETTINGS_UPDATE_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
    case SETTINGS_UPDATE_LOADING:
      state = {
        loading: action.payload
      }
  }
  return state
}

// Get settings
const initialState_GetSettings = {
  data: [],
  error: "",
  success: "",
  loading: false
}

export const GetSettings = (state = initialState_GetSettings, action) => {
  switch (action.type) {
    case GET_SETTINGS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }
    case GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_SETTINGS_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
  }
  return state
}

//campaign settings
const campaignsettingsinitialState = {
  error: "",
  success: "",
  loading: false
}

export const CampaignSettingsUpdate = (state = campaignsettingsinitialState, action) => {
  switch (action.type) {
    case CAMPAIGN_SETTINGS_UPDATE:
      state = {
        ...state,
      }
      break
    case CAMPAIGN_SETTINGS_UPDATE_SUCCESS:
      state = { ...state, success: action.payload, loading: false }
      break
    case CAMPAIGN_SETTINGS_UPDATE_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break
  }
  return state
}

//get campaign details
const getCampaignDetailsInitialState = {
  error: "",
  data: {},
  loading: false
}

export const GetCampaignDetails = (state = getCampaignDetailsInitialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGN_DETAILS:
      state = {
        error: "",
        data: "",
        loading: true
      }
      break
    case GET_CAMPAIGN_DETAILS_SUCCESS:
      state = { error: "", data: action.payload, loading: false }
      break
    case GET_CAMPAIGN_DETAILS_FAIL:
      state = { data: "", error: action.payload, loading: false }
      break
  }
  return state
}

//update campaign details
const updateCampaignDetailsInitialState = {
  error: "",
  data: {},
  loading: false
}

export const UpdateCampaignDetails = (state = updateCampaignDetailsInitialState, action) => {
  switch (action.type) {
    case UPDATE_CAMPAIGN_DETAILS:
      state = {
        error: "",
        data: "",
        loading: true
      }
      break
    case UPDATE_CAMPAIGN_DETAILS_SUCCESS:
      state = { error: "", data: action.payload, loading: false }
      break
    case UPDATE_CAMPAIGN_DETAILS_FAIL:
      state = { data: "", error: action.payload, loading: false }
      break
  }
  return state
}

//campaign options
const campaignOPtions = {
  data: []
}

export const CampaignOptions = (state = campaignOPtions, action = CAMPAIGN_OPTIONS) => {
  if (action.type === CAMPAIGN_OPTIONS) {
    state = {
      data: action.payload
    }
  }
  return state;
}

// Get CampaignId
const getCampaignIdInitialState = {
  error: "",
  success: "",
  loading: false
}

export const GetByCampaignId = (state = getCampaignIdInitialState, action) => {
  switch (action.type) {
    case GET_BY_CAMPAIGN_ID:
      return {
        ...state,
        loading: true,
      }
    case GET_BY_CAMPAIGN_ID_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false
      }

    case GET_BY_CAMPAIGN_ID_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
  }
  return state
}
