const base64ToFile = (base64String, filename, mimeType) => {
    // Remove data url prefix
    const base64Data = base64String.replace(/^data:\w+\/\w+;base64,/, '');

    // Convert to binary buffer
    const binaryString = atob(base64Data);
    const binaryData = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        binaryData[i] = binaryString.charCodeAt(i);
    }

    // Create Blob
    const blob = new Blob([binaryData], { type: mimeType });

    // Create File
    const file = new File([blob], filename, { type: mimeType });

    return file;
}

export default base64ToFile
