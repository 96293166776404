//Login
export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

//Reset Password
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"

// Forget password
export const FORGET_PASSWORD = "FORGET_PASSWORD"
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS"
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR"

//Forget password resend code
export const FORGET_PASSWORD_RESEND_CODE = "FORGET_PASSWORD_RESEND_CODE"
export const FORGET_PASSWORD_RESEND_CODE_SUCCESS = "FORGET_PASSWORD_RESEND_CODE_SUCCESS"
export const FORGET_PASSWORD_RESEND_CODE_ERROR = "FORGET_PASSWORD_RESEND_CODE_ERROR"

//Logout
export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"