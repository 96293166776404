import {
  call,
  put,
  takeEvery,
  takeLatest,
  all,
  fork,
} from "redux-saga/effects";
import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_RESEND_CODE,
  LOGIN_USER,
  LOGOUT_USER,
  RESET_PASSWORD,
} from "./actionTypes";
import {
  apiError,
  loginSuccess,
  userForgetPasswordError,
  userForgetPasswordSuccess,
  userResetPasswordError,
  userResetPasswordSuccess,
} from "./actions";
import toast from "react-hot-toast";
import {
  applicationLogin,
  forgetPwd,
  forgetPwdResendCode,
  resetPwd,
} from "helpers/backend_helper";
import jwt_decode from "jwt-decode";
import { clearToken, setToken } from "helpers/api_helper";
import { authInfoSuccess } from "store/authInfo/action";

function* loginUser({ payload: { user, history, redirectUrl } }) {
  try {
    const response = yield call(applicationLogin, {
      email: user.email,
      password: user.password,
    });
    if (response?.status?.toLowerCase() === "success") {
      toast.success(response.message);

      localStorage.setItem("authUser", response?.access_token);
      // localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response.message));
      const decoded = jwt_decode(response?.access_token);
      yield put(authInfoSuccess(decoded));

      setToken();

      if (redirectUrl) {
        history.push(`${redirectUrl}`);
        sessionStorage.removeItem("redirectUrl");
      } else {
        if (decoded?.role?.toLowerCase() === "staff") {
          history.push("/print-qr");
        } else if (decoded?.role?.toLowerCase() === "management") {
          history.push("/manageqr");
        }
      }
    } else if (response?.status?.toLowerCase() === "error") {
      toast.error(response.message);
      yield put(loginSuccess(response.message));
    } else {
      toast.error(response.message);
      yield put(apiError(response.message));
    }
  } catch (error) {
    toast.error(error.response.data.message, { duration: 6000 });
    yield put(apiError(error.response.data.message));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("jwt");
    localStorage.removeItem("authUser");
    clearToken();
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* AuthSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

//Forgot Password
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(forgetPwd, {
      email: user.email,
    });
    if (response.status?.toLowerCase() === "success") {
      yield put(userForgetPasswordSuccess(response));
      toast.success(response.message);
      history.push(`/reset-password/${user.email}`);
    } else {
      yield put(userForgetPasswordError(response));
      toast.error(response.message);
    }
  } catch (error) {
    toast.error(error.response.data.message, { duration: 6000 });
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}
export function* ForgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

//Forget password resend code
function* forgetUserResendCode({ payload: { user, history } }) {
  try {
    const response = yield call(forgetPwdResendCode, {
      email: user.email,
    });
    if (response.status.toLowerCase() === "success") {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    toast.error(error.response.data.message, { duration: 6000 });
  }
}

export function* watchUserPasswordForgetResendCode() {
  yield takeEvery(FORGET_PASSWORD_RESEND_CODE, forgetUserResendCode);
}

export function* ForgetPasswordResendCodeSaga() {
  yield all([fork(watchUserPasswordForgetResendCode)]);
}

// Reset Password
function* resetUser({ payload: { users, history } }) {
  try {
    const response = yield call(resetPwd, {
      email: users.email,
      password: users.password,
      confPwd: users.confPwd,
      verificationToken: users.verificationToken,
    });
    if (response.status.toLowerCase() === "success") {
      toast.success(response.message);
      yield put(userResetPasswordSuccess(response.message));
      history.push("/login");
    } else {
      toast.error(response.message);
      yield put(userResetPasswordError(response.message));
    }
  } catch (error) {
    toast.error(error.response.data.message, { duration: 6000 });
    yield put(userResetPasswordError(error.response.data.message));
  }
}

export function* watchUserPasswordReset() {
  yield takeEvery(RESET_PASSWORD, resetUser);
}

export function* ResetPasswordSaga() {
  yield all([fork(watchUserPasswordReset)]);
}
