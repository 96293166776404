import React, { useEffect, useMemo, useState } from "react";
import { Card, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledTooltip } from "reactstrap";
import serachIcon from "../../assets/images/svg/manageQr/ic-search.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAllWinners, redeemPrizeAction } from "store/winners/action";
import TableContainer from "components/Common/TableContainer";
import * as moment from "moment-timezone";
import WebCam from "../../components/Common/Modals/WebCam";
import PhotoView from "../../components/Common/Modals/PhotoView";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import SecountryBtn from "components/Common/Buttons/SecountryBtn";
import PageLoader from "components/Common/PageLoader";
import TableSkeleton from "components/Common/TableSkeleton";
import Pagination from "../../components/Common/pagination/Pagination";
import download from "../../assets/images/svgicons/download.svg";
import csvDownload from "constants/csvDownload";
import axios from "axios";
import { winnersDetaIls } from "helpers/backend_helper";
import toast from "react-hot-toast";
import DatePicker from "react-multi-date-picker";
import resetIcon from "../../assets/images/svgicons/reset-icon.svg";
import calendar from "../../assets/images/svgicons/calendar.svg";
import resetIconWhite from "../../assets/images/svgicons/reset-icon-white.svg";
import DocumentTitle from "components/DocumentTitle";
import { readFile, readFileSuccess } from "store/actions";
import base64ToFile from "constants/base64ToFile";
import getMimeType from "constants/getMimeType";
import Select from "react-select";

export default function Winners() {
  const dispatch = useDispatch();

  const winnersList = useSelector((state) => state?.GetallWinners?.data?.qrlist); // all winers list api complete
  const winnersListLoader = useSelector((state) => state?.GetallWinners?.loading); // all winers list api complete
  const allWinnerCount = useSelector((state) => state?.GetallWinners?.data?.totalCount);
  const readAdsImage = useSelector((state) => state?.ReadAdvertisementImage?.data)
  const readAdsImageloader = useSelector((state) => state?.ReadAdvertisementImage?.loading)
  const campaignOptions = useSelector((state) => state?.CampaignOptions?.data);

  const [inputSearch, setInputSearch] = useState("");
  const [showData, setShowData] = useState([]);
  const [openWebCam, setOpenWebCam] = useState(false);
  const [imageOpen, setImageOpen] = useState(false);
  const [imageSource, setImageSource] = useState();
  const [qrid, setId] = useState(null);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState({
    field: "spinDateAndTime",
    sort: false,
    value: "descending",
  });
  const [openDrop, setOpenDrop] = useState(false);
  const [fromValue, setFromValue] = useState();
  const [toValue, setToValue] = useState();
  const [active, setActive] = useState(false);
  const [campaignDropValue, setCampaignDropValue] = useState(null)

  const getWinners = () => {
    const fromdate = fromValue ? moment(fromValue?.toDate()).startOf('day').tz('Asia/kolkata').format() : "";
    const todate = toValue ? moment(toValue?.toDate()).endOf('day').tz('Asia/kolkata').format() : "";

    let dateFilter = {
      from: fromdate,
      to: todate,
      field: 'spinDateAndTime'
    }
    dispatch(getAllWinners({ data: { search: inputSearch, sort: sortBy, dateFilter: dateFilter, campaign: campaignDropValue?.value }, currentPage, pageSize, dispatch: dispatch, readFile: readFile }));
  };

  useEffect(() => {
    getWinners();
  }, [currentPage, inputSearch, sortBy, fromValue, toValue, campaignDropValue]);

  const handleRedeem = () => {
    setOpenWebCam(true);
  };

  const resetRedeemModel = () => {
    getWinners();
    setOpenWebCam(false);
    setId(null);
  };

  const captureFunction = (image, qrId) => {
    if (image && qrId) {
      const data = {};
      data["id"] = qrId;
      data["image"] = base64ToFile(image, 'user_img', 'image/jpeg');
      dispatch(redeemPrizeAction(data, resetRedeemModel));
    }
    return;
  };

  const handeView = (imageSrc) => {
    setImageSource(imageSrc);
    setImageOpen(true);
  };

  useEffect(() => {
    if (inputSearch) {
      searchFilter();
    } else setShowData(winnersList || []);
  }, [inputSearch, winnersList]);

  const searchFilter = () => {
    if (inputSearch) {
      const searchValue = inputSearch?.toLowerCase();
      const filterData = [...winnersList];
      const showTableData = filterData.filter((item) => {
        return (
          item?.email?.toLowerCase().includes(searchValue) ||
          item?.qrCode?.toLowerCase().includes(searchValue)
        );
      });
      setShowData(showTableData);
    }
  };

  const Header = useMemo(
    () => [
      {
        Header: `S.No`,
        Cell: (cellProps) => {
          return (
            <div>
              <p className="m-0">{cellProps?.row?.index + 1}</p>
            </div>
          );
        },
      },
      {
        Header: `Campaign Name`,
        Cell: (cellProps) => {
          return (
            <div>
              <p className="m-0 td">{cellProps?.row?.original?.campaign}</p>
            </div>
          );
        },
        isSorting: true,
        id: 'campaign'
      },
      {
        Header: `Email`,
        Cell: (cellProps) => {
          return (
            <div>
              <p className="m-0 td">{cellProps?.row?.original?.email}</p>
            </div>
          );
        },
        isSorting: true,
        id: 'email'
      },
      {
        Header: `QR Code`,
        Cell: (cellProps) => {
          return (
            <div>
              <p className="m-0 td text-uppercase">
                {cellProps?.row?.original?.qrCode}
              </p>
            </div>
          );
        },
        isSorting: true,
        id: 'qrCode'
      },
      {
        isSorting: true,
        id: 'productId',
        Header: `Prize`,
        Cell: (cellProps) => {
          return (
            <div>
              <p className="m-0">{cellProps?.row?.original?.productId || "-"}</p>
            </div>
          );
        },
      },
      {
        isSorting: true,
        id: 'spinDateAndTime',
        Header: `Lucky Draw Time`,
        Cell: (cellProps) => {
          return (
            <div>
              <p className="m-0">
                {cellProps.row.original?.spinDateAndTime ? (
                  <>
                    <p className="m-0 td">
                      {moment(cellProps.row.original?.spinDateAndTime).format(
                        "LT"
                      )}
                    </p>
                    <span className="date">
                      {" "}
                      {moment(cellProps.row.original?.spinDateAndTime).format(
                        "ll"
                      )}
                    </span>
                  </>
                ) : (
                  "-"
                )}
              </p>
            </div>
          );
        },
      },
      {
        Header: " ",
        Cell: (cellProps) => {
          return (
            <div style={{ display: "flex" }}>
              <>
                {cellProps.row.original?.redeemStatus ? (
                  <PrimaryButton
                    className="disabled"
                    btnName={"Redeemed"}
                    disabled={true}
                  />
                ) : (
                  <PrimaryButton
                    btnName={"Redeem"}
                    onClick={() => {
                      setId(cellProps.row.original?._id);
                      handleRedeem();
                    }}
                  />
                )}
              </>
            </div>
          );
        },
      },
      {
        isSorting: true,
        id: 'redeemedTime',
        Header: `Redeem Time`,
        Cell: (cellProps) => {
          return (
            <>
              {!cellProps.row.original?.redeemedTime ? (
                <div>-</div>
              ) : (
                <div style={{ display: "flex" }}>
                  <div className="timeZone mx-0">
                    <p className="m-0 td">
                      {cellProps.row.original?.redeemedTime &&
                        moment(cellProps.row.original?.redeemedTime).format(
                          "LT"
                        )}
                    </p>
                    <span className="date">
                      {" "}
                      {cellProps.row.original?.redeemedTime &&
                        moment(cellProps.row.original?.redeemedTime).format(
                          "ll"
                        )}
                    </span>
                  </div>
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: `  `,
        Cell: (cellProps) => {
          const path = readAdsImage[`${cellProps?.row?.original?.redeemedPerson}`]

          return (
            <div style={{ display: "flex" }}>
              {cellProps.row.original?.redeemedPerson ? (
                <SecountryBtn
                  btnName={"View Photo"}
                  onClick={() =>
                    handeView(path ? `data:${getMimeType(path)};base64,${path}` : "")
                  }
                />
              ) : (
                <SecountryBtn
                  className="disabled"
                  btnName={"View Photo"}
                  disabled={true}
                />
              )}
            </div>
          );
        },
      },
    ],
    [winnersList, readAdsImage]
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDownload = async ({ data = {} }) => {
    try {
      const response = await winnersDetaIls(data, 0, 0);
      if (response?.status?.toLowerCase() === "success") {
        const newData = response?.qrlist?.length > 0 && response?.qrlist?.map((item) => {
          return {
            campaign:item?.campaign,
            email: item?.email,
            qr: item?.qrCode,
            prize: item?.productId,
            spinDateAndTime: item?.spinDateAndTime,
            redeemed: item?.redeemStatus,
            redeemedDateAndTime: item?.redeemedTime || "-"
          }
        })
        csvDownload({ jsonData: newData || [] });
      } else {
        toast.error("Something went wrong, please try again later.");
      }
    } catch (error) {
      toast.error("Something went wrong, please try again later.");
    }
  }

  const reestFilter = () => {
    setFromValue();
    setToValue();
    setInputSearch("");
  }

  const handleMouseOver = () => {
    setActive(true);
  };

  const handleMouseOut = () => {
    setActive(false);
  };

  const handleCampaignSelect = (e) => {
    setCampaignDropValue(e);
    reestFilter();
  }

  useEffect(() => {
    if (campaignOptions?.length > 0) {
      const result = campaignOptions.filter((item) => item?.status === 'ACTIVE')
      if (result.length > 0) {
        setCampaignDropValue(result[0])
        reestFilter()
      }
    }
  }, [campaignOptions])

  return (
    <React.Fragment>
      <DocumentTitle title={`Winners`} />
      <div className="page-content winners-list">
        <Container fluid>
          <div className="page-title">Winners</div>
          <div className="select-drop">
            <Select
              className='select-menu'
              value={campaignDropValue}
              onChange={(e) => handleCampaignSelect(e)}
              options={[{ label: "All", value: "", status: "" }, ...campaignOptions]}
            />
          </div>
          <Card>
            <div className="actions">
              <div className="filters">
                <div className="searchField">
                  <img
                    className="searchIcon"
                    src={serachIcon}
                    width={16}
                    height={16}
                  ></img>
                  <input
                    className="searchInput"
                    type="text"
                    value={inputSearch}
                    onInput={(e) => setInputSearch(e.target.value)}
                    placeholder="Search"
                  />
                </div>
                <div className="date-picker from-date">
                  <img className="calendar-icon" src={calendar} alt="calendar" />
                  <DatePicker
                    inputClass="date-input rmdp-input"
                    value={fromValue}
                    onChange={setFromValue}
                    format="DD/MM/YYYY"
                    maxDate={new Date()}
                    placeholder="From date"
                  />
                </div>
                <div className="date-picker to-date">
                  <img className="calendar-icon" src={calendar} alt="calendar" />
                  <DatePicker
                    inputClass="date-input rmdp-input"
                    value={toValue}
                    onChange={setToValue}
                    format="DD/MM/YYYY"
                    minDate={fromValue}
                    maxDate={new Date()}
                    placeholder="To date"
                  />
                </div>
                <div className="reset"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <PrimaryButton btnName={"Reset"} disabled={(inputSearch || fromValue || toValue) ? false : true} onClick={() => reestFilter()} showIcon={true} btnIcon={active ? resetIcon : resetIconWhite} />
                </div>
              </div>
              <div className="download">
                <div className="download-btn">
                  <button className="download-all" onClick={() => handleDownload({ data: {} })} ><img src={download} width={25} alt="download" /> Download all</button>
                </div>
                <div className="download-btn">
                  <button className="dropdown-toggle" onClick={() => {
                    const fromdate = fromValue ? moment(fromValue?.toDate()).startOf('day').tz('Asia/kolkata').format() : "";
                    const todate = toValue ? moment(toValue?.toDate()).endOf('day').tz('Asia/kolkata').format() : "";
                    const data = {
                      dateFilter: {
                        from: fromdate,
                        to: todate,
                        field: 'spinDateAndTime'
                      },
                      search: inputSearch,
                      sort: sortBy
                    };
                    handleDownload({ data: data })
                  }} ><img src={download} width={25} alt="download" /> Download with filter</button>
                </div>
                {/* <div id="reset" className="cursor-pointer" onClick={() => reestFilter()}>
                  <img src={resetIcon} alt="reset" />
                  <UncontrolledTooltip target={`reset`}>Reset
                  </UncontrolledTooltip>
                </div> */}
              </div>
            </div>
          </Card>
          <Card>
            {!winnersListLoader && !readAdsImageloader && showData ? (
              <div className="p-4">
                <TableContainer
                  columns={Header}
                  data={showData}
                  isGlobalFilter={false}
                  isAddOptions={false}
                  customPageSize={10}
                  handleOrderClicks={() => null}
                  iSPageOptions={true}
                  handlePageChange={handlePageChange}
                  className="custom-header-css"
                  btnType="button"
                  editID={""}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                />
                <div className="d-flex justify-content-center"> {
                  allWinnerCount > pageSize &&
                  <Pagination
                    className="pagination-bar custom-pagination"
                    currentPage={currentPage}
                    totalCount={allWinnerCount}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                  />
                }
                </div>
              </div>

            ) : (
              <TableSkeleton datacount={7} listcount={15} />
            )}
          </Card>
        </Container>
        <WebCam
          setImage={captureFunction}
          qrid={qrid}
          setShowModal={setOpenWebCam}
          showModel={openWebCam}
        />
        <PhotoView
          src={imageSource}
          showModal={imageOpen}
          setShowModal={setImageOpen}
        />
      </div>
    </React.Fragment >
  );
}
