export const fontSizeList = [
    {
        "label": "8",
        "value": "8"
    },
    {
        "label": "9",
        "value": "9"
    },
    {
        "label": "10",
        "value": "10"
    },
    {
        "label": "11",
        "value": "11"
    },
    {
        "label": "12",
        "value": "12"
    },
    {
        "label": "14",
        "value": "14"
    },
    {
        "label": "16",
        "value": "16"
    },
    {
        "label": "18",
        "value": "18"
    },
    {
        "label": "20",
        "value": "20"
    },
    {
        "label": "22",
        "value": "22"
    },
    {
        "label": "24",
        "value": "24"
    },
    {
        "label": "26",
        "value": "26"
    },
    {
        "label": "28",
        "value": "28"
    },
    {
        "label": "36",
        "value": "36"
    },
    {
        "label": "48",
        "value": "48"
    },
    {
        "label": "72",
        "value": "72"
    },
    {
        "label": "74",
        "value": "74"
    },
    {
        "label": "76",
        "value": "76"
    },
    {
        "label": "78",
        "value": "78"
    },
    {
        "label": "80",
        "value": "80"
    },
   
];
export const fontFamilyList = [
    {
        "label": "Arial",
        "value": "Arial"
    },
    {
        "label": "Verdana",
        "value": "Verdana"
    },
    {
        "label": "Times New Roman",
        "value": "Times New Roman"
    },
    {
        "label": "Algerian",
        "value": "Algerian"
    },
    {
        "label": "Arial Narrow",
        "value": "Arial Narrow"
    },
    {
        "label": "Helvetica",
        "value": "Helvetica"
    },
    {
        "label": "Tahoma",
        "value": "Tahoma"
    },
    {
        "label": "Impact",
        "value": "Impact"
    },
]