import React from 'react'
import { useSelector } from 'react-redux';

const DocumentTitle = ({ title }) => {

    const name = useSelector(state => state.GetCampaignDetails.data?.data);

    document.title = `${title} | ${(name && name?.length > 0) ? name[0]?.campaignName : ""} AdminPanel`;
    return (
        <></>
    )
}

export default DocumentTitle