import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card, CardBody, Label } from 'reactstrap';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-multi-date-picker';
import { getByCampaignId, getCampaignDetails, updateCampaignDetails } from 'store/actions';
import toast from 'react-hot-toast';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';

const CampaignDetails = ({ compaginId, setAddedCampaign, setOpenItem }) => {

    const dispatch = useDispatch();


    const campaignData = useSelector(state => state.GetCampaignDetails?.data?.data);
    const campaignIdData = useSelector(state => state.GetByCampaignId?.data?.data);
    

    const loginForm = {
        campaignName: "",
        location: ""
    }

    const campaignSchema = Yup.object().shape({
        campaignName: Yup.string().required("Campaign name is required").trim(),
        location: Yup.string().required("Address is required").trim(),
    })

    const { register, handleSubmit, formState: { errors }, reset, setValue, trigger, watch } = useForm({
        resolver: yupResolver(campaignSchema),
        defaultValues: loginForm
    });

    const afterSuccess = (id) => {
        setAddedCampaign(id);
        dispatch(getCampaignDetails());
        setOpenItem("2");
    }

    const validateCurrentStep = async () => {
        const isValid = await trigger();
        return isValid;
    };

    const moveToNextStep = async () => {
        const isCurrentStepValid = await validateCurrentStep();
        if (isCurrentStepValid) {
            // Move to the next step
            // Your logic for moving to the next step goes here
        }
    };
    useEffect(() => {
        if (compaginId && compaginId) {
            dispatch(getByCampaignId(compaginId));
        } else {
            reset(loginForm);
        }
        return () => {
            dispatch(getByCampaignId(null)); 
        };
    }, [compaginId])

    const onSubmit = (values) => {
        if (compaginId) {
            dispatch(updateCampaignDetails(values, campaignIdData?._id, "",afterSuccess))
        } else {
            dispatch(updateCampaignDetails(values, "", afterSuccess));
        }
     
    }

    useEffect(() => {
        if (campaignIdData && campaignIdData ) {
            const { location, campaignName } = campaignIdData;
            reset({
                campaignName: campaignName || "",
                location: location || ""
            })
        }
    }, [campaignIdData])

    return (
        <>
            <Card className="settings-card">
                <CardBody>
                    <div className="p-4">
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off"
                            className="form-horizontal" >
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="mb-3">
                                        <Label className="form-label f-size-14">Campaign name<span>*</span></Label>
                                        <input type="text" {...register("campaignName")} className={`form-control ${errors.campaignName?.message ? 'is-invalid' : ''}`}
                                            disabled={compaginId ? true : false} />
                                        {errors.campaignName?.message ? <p className={`invalid-feedback`} >{errors.campaignName?.message}</p> : null}
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="mb-3" style={{ height: "100%" }}>
                                        <Label className="form-label f-size-14">Address<span>*</span></Label>
                                        <textarea rows={5} type="text" {...register("location")} className={errors.location?.message ? 'form-control is-invalid' : '' + 'form-control'} />
                                        {errors.location?.message ? <p className={`invalid-feedback`} >{errors.location?.message}</p> : null}
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3 d-flex gap-3'>
                                <PrimaryButton btnName={'Save'}  />
                                 {/* <SecountryBtn btnType={`button`} btnName={"Next"} onClick={() => setOpenItem("2")} /> */}
                            </div>
                        </form>
                    </div>
                </CardBody>
            </Card>
        </>
    )
}

export default CampaignDetails