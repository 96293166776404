import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { WithAxios } from "helpers/WithAxios";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  role: role,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const accessToken = localStorage.getItem("authUser");
      const decoded = accessToken ? jwt_decode(accessToken) : {};
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        const url = window.location.pathname;
        if (url && !url.includes(["/login"])) {
          window.sessionStorage.setItem("redirectUrl", url);
        }
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      if (isAuthProtected && role?.includes(decoded?.role) === false) {
        return (
          <Redirect
            to={{ pathname: "/unauthorized", state: { from: props.location } }}
          />
        );
      }

      return (
        <WithAxios>
          <Layout>
            <Component {...props} />
          </Layout>
        </WithAxios>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
