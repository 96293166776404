import { all, fork } from "redux-saga/effects"
import LayoutSaga from "./layout/saga"
import { AuthSaga, ResetPasswordSaga, ForgetPasswordResendCodeSaga, ForgetPasswordSaga } from "./auth/sagas"
import { AdminSaga, AddAdminSaga, GetAdminByIdSaga, StatusSaga } from './admin/sagas'
import { UpdateProfileImageSaga, UpdateProfileSaga, LoginPasswordChangeSaga, getProfileImagePathSaga } from './profile/saga'
import { AddQrcodesSaga, QrcodesSaga, GetQrcodesIdSaga, SetStatusQrcodesSaga, issueQrcodesSaga, SendQRCodeSaga } from "./qrcodes/sagas"
import { AddUserSaga, GetUserByIdSaga, ManageUsersSaga, UserStatusSaga } from "./users/sagas"
import { GetSettingsSaga, SettingsUpdateSaga, CampaignSettingsUpdateSaga, GetCampaignDetailsSaga, UpdateCampaignDetailsSaga, GetByCampaignIdSaga } from "./settings/saga"
import { QrSaga } from "./manageQr/saga"
import { AdminRoleSaga } from "./roles/sagas"
import { FreezePrizeSaga, ManagePrizeSaga, addPrizeSaga, changePrizeStatusSaga, updatePrizeImageSaga, updatePrizeSaga, ManageSpinnerSaga, AddSegmentsSaga, PrizePublishSaga } from "./managePrize/saga"
import { RedeemPrizeSaga, WinnersSaga } from "./winners/saga"
import { DeleteAdIdSaga, ManageadsSaga, ReadAdvertisementImageSaga, addAdvertisementImageSaga, adsStatusSaga } from "./manageads/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(ForgetPasswordSaga),
    fork(LayoutSaga),
    fork(AdminSaga),
    fork(AddAdminSaga),
    fork(GetAdminByIdSaga),
    fork(StatusSaga),
    fork(ResetPasswordSaga),
    fork(ForgetPasswordResendCodeSaga),
    fork(UpdateProfileSaga),
    fork(LoginPasswordChangeSaga),
    fork(UpdateProfileImageSaga),
    fork(getProfileImagePathSaga),
    fork(QrcodesSaga),
    fork(AddQrcodesSaga),
    fork(GetQrcodesIdSaga),
    fork(SetStatusQrcodesSaga),
    fork(ManageUsersSaga),
    fork(AddUserSaga),
    fork(GetUserByIdSaga),
    fork(UserStatusSaga),
    fork(SettingsUpdateSaga),
    fork(GetSettingsSaga),
    fork(CampaignSettingsUpdateSaga),
    fork(AdminRoleSaga),
    fork(QrSaga),
    fork(issueQrcodesSaga),
    fork(ManagePrizeSaga),
    fork(updatePrizeSaga),
    fork(addPrizeSaga),
    fork(updatePrizeImageSaga),
    fork(changePrizeStatusSaga),
    fork(ManageSpinnerSaga),
    fork(WinnersSaga),
    fork(RedeemPrizeSaga),
    fork(FreezePrizeSaga),
    fork(GetCampaignDetailsSaga),
    fork(UpdateCampaignDetailsSaga),
    fork(SendQRCodeSaga),
    fork(ManageadsSaga),
    fork(adsStatusSaga),
    fork(DeleteAdIdSaga),
    fork(addAdvertisementImageSaga),
    fork(ReadAdvertisementImageSaga),
    fork(AddSegmentsSaga),
    fork(GetByCampaignIdSaga),
    fork(PrizePublishSaga),


  ])
}