import React from "react"

const AuthLoader = ({ btnStyle }) => {
    return (
        <div className="authLoader" style={btnStyle ? btnStyle : {}}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default AuthLoader
