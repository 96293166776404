
import {
    GET_ALL_QRCODES,
    GET_ALL_QRCODES_SUCCESS,
    GET_ALL_QRCODES_FAIL,
    GET_QRCODES_BY_ID,
    GET_QRCODES_BY_ID_SUCCESS,
    GET_QRCODES_BY_ID_FAIL,
    GENERATE_QRCODES,
    SET_STATUS_QRCODES,
    SET_STATUS_QRCODES_SUCCESS,
    SET_STATUS_QRCODES_FAIL,
    GENERATE_QRCODES_SUCCESS,
    GENERATE_QRCODES_FAIL,
    QRCODES_LOADING,
    DEL_QRCODES,
    DEL_QRCODES_SUCCESS,
    DEL_QRCODES__FAIL,
    ISSUE_QRCODES,
    ISSUE_QRCODES_SUCCESS,
    ISSUE_QRCODES__FAIL,
    CONFIRM_PRINT_QR,
    SEND_QRCODE,
    SEND_QRCODE_SUCCESS,
    SEND_QRCODE_FAIL
} from "./actionTypes";

//Get Qrcodes
export const getAllQrcodes = () => ({
    type: GET_ALL_QRCODES,
});
export const getAllQrcodesSuccess = qrcodes => ({
    type: GET_ALL_QRCODES_SUCCESS,
    payload: qrcodes,
});
export const getAllQrcodesFail = error => ({
    type: GET_ALL_QRCODES_FAIL,
    payload: error,
});

//get qrcodes by ID
export const getQrcodesById = id => ({
    type: GET_QRCODES_BY_ID,
    payload: id,
})
export const getQrcodesByIdSuccess = qrcodes => ({
    type: GET_QRCODES_BY_ID_SUCCESS,
    payload: qrcodes,
})
export const getQrcodesIdFail = error => ({
    type: GET_QRCODES_BY_ID_FAIL,
    payload: error,
})

//Add Qrcodes
export const GenerateNewQrcodes = (count) => {
    return {
        type: GENERATE_QRCODES,
        payload: { count },
    }
}
export const addNewQrcodesSuccess = qrcodes => {
    return {
        type: GENERATE_QRCODES_SUCCESS,
        payload: qrcodes,
    }
}
export const addNewQrcodesError = error => {
    return {
        type: GENERATE_QRCODES_FAIL,
        payload: error,
    }
}
export const qrcodesLoading = (loading) => {
    return {
        type: QRCODES_LOADING,
        payload: loading,
    }
}

export const SetQrcodesStatus = (Qrnumbers) => {
    return {
        type: SET_STATUS_QRCODES,
        payload: { Qrnumbers },
    }
}
export const setStatusQrcodesSuccess = qrcodes => {
    return {
        type: SET_STATUS_QRCODES_SUCCESS,
        payload: qrcodes,
    }
}
export const setStatusQrcodesError = error => {
    return {
        type: SET_STATUS_QRCODES_FAIL,
        payload: error,
    }
}

//Delete Qrcodes
export const DeleteQrcodes = (id, history) => {
    return {
        type: DEL_QRCODES,
        payload: { id, history },
    }
}
export const DeleteQrcodesSuccess = qrcodes => {
    return {
        type: DEL_QRCODES_SUCCESS,
        payload: qrcodes,
    }
}
export const DeleteQrcodesError = error => {
    return {
        type: DEL_QRCODES__FAIL,
        payload: error,
    }
}
//issue Qrcodes
export const issueQrcodes = (count, data) => {
    return {
        type: ISSUE_QRCODES,
        payload: { count: count, data },
    }
}
export const issueQrcodesSuccess = qrcodes => {
    return {
        type: ISSUE_QRCODES_SUCCESS,
        payload: qrcodes,
    }
}
export const issueQrcodesError = error => {
    return {
        type: ISSUE_QRCODES__FAIL,
        payload: error,
    }
}
export const printConfirm = data => {
    return {
        type: CONFIRM_PRINT_QR,
        payload: data,
    }
}

//Send OR Code

export const sendQrcodes = (data, handleClear) => {
    return {
        type: SEND_QRCODE,
        payload: { data, handleClear },
    }
}
export const sendQrcodesSuccess = data => {
    return {
        type: SEND_QRCODE_SUCCESS,
        payload: data,
    }
}
export const sendQrcodesError = error => {
    return {
        type: SEND_QRCODE_FAIL,
        payload: error,
    }
}