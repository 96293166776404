import DocumentTitle from 'components/DocumentTitle';
import CampaignDetails from 'pages/ManagePrize/component/CampaignDetails';
import React, { useEffect, useState } from 'react';
import { Card, Container, Label } from 'reactstrap';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import CustomSpinWheel from './CustomSpinWheel';
import ManagePrize from './ManagePrize';
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import { useSelector } from 'react-redux';
import Select from "react-select";
import toast from "react-hot-toast";

const ManageCampaign = () => {
    const [openItem, setOpenItem] = useState('1');
    const [compaginId, setCompaginId] = useState('');
    const [managePrizeData, setManagePrizeData] = useState('');
    const [activeCampaign, setActiveCampaign] = useState();
    const [addedCampaign, setAddedCampaign] = useState();
    const [openSecondAccordion, setOpenSecondAccordion] = useState(false);

    const campaignData = useSelector(state => state.GetCampaignDetails?.data?.data);
    const campaignOptions = useSelector((state) => state?.CampaignOptions?.data);
    const campaignIdData = useSelector(state => state.GetByCampaignId?.data?.data);


    const toggle = (id) => {
        if (openItem === id) {
            setOpenItem();
        } else {
            setOpenItem(id);
        }
    };
    const openFirstItem = () => {
        setCompaginId('');
        setManagePrizeData();
        setActiveCampaign([]);
        setOpenItem();
        setOpenItem("1")
    };

    const openAllItems = () => {
        const newIsOpenState = {};
        Object.keys(isOpen).forEach(key => {
            newIsOpenState[key] = true;
        });
        setOpenItem(newIsOpenState);
    };

    const changeCampaignId = (data) => {
        setCompaginId(data?.value);
        setActiveCampaign(data);
        setOpenItem();
        setOpenItem("1")    
    };

    const filterCampaign = (field, value) => {
        const campaignActive = campaignOptions.filter((item) => item[field] === value);
        if (campaignActive?.length > 0) {
            setActiveCampaign(campaignActive[0]);
            setCompaginId(campaignActive[0]?.value);
        } else {
            setActiveCampaign();
        }
    }

    useEffect(() => {
        if (campaignOptions && campaignOptions?.length > 0) {
            if (addedCampaign) {
                filterCampaign("value", addedCampaign);
            } else {
                filterCampaign("status", "ACTIVE");
            }
        }
    }, [campaignOptions, addedCampaign])

    const CustomSpinWheelAccordionClick = () => {
        if (!compaginId) {
            toast.error("Please complete Campaign details");
        } else {
            setOpenSecondAccordion(!openSecondAccordion);
        }
    };

    const ManagePrizeAccordionClick = () => {
        if (managePrizeData?.length === 0) {
            toast.error("Please complete Campaign wheel segment");
        } else {
            setOpenItem("3");
        }
    };

    useEffect(() => {
        setManagePrizeData('');
    }, [activeCampaign]);

    return (
        <React.Fragment>
            <DocumentTitle title={`Manage Campaign`} />
            <div className="page-content ">
                <Container fluid>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="page-title">Manage Campaign</div>
                    </div>
                    <Card className='campaign-list'>
                        <div className='compain-drop'>
                            <Label>Select campaign</Label>
                            <Select
                                className='select-menu'
                                value={activeCampaign}
                                placeholder="Select campaign"
                                onChange={(e) => { changeCampaignId(e); }}
                                options={campaignOptions}
                            />
                        </div>
                        <div>
                            <PrimaryButton btnName={'Add New'} onClick={openFirstItem} />
                        </div>
                    </Card>
                    <Card>
                        <div className='p-4 manage-campaign'>
                            <Accordion open={openItem} toggle={toggle}>
                                <AccordionItem>
                                    <AccordionHeader targetId="1">Campaign details</AccordionHeader>
                                    <AccordionBody accordionId="1">
                                        <CampaignDetails compaginId={compaginId} setAddedCampaign={setAddedCampaign} setOpenItem={setOpenItem} />
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem onClick={CustomSpinWheelAccordionClick}>
                                    <AccordionHeader targetId="2" className={!compaginId || !campaignIdData?.campaignName && managePrizeData?.length === 0 ? 'disabled-accordion' : ''}>Campaign wheel segment</AccordionHeader>
                                    <AccordionBody accordionId="2">
                                        <CustomSpinWheel compaginId={compaginId} managePrizeData={managePrizeData} setOpenItem={setOpenItem} />
                                    </AccordionBody>
                                </AccordionItem>
                                <AccordionItem onClick={() => ManagePrizeAccordionClick()}>
                                    <AccordionHeader targetId="3" className={managePrizeData?.length === 0 ? 'disabled-accordion' : ''}>Manage prize</AccordionHeader>
                                    <AccordionBody accordionId="3">
                                        <ManagePrize compaginId={compaginId} setManagePrizeData={setManagePrizeData} currentCampaignData={activeCampaign} />
                                    </AccordionBody>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageCampaign;