import {
    GET_ADMIN_ROLES,
    GET_ADMIN_ROLES_SUCCESS,
    GET_ADMIN_ROLES_FAIL,
    GET_ADMIN_ROLETYPE,
    GET_ADMIN_ROLETYPE_SUCCESS,
    GET_ADMIN_ROLETYPE_FAIL
} from "./actionsType"

//Get Admin Role
export const getAdminRoles = () => ({
    type: GET_ADMIN_ROLES
});
export const getAdminRolesSuccess = roles => ({
    type: GET_ADMIN_ROLES_SUCCESS,
    payload: roles
});
export const getAdminRolesFail = (error) => ({
    type: GET_ADMIN_ROLES_FAIL,
    payload: error
});

//Get Admin Role Type
export const getAdminRoleType = (type) => ({
    type: GET_ADMIN_ROLETYPE,
    payload: type,
});
export const getAdminRoleTypeSuccess = roles => ({
    type: GET_ADMIN_ROLETYPE_SUCCESS,
    payload: roles
})
export const getAdminRoleTypeFail = (error) => ({
    type: GET_ADMIN_ROLETYPE_FAIL,
    payload: error
})