import React, { useState, useRef } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import PrimaryButton from 'components/Common/Buttons/PrimaryButton';
import SecountryBtn from 'components/Common/Buttons/SecountryBtn';
import { useDispatch, useSelector } from 'react-redux';
import { sendQrcodes } from 'store/actions';
import AuthLoader from 'components/Common/AuthLoader';
import minusIcon from "../../../assets/images/svg/printQr/ic-minus.svg";
import plusIcon from "../../../assets/images/svg/printQr/ic-plus.svg";
import IntlTelInput from 'react-intl-tel-input';


const SendQr = (props) => {
    const { eventData } = props;

    const dispatch = useDispatch();

    const [isInputEnabled, setIsInputEnabled] = useState('whatsapp');
    const [qrCount, setQrCount] = useState(1);
    const [valid, setValid] = useState(true);
    const intlTelInputRef = useRef(null);

    const loader = useSelector(state => state.SendQrcodes.loading);

    const sendFormValues = {
        mediaType: "",
        identifier: "",
    };

    const emailSchema = Yup.object().shape({
        identifier: Yup.string().required('Enter Email address')
            .email('Enter valid email')
            .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                'Enter valid email'
            )
            .trim(),
    });

    const mobileSchema = Yup.object().shape({
        identifier: Yup.string().required('Enter mobile number').trim(),
    });

    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: isInputEnabled === "email" ? yupResolver(emailSchema) : yupResolver(mobileSchema),
        defaultValues: sendFormValues,
    });

    const onSubmit = (value) => {
        const data = {};
        data[isInputEnabled] = value?.identifier;
        data['mediaType'] = isInputEnabled;
        data['count'] = qrCount;
        data['campaign'] = eventData?._id;
        dispatch(sendQrcodes(data, handleClear));
    };

    const increment = () => {
        setQrCount((prev) => {
            if (prev >= 4) return 5;
            return prev + 1
        });
    };

    const decrement = () => {
        setQrCount((prev) => {
            if (prev > 2 && prev != 2) return prev - 1;
            else return (prev = 1);
        });
    };

    const handleClear = () => {
        setQrCount(1);
        reset();
    }

    const handleReset = () => {
        reset();
        if (intlTelInputRef.current) {
            intlTelInputRef.current.setNumber('');
        }
    };

    return (
        <div className='print-qr send-qr'>
            <Card>
                <CardBody>
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                        {eventData ? (
                            <>
                                <div className='row'>
                                    <div>
                                        <div className="quantity">
                                            <h4 className="mb-4 mt-5"><strong>Campaign: {eventData?.campaignName}</strong></h4>
                                            <p>Quantity</p>
                                            <p className="limit">Maximum allowed limit is 5 per send</p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button type='button' style={{ cursor: qrCount <= 1 ? "text" : "pointer", opacity: qrCount <= 1 ? 0.2 : 1 }} className="buttons" onClick={decrement}><img src={minusIcon}></img></button>
                                            <span className="d-flex justify-content-center align-items-center count">
                                                {qrCount}
                                            </span>
                                            <button type='button' style={{ cursor: qrCount >= 5 ? "text" : "pointer", opacity: qrCount >= 5 ? 0.2 : 1 }} className="buttons" onClick={increment}><img src={plusIcon}></img></button>
                                        </div>
                                    </div>
                                    <div className='checkbox-grp'>
                                        <div className='form-check'>
                                            <Controller
                                                name="radioOption"
                                                control={control}
                                                render={({ field }) => (
                                                    <input type="radio" id="whatsapp" className='form-check-input'
                                                        {...field} value="whatsapp"
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleClear()
                                                            setIsInputEnabled('whatsapp');
                                                        }}
                                                        defaultChecked />
                                                )}
                                            />
                                            <label className='cursor-pointer' htmlFor="whatsapp"><span>Whatsapp</span></label>
                                        </div>

                                        <div className='form-check'>
                                            <Controller
                                                name="radioOption"
                                                control={control}
                                                render={({ field }) => (
                                                    <input type="radio" id="email" className='form-check-input' {...field} value="option2"
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleClear()
                                                            setIsInputEnabled('email');
                                                            setValid(true);
                                                        }}
                                                    />
                                                )}
                                            />
                                            <label className='cursor-pointer' htmlFor="email"><span>Email address</span></label>
                                        </div>
                                    </div>
                                    <div className='sentqr_group'>
                                        {isInputEnabled === "whatsapp" ?
                                            <div className="mb-3 inputGroup">
                                                <Controller
                                                    name="identifier"
                                                    control={control}
                                                    render={({ field: { onChange, value } }) => (
                                                        <IntlTelInput
                                                            ref={intlTelInputRef}
                                                            containerClassName="intl-tel-input"
                                                            inputClassName={`form-control ${errors.identifier?.message ?
                                                                "form-control is-invalid" : ""} inputField ${valid ? "" : "form-control is-invalid"}`}
                                                            onPhoneNumberChange={(isValid, rawValue, countryData, formattedValue) => {
                                                                onChange(formattedValue.replace(/\s+/g, ''));
                                                                setValid(isValid);
                                                            }}
                                                            format
                                                            defaultCountry="my"
                                                            preferredCountries={['in', 'my']}
                                                        />
                                                    )}
                                                />
                                                {!valid && !errors.identifier?.message && (
                                                    <p className="er-message">Enter valid mobile number</p>)
                                                }
                                                {errors.identifier?.message ? (
                                                    <p className={"er-message"}>
                                                        {errors.identifier?.message}
                                                    </p>) : ""}
                                            </div> :
                                            <div className="mb-3 inputGroup">
                                                <Controller
                                                    name="identifier"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input type={`${isInputEnabled === "sms" || isInputEnabled === "whatsapp" ? "number" : "text"}`}
                                                            placeholder={`${isInputEnabled === "sms" || isInputEnabled === "whatsapp" ? "Mobile number" : "Email address"}`}
                                                            className={`form-control ${errors.identifier?.message ?
                                                                "form-control is-invalid" : ""} inputField`} {...field} />

                                                    )}
                                                />
                                                {errors.identifier?.message ? (
                                                    <p className={`invalid-feedback mb-0`}>
                                                        {errors.identifier?.message}
                                                    </p>) : ""}
                                            </div>
                                        }

                                        <div>
                                            {isInputEnabled === "sms" || isInputEnabled === "whatsapp" || isInputEnabled === "email" ?
                                                <div className="d-flex align-items-center justify-content-center mt-4 pt-2">
                                                    <div>
                                                        {loader ? <div className="d-grid"><AuthLoader btnStyle={{ padding: "5px 20px", height: "40px" }} /></div> :
                                                            <PrimaryButton
                                                                btnName={"Send QR"}
                                                                showIcon={false}
                                                                btnType={"submit"}
                                                                disabled={!valid || errors.identifier}
                                                            />}
                                                    </div>
                                                    <div className="ms-3 ">
                                                        <SecountryBtn disabled={loader} btnName={"Clear"} btnType={"button"} showIcon={false} onClick={handleReset} />
                                                    </div>
                                                </div>
                                                : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <p className="campaign-details pt-5">No active campaign</p>
                        )}
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default SendQr;