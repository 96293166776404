import { combineReducers } from "redux";
import Layout from "./layout/reducer";
import authInfo from "./authInfo/reducer";
import {
  Login,
  ForgetPassword,
  ResetPassword,
  ForgetPasswordResendCode,
} from "./auth/reducers";
import {
  GetAdmin,
  AddAdmin,
  GetAdminById,
  ChangeStatus,
} from "./admin/reducers";
import {
  UpdateProfile,
  LoginPasswordChange,
  UpdateProfileImg,
} from "./profile/reducer";
import {
  GetQrcodes,
  AddQrcodes,
  GetQrcodesById,
  SetStatusQrcodes,
  issueQrcodes,
  SendQrcodes
} from "./qrcodes/reducers";
import { GetAllUser, AddUser, GetUserById, UserStatus } from "./users/reducers";
import { SettingsUpdate, GetSettings, CampaignSettingsUpdate, GetCampaignDetails, UpdateCampaignDetails, CampaignOptions, GetByCampaignId } from "./settings/reducer";
import { GetAdminRoles, GetAdminRolesType } from "./roles/reducers";
import { GetallQr } from "./manageQr/reducer";
import { GetallPrize, updatePrize, FreezePrize, AddPrize, AddSegments, UpdatePrizeImage, ChangePrizeStatus, GetSpinner, MagangePrizePublish } from "./managePrize/reducer";
import { GetallWinners, RedeemPrizeReducer } from "./winners/reducer";
import ErrorScreenCode from "./errorscreen/reducer";
import { GetallAdds, AdsStatusChange, addAdvertisement, DeleteAdById, ReadAdvertisementImage } from "./manageads/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  ForgetPassword,
  authInfo,
  GetAdmin,
  AddAdmin,
  GetAdminById,
  ChangeStatus,
  ResetPassword,
  ForgetPasswordResendCode,
  UpdateProfile,
  LoginPasswordChange,
  UpdateProfileImg,
  GetQrcodes,
  AddQrcodes,
  GetQrcodesById,
  SetStatusQrcodes,
  GetAllUser,
  AddUser,
  GetUserById,
  UserStatus,
  SettingsUpdate,
  CampaignSettingsUpdate,
  GetSettings,
  GetAdminRoles,
  GetAdminRolesType,
  GetallQr,
  issueQrcodes,
  GetallPrize,
  updatePrize,
  AddPrize,
  UpdatePrizeImage,
  ChangePrizeStatus,
  GetSpinner,
  GetallWinners,
  RedeemPrizeReducer,
  FreezePrize,
  ErrorScreenCode,
  GetCampaignDetails,
  UpdateCampaignDetails,
  SendQrcodes,
  GetallAdds,
  AdsStatusChange,
  DeleteAdById,
  addAdvertisement,
  ReadAdvertisementImage,
  AddSegments,
  CampaignOptions,
  GetByCampaignId,
  MagangePrizePublish
});

export default rootReducer;
