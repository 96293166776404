import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESS,
    API_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_ERROR,
    FORGET_PASSWORD_RESEND_CODE,
    FORGET_PASSWORD_RESEND_CODE_SUCCESS,
    FORGET_PASSWORD_RESEND_CODE_ERROR
} from "./actionTypes"

//Login
const initialState = {
    error: "",
    loading: null,
}
export const Login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true,
            }
            break
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
            }
            break
        case LOGOUT_USER:
            state = { ...state, loading: false, }
            break
        case LOGOUT_USER_SUCCESS:
            state = { ...state, loading: false, }
            break
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

//Reset Password
const resetInitialState = {
    resetSuccessMsg: null,
    resetError: null,
    resetLoader: false,
}
export const ResetPassword = (state = resetInitialState, action) => {
    switch (action.type) {
        case RESET_PASSWORD:
            state = {
                ...state,
                resetSuccessMsg: null,
                resetError: null,
                resetLoader: true,
            }
            break
        case RESET_PASSWORD_SUCCESS:
            state = {
                ...state,
                resetSuccessMsg: action.payload,
                resetLoader: false,
            }
            break
        case RESET_PASSWORD_ERROR:
            state = { ...state, resetError: action.payload, resetLoader: false, }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

// Forget Password
const forgotInitialState = {
    forgetSuccessMsg: null,
    forgetError: null,
    forgetLoader: null,
}
export const ForgetPassword = (state = forgotInitialState, action) => {
    switch (action.type) {
        case FORGET_PASSWORD:
            state = {
                ...state,
                forgetSuccessMsg: null,
                forgetError: null,
                forgetLoader: true,
            }
            break
        case FORGET_PASSWORD_SUCCESS:
            state = {
                ...state,
                forgetSuccessMsg: action.payload,
                forgetLoader: false,
            }
            break
        case FORGET_PASSWORD_ERROR:
            state = { ...state, forgetError: action.payload, forgetLoader: null, }
            break
        default:
            state = { ...state }
            break
    }
    return state
}

//Forget password resend code
export const ForgetPasswordResendCode = (state = forgotInitialState, action) => {
    switch (action.type) {
        case FORGET_PASSWORD_RESEND_CODE:
            state = {
                ...state,
                forgetSuccessMsg: null,
                forgetError: null,
                forgetLoader: true,
            }
            break
        case FORGET_PASSWORD_RESEND_CODE_SUCCESS:
            state = {
                ...state,
                forgetSuccessMsg: action.payload,
                forgetLoader: false,
            }
            break
        case FORGET_PASSWORD_RESEND_CODE_ERROR:
            state = { ...state, forgetError: action.payload, forgetLoader: false, }
            break
        default:
            state = { ...state }
            break
    }
    return state
}