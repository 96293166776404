import React from "react";
import { Modal } from "reactstrap";

const VideoView = ({ src, showModal, setShowModal }) => {

    const togglemodal = () => {
        setShowModal(false);
    };

    return (
        <>
            <Modal
                size="md"
                isOpen={showModal}
                centered={true}
                className="doc-modal confirmation"
            >
                <div className="modal-header px-0 border-0">
                    <div className="head-item">
                        <span
                            onClick={togglemodal}
                            aria-hidden="true"
                            className="close-icon"
                        >
                            <i className="bx bx-x"></i>
                        </span>
                    </div>
                </div>

                <div>
                    <video  width={"100%"} alt="video" controls autoPlay> 
                        <source src={`data:video/mp4;base64,${src}`} type="video/mp4" />
                    </video>
                </div>
            </Modal>
        </>
    );
};

export default VideoView