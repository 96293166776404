import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  useEffect(() => {
    return props.history.listen(() => {
      const userToken = localStorage.getItem("jwt");
      if (userToken) {
        const decodedJwt = parseJwt(userToken);
        if (decodedJwt.exp * 1000 < Date.now()) {
          props.logOut();
        }
      }
    });
  }, [])


  return <div></div>;
};

export default withRouter(AuthVerify);