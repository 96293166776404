import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumb from "components/Common/Breadcrumb";
import PrimaryButton from "components/Common/Buttons/PrimaryButton";
import SecountryBtn from "components/Common/Buttons/SecountryBtn";
import React, { useState } from "react";
import { Card, CardBody, Label, Container, Col, Row } from "reactstrap";
import { addPrize } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PageLoader from "components/Common/PageLoader";
import DocumentTitle from "components/DocumentTitle";

export default function AddPrize() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [spinnerImagePreview, setSpinnerImagePreview] = useState(null);
    const [prizeImagePreview, setPrizeImagePreview] = useState(null);

    const addPrizeLoading = useSelector((state) => state?.AddPrize?.loading);

    const handleInput = (event) => {
        const forbiddenKeys = ['.', '-'];
        if (forbiddenKeys.includes(event.key)) {
            event.preventDefault();
            return;
        }
        const inputValue = parseInt(event.target.value, 10);
        if (isNaN(inputValue) || inputValue < 0) {
            event.target.value = '';
        }
    };

    const addprizeFormValues = {
        productCode: "",
        totalPrizes: "",
        probability: "",
        spinnerImg: null,
        prizeImg: null

    };

    const addprizeSchema = Yup.object({
        productCode: Yup.string().required("Enter prize name").trim(),
        totalPrizes: Yup.string().required("Enter total prizes").trim(),
        probability: Yup.string().required("Enter probability").trim(),
        spinnerImg: Yup.string().required("Upload spinner image").trim(),
        prizeImg: Yup.string().required("Upload prize image").trim(),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(addprizeSchema),
        defaultValues: addprizeFormValues,
    });

    const onSubmit = (values) => {
        const data = {};
        data["productCode"] = values?.productCode;
        data["totalPrizes"] = values?.totalPrizes;
        data["probability"] = values?.probability;
        data["spinnerImg"] = spinnerImagePreview
        data["prizeImg"] = prizeImagePreview
        dispatch(addPrize(data, history));
    };

    const handleClear = () => {
        reset(addprizeFormValues);
        setSpinnerImagePreview(null);
        setPrizeImagePreview(null);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const { files } = event.dataTransfer;
        if (files.length > 0) {
            handleFileChange(files[0]);
        }
    };

    const preventDefault = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleFileInputChange = (event, type) => {
        const { files } = event.target;
        if (files && files.length > 0) {
            const file = files[0];
            if (type === "prize") {
                handlePrizeFileChange(file);
            } else if (type === "spinner") {
                handleSpinnerFileChange(file);
            }
        }
    };

    const handleSpinnerFileChange = (file) => {
        handleFileChange(file, setSpinnerImagePreview);
    };

    const handlePrizeFileChange = (file) => {
        handleFileChange(file, setPrizeImagePreview);
    };

    const handleFileChange = (file, setPreview) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setPreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <React.Fragment>
            <DocumentTitle title={`Add Prize`} />

            {addPrizeLoading ? <PageLoader /> : ""}
            <div className="page-content adding-prize">
                <Container fluid>
                    <Breadcrumb title="Manage Prize" dashurl="/manage-prize" breadcrumbItem={"Add New Prize"} />
                    <Card>
                        <div className="p-4">
                            <CardBody>
                                <form
                                    className="form-horizontal"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <Row>
                                        <Col md="4">
                                            <div className="mb-3 ">
                                                <Label className="">
                                                    Prize name <span>*</span>
                                                </Label>
                                                <input
                                                    type="text"
                                                    onInput={(e) => {
                                                        e.target.value = e.target.value.replace(/[^A-Za-z0-9\s]/g, '');
                                                    }}
                                                    {...register("productCode")}
                                                    className={
                                                        errors.productCode?.message
                                                            ? "form-control is-invalid"
                                                            : "" + "inputField"
                                                    }
                                                />
                                                {errors.productCode?.message ? (
                                                    <p className={`invalid-feedback`}>
                                                        {errors.productCode?.message}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3 ">
                                                <Label className="">
                                                    Total prizes <span>*</span>
                                                </Label>
                                                <input
                                                    type="number"
                                                    {...register("totalPrizes")}
                                                    onKeyDown={handleInput}
                                                    onInput={handleInput}
                                                    className={
                                                        errors.totalPrizes?.message
                                                            ? "form-control is-invalid"
                                                            : "" + "inputField"
                                                    }
                                                />
                                                {errors.totalPrizes?.message ? (
                                                    <p className={`invalid-feedback`}>
                                                        {errors.totalPrizes?.message}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3 ">
                                                <Label className="">
                                                    Probability <span>*</span>
                                                </Label>
                                                <input
                                                    type="number"
                                                    {...register("probability")}
                                                    onKeyDown={handleInput}
                                                    onInput={handleInput}
                                                    className={
                                                        errors.probability?.message
                                                            ? "form-control is-invalid"
                                                            : "" + "inputField"
                                                    }
                                                />
                                                {errors.probability?.message ? (
                                                    <p className={`invalid-feedback`}>
                                                        {errors.probability?.message}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <Label className="">
                                                Prize image <span>*</span>
                                            </Label>
                                            <div className="mb-3 ">
                                                <div >
                                                    <div
                                                        onDrop={handleDrop}
                                                        onDragOver={preventDefault}
                                                        onDragEnter={preventDefault}
                                                        className='upload-container form-control is-invalid'
                                                    >
                                                        <input
                                                            type="file"
                                                            {...register("prizeImg")}
                                                            onChange={(event) => handleFileInputChange(event, "prize")}
                                                            className={
                                                                errors.prizeImg?.message
                                                                    ? "form-control is-invalid"
                                                                    : "" + "inputField"
                                                            }
                                                        />
                                                        <div className='upload-content '>
                                                            {prizeImagePreview && (
                                                                <img
                                                                    src={prizeImagePreview}
                                                                    alt="Prize Preview"
                                                                    className="img-thumbnail mt-2"
                                                                    width={100}
                                                                />
                                                            )}
                                                            <label
                                                                className="m-0"
                                                                htmlFor="Upload"
                                                                onDrop={handleDrop}
                                                                onDragOver={(e) => e.preventDefault()}
                                                            >
                                                            </label>
                                                            <h5 className='p-0'>Upload Prize Image</h5>
                                                            <p>File Should be png, jpeg, jpg.</p>
                                                        </div>
                                                    </div>
                                                    {errors.prizeImg?.message ? (<p className={`invalid-feedback`}>{errors.prizeImg?.message} </p>) : null}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <Label className="">
                                                Spinner image <span>*</span>
                                            </Label>
                                            <div className="mb-3 avatar ">
                                                <div >
                                                    <div
                                                        onDrop={handleDrop}
                                                        onDragOver={preventDefault}
                                                        onDragEnter={preventDefault}
                                                        className='upload-container form-control is-invalid'
                                                    >
                                                        <input
                                                            type="file"
                                                            {...register("spinnerImg")}
                                                            onChange={(event) => handleFileInputChange(event, "spinner")}
                                                            className={
                                                                errors.spinnerImg?.message
                                                                    ? "form-control is-invalid"
                                                                    : "" + "inputField"
                                                            }
                                                        />
                                                        <div className='upload-content'>
                                                            {spinnerImagePreview && (
                                                                <img
                                                                    src={spinnerImagePreview}
                                                                    alt="Prize Preview"
                                                                    className="img-thumbnail mt-2"
                                                                    width={100}
                                                                />
                                                            )}
                                                            <label
                                                                className="m-0"
                                                                htmlFor="spinnerUpload"
                                                                onDrop={handleDrop}
                                                                onDragOver={(e) => e.preventDefault()}
                                                            >
                                                            </label>
                                                            <h5 className='p-0'>Upload Spinner Image</h5>
                                                            <p>File Should be png, jpeg, jpg.</p>
                                                        </div>

                                                    </div>

                                                    {errors.spinnerImg?.message ? (<p className={`invalid-feedback`}>{errors.spinnerImg?.message} </p>) : ""}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="d-flex align-items-center mt-4">
                                        <div>
                                            <PrimaryButton
                                                btnName={"Save"}
                                                showIcon={false}
                                                btnType={"submit"}
                                            />
                                        </div>
                                        <div className="ms-3 ">
                                            <SecountryBtn btnName={"Clear"} btnType={"button"} showIcon={false} onClick={handleClear} />
                                        </div>
                                    </div>
                                </form>
                            </CardBody>
                        </div>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}
